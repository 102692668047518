import React from "react";
const FormContext = React.createContext({});
const FormProvider = FormContext.Provider;
const FormConsumer = FormContext.Consumer;
const withFormContext = Component => React.forwardRef((props, ref) => (
    <FormConsumer>
        {(response) => {
            return <Component {...props} formContext={response} ref={ref}/>;
        }}
    </FormConsumer>
));
export { FormProvider, FormConsumer, withFormContext };
