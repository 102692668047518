import React, {Component} from 'react';
import {AccountAreaButton, AccountAreaDropDown} from "./style";
import Avatar from "../../icons/Avatar";
import {NavLink} from "react-router-dom";
import {
    ROUTE_ACCOUNT, ROUTE_ACCOUNT_CHANGE_PASSWORD, ROUTE_ACCOUNT_PAYMENTS, ROUTE_ACCOUNT_TICKETS,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_REGISTER
} from "../../../routes/routes";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAccountContext} from "../../../contexts/AccountContext";
import Button from "../Button";
import Link from "../Link";

class AccountArea extends Component {
    refAvatarContainer = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    onClickAvatar = (e) => {
        this.setState({
            open: !this.state.open
        })
    }
    componentDidMount() {
        let self = this;
        document.body.onclick = function (e) {
            if(self.refAvatarContainer && !self.refAvatarContainer.contains(e.target)){
                self.setState({
                    open: false
                })
            }
        }
    }
    onClickChangeTheme = (e) => {
        const {applicationContext} = this.props;
        e.preventDefault();
        applicationContext.onChangeTheme("auto")
    }
    onLogout = (e) => {
        e.preventDefault();
        const {applicationContext} = this.props;
        let footer = (
            <React.Fragment>
                <Button color={"primary"} onClick={this.handleLogout} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"outline-primary"} onClick={applicationContext.onCloseConfirmPopup} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        this.setState({
            open: false
        })
        applicationContext.onOpenConfirmPopup("Log Out", "Are you sure you want to log out?", footer, () => {}, "")

    }
    handleLogout = (e) => {
        const {applicationContext, accountContext, navigate} = this.props;
        accountContext.handleLogout(() => {
            applicationContext.onCloseConfirmPopup();
            navigate(ROUTE_HOME);
        })
    }
    onMenuClick = (e) => {
        this.setState({
            open: false
        })
    }
    render() {
        const {open} = this.state;
        const {applicationContext, accountContext} = this.props;
        return (
            <AccountAreaButton ref={(ref) => this.refAvatarContainer = ref}>
                <Avatar width={"3em"} height={"3em"} onClick={this.onClickAvatar}/>
                <AccountAreaDropDown open={open}>
                    {accountContext.state.loggedIn?(
                        <React.Fragment>
                            <div className={"menu-text"}>Welcome, <b>{accountContext.state.userInfo['first_name']}</b></div>
                            <hr />
                            <Link to={ROUTE_ACCOUNT} onClick={this.onMenuClick}>My Profile</Link>
                            <Link to={ROUTE_ACCOUNT_CHANGE_PASSWORD} onClick={this.onMenuClick}>Change password</Link>
                            <Link to={ROUTE_ACCOUNT_PAYMENTS} onClick={this.onMenuClick}>Payments</Link>
                            <Link to={ROUTE_ACCOUNT_TICKETS} onClick={this.onMenuClick}>Tickets</Link>
                        </React.Fragment>
                    ):(
                        <React.Fragment>
                            <Link to={ROUTE_LOGIN} onClick={this.onMenuClick}>Log in</Link>
                            <Link to={ROUTE_REGISTER} onClick={this.onMenuClick}>Register</Link>
                        </React.Fragment>
                    )}
                    <hr />
                    <Link to={"/"} onClick={this.onClickChangeTheme}>Switch to <b>{applicationContext.state.theme === "light-theme"?"Dark":"Light"} mode</b></Link>
                    {accountContext.state.loggedIn && <Link to={""} onClick={this.onLogout}>Log out</Link>}
                </AccountAreaDropDown>
            </AccountAreaButton>
        );
    }
}

export default withApplicationContext(withRouter(withAccountContext(AccountArea)));