import React, {Component} from 'react';
import {withStageMapContext} from "./StageMapContext";
import {Header, SeatLabel, SeatRowContainer, SeatRowWrapper, WhiteSpace} from "./style";
import Seat from "./Seat";
import PropTypes from "prop-types";

class SeatRow extends Component {
    render() {
        const {seatRow, seatColumnIndex, context} = this.props;
        const {cellSize, seats} = context;
        return (
            <SeatRowContainer>
                {(seatRow.hasOwnProperty('white-space') && seatRow['white-space'])?(
                    <WhiteSpace size={cellSize}></WhiteSpace>
                ):(
                    (seatRow.hasOwnProperty('header') && seatRow['header'])?(
                        <Header size={cellSize}>{seatRow['header']}</Header>
                    ):(
                        <React.Fragment>
                            <SeatLabel size={cellSize}>{seatRow.label}</SeatLabel>
                            <SeatRowWrapper>
                                {seatRow['seats'].map((seatCell, seatCellIndex) => {
                                    return <Seat seatRow={seatRow} seatCell={seatCell} key={seatCellIndex} />;
                                })}
                            </SeatRowWrapper>
                            {seatColumnIndex === seats['seats'].length - 1 ?<SeatLabel size={cellSize}>{seatRow.label}</SeatLabel>:null}

                        </React.Fragment>
                    )

                )}

            </SeatRowContainer>
        )
    }
}
SeatRow.propTypes = {
    seatRow: PropTypes.object,
    seatColumnIndex: PropTypes.number,
    seatRowIndex: PropTypes.number
}
export default withStageMapContext(SeatRow);