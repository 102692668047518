import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import * as Yup from "yup";
import Card from "../../../components/ui/Card";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import AdminService from "../../../services/AdminService";
import Dropdown from "../../../components/ui/Dropdown";
import Uploader from "../../../components/ui/Uploader";
import {file, formatBytes, getRandomInt, parseErrorMessage, prepareParametersData} from "../../../helpers";
import {ROUTE_ADMIN_EVENT_PLACES} from "../../../routes/routes";
import MultiUploader from "../../../components/ui/Uploader/MultiUploader";
const MaxFileUploadSize = 1048576;
class EventPlaceDataForm extends Component {
    refForm = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            service: props.service,
            loading: true,
            updatingId: null,
            inputs: {
                type: "CONCERT",
                title: "",
                description: "",
                address: "",
                capacity: 0,
                images: []
            },
            formDisabled: false
        }

    }
    componentDidMount = async () => {
        const {navigate, params, service} = this.props;
        const {id} = params;
        if(id){
            try{
                let data = await AdminService.get(service, id);
                let images = [];
                data['gallery'].map((image) => {
                    images.push(file(image))
                })
                this.setState({
                    updatingId: data.id,
                    inputs: {
                        type: data.type,
                        title: data.title,
                        description: data.description,
                        googleMapUrl: (data.location && data.location.googleMapUrl),
                        address: data.address,
                        capacity: data.capacity,
                        images: images
                    },
                    loading: false
                })
            }catch (e){
                navigate(ROUTE_ADMIN_EVENT_PLACES);
                return;
            }

        }else{
            this.setState({ loading: false })
        }

    }

    onSubmit = async (values, actions) => {
        const {updatingId} = this.state;
        this.setState({ submitting: true });
        if(updatingId){
            await this.onUpdateRecord(values, actions);
        }else{
            await this.onAddRecord(values, actions)
        }


    }
    onAddRecord = async (values, actions) => {
        const {applicationContext, alert, service} = this.props;
        await AdminService.doCreate(service, prepareParametersData(values)).then((data) => {
            alert.success(applicationContext.translator("Event place created successfully"));
            actions.resetForm();
            this.setState({
                inputs: {
                    type: "CONCERT",
                    title: "",
                    description: "",
                    address: "",
                    googleMapUrl: "",
                    capacity: 0,
                    images: []
                }
            })
        }).catch((err) => {
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    onUpdateRecord = async (values = null, actions = null) => {
        const {applicationContext, alert, service} = this.props;
        const {updatingId} = this.state;
        if(!updatingId) return;
        if(!values) values = this.refForm.values
        await AdminService.doUpdate(service, updatingId, prepareParametersData(values)).then((data) => {
            alert.success(applicationContext.translator("Event place updated successfully"));
        }).catch((err) => {
            if(err.response.data.hasOwnProperty('input_errors')){
                if(actions) actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    render() {
        const {applicationContext} = this.props;
        const {formDisabled, updatingId} = this.state;
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    enableReinitialize={true}
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.inputs}
                    validationSchema={Yup.object().shape({
                        type: Yup.string().required(applicationContext.translator("You must select the ${path}")).label(applicationContext.translator("Event type")),
                        title: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Title")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        address: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Address")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        googleMapUrl: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Google Map URL")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        capacity: Yup.number().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Capacity")).min(1, applicationContext.translator("${path} must be bigger than 1")),
                        images: Yup.array().min(1, "You must upload at least one file")


                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldTouched,
                          validateField,
                          setFieldValue
                      }) =>
                        (
                            <Card maxWidth={800} center={true} top={"2em"}>
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <Dropdown
                                        name={"type"}
                                        value={values.type}
                                        onChange={handleChange}
                                        line={true}
                                        label={applicationContext.translator("Event type")}
                                        data={[
                                            { key: "CONCERT", label: "Concert"},
                                            { key: "PARTY", label: "Party"},
                                        ]}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <InputBox type={"text"}
                                              name={"title"}
                                              onChange={handleChange}
                                              line={true}
                                              label={applicationContext.translator("Title")}
                                              value={values.title}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type={"text"}
                                              name={"googleMapUrl"}
                                              onChange={handleChange}
                                              line={true}
                                              label={applicationContext.translator("Google Map URL")}
                                              value={values.googleMapUrl}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox as={"textarea"}
                                              rows={3}
                                              name={"description"}
                                              line={true}
                                              onChange={handleChange}
                                              label={applicationContext.translator("Description")}
                                              value={values.description}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox as={"textarea"}
                                              name={"address"}
                                              rows={3}
                                              line={true}
                                              onChange={handleChange}
                                              label={applicationContext.translator("Address")}
                                              value={values.address}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox type={"text"}
                                              onlyNumber={true}
                                              line={true}
                                              name={"capacity"}
                                              onChange={handleChange}
                                              label={applicationContext.translator("Capacity")}
                                              value={values.capacity}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <MultiUploader
                                        name={"images"}
                                        fileType={["image", "video", "audio"]}
                                        uploaded={values.images}
                                        form={this.refForm}
                                        maxSize={MaxFileUploadSize}
                                        uploadToServer={true}
                                        onUpdateRecord={this.onUpdateRecord}
                                        onStartUpload={() => this.setState({formDisabled: true})}
                                        onCompleteUpload={() => this.setState({formDisabled: false})}
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} disabled={formDisabled} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                            {this.state.submitting?"Submitting...":applicationContext.translator("Save")}
                                        </Button>
                                    </Form.Group>


                                </Form>
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext((EventPlaceDataForm)))));