import styled from "styled-components";
import {Row} from "react-bootstrap";

export const Container = styled.div`
  background: var(--color-background);
  color: var(--color-text);
  border: 0;
  width: 100%;
  padding: ${(props) => props.padding??`.5em 0`};
  ${(props) => props.center?`margin: 0 auto;`:`margin: 1em 0;`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  ${(props) => props.top && `margin-top: ${props.top};`}
  ${(props) => props.bottom && `margin-bottom: ${props.bottom};`}
  ${(props) => props.margin && `margin: ${props.margin} !important;`}
  @media screen and (max-width: 700px) {
    padding: .5em 0em;
  }
`;
export const Title = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 1em;
`

