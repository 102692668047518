import React, {Component} from 'react';
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormDescription, FormTitle} from "../Login/style";
import Link from "../../components/ui/Link/Link";
import {ROUTE_LOGIN, ROUTE_NOT_FOUND, ROUTE_RESET_PASSWORD_BY_MOBILE} from "../../routes/routes";
import * as Yup from "yup";
import InputBox from "../../components/ui/InputBox/InputBox";
import Button from "../../components/ui/Button";
import {Formik} from "formik";
import Service from "../../services/Service";
import {parseErrorMessage, passwordCheck} from "../../helpers";
import FormErrorText from "../../components/ui/FormErrorText";
import {Navigate} from "react-router-dom";
import Spinner from "../../components/ui/Spinner/Spinner";
import CommonService from "../../services/CommonService";
import SuccessfullMessageBox from "../../components/ui/SuccessfullMessageBox";

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                email: "",
                mobile: "",
                password: "",
                password_confirmation: ""
            },
            loading: true,
            error: null,
            submitting: false,
            successfully: false,
            redirect: false,
            endpoint: null
        }
    }
    componentDidMount = async () => {
        const {params} = this.props;
        try{
            let url = (decodeURIComponent(atob(params.url)));
            const ua = new URL(url);
            const urlParams = new URLSearchParams(ua.search);
            this.setState({
                inputs: {
                    email: urlParams.get("email"),
                    mobile: urlParams.get("flag")+" (+"+urlParams.get("mobile_prefix")+") "+urlParams.get("mobile"),
                },
                endpoint: ua.toString(),
                loading: false
            })
        }catch (e){
            this.setState({
                redirect: true,
            })
        }

    }
    handleSubmit = async (values, actions) => {
        const {applicationContext, navigate} = this.props;
        this.setState({ submitting: true });
        try {
            await CommonService.doPost(this.state.endpoint, {
                password: values.password,
                password_confirmation: values.password_confirmation,
            });
            this.setState({
                successfully: true,
                error: null
            });
            setTimeout(() => {
                navigate(ROUTE_LOGIN);
            }, 3000)
        } catch (err) {
            let error = null;
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                error = applicationContext.translator(parseErrorMessage(err));
            }
            this.setState({
                submitting: false,
                error
            });

        }
    }
    render() {
        const {redirect} = this.state;
        if(redirect)
            return <Navigate to={ROUTE_NOT_FOUND} />;
        return (
            <React.Fragment>
                {this.renderComponent()}
            </React.Fragment>
        );
    }
    renderComponent = () => {
        const {applicationContext} = this.props;
        const {loading, successfully} = this.state;
        if(loading)
            return <Spinner size={"md"} show={loading} inline={false}/>
        else if(successfully)
            return (
                <SuccessfullMessageBox
                    title={applicationContext.translator("Password Changed!")}
                    messages={[applicationContext.translator("Your password has been changed successfully.")]}
                />
            )
        else
            return this.renderForm();
    }
    renderForm = () => {
        const {applicationContext} = this.props;
        const {error, inputs, submitting} = this.state;
        return (
            <React.Fragment>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={inputs}
                    validationSchema={Yup.object().shape({
                        password: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                            .test('password', applicationContext.translator("The password must contain at least 8 english characters and include upper and lower case letters, numbers and a symbol"), (value) => {
                                let check = passwordCheck(value);
                                return (check.message === "Strong")
                            })
                            .label(applicationContext.translator("Password")),
                        password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                            .oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))
                            .label(applicationContext.translator("Password Confirmation"))
                    })}
                    onSubmit={this.handleSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                <FormTitle>{applicationContext.translator("Reset Password")}</FormTitle>
                                <FormDescription>{applicationContext.translator("Enter your e-mail address to reset your password")}</FormDescription>
                                <Row>
                                    <Col md={12}>
                                        {values.email && <InputBox type="text"
                                                  name={"email"}
                                                  placeholder={applicationContext.translator("E-Mail Address")}
                                                  value={values.email}
                                                  disabled={true}
                                                  errors={errors}
                                                  touched={touched}
                                        />}
                                        {values.mobile && <InputBox type="text"
                                                                   name={"mobile"}
                                                                   placeholder={applicationContext.translator("Mobile number")}
                                                                   value={values.mobile}
                                                                   disabled={true}
                                                                   errors={errors}
                                                                   touched={touched}
                                        />}
                                    </Col>
                                    <Col md={12}>
                                        <InputBox type={"password"}
                                                  name={"password"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("Password")}
                                                  value={values.password}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                                  strength={true}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <InputBox type={"password"}
                                                  name={"password_confirmation"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("Password Confirmation")}
                                                  value={values.password_confirmation}
                                                  save={"off"}
                                                  errors={errors}
                                                  touched={touched}
                                                  strength={true}
                                        />
                                    </Col>
                                </Row>
                                {error && <FormErrorText>{error}</FormErrorText>}
                                <Form.Group className={"text-center"}>
                                    <Button color={"primary"} fullWidth={true} size={3} submitting={submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Reset Password")}</Button>
                                </Form.Group>
                            </Form>
                        )}
                </Formik>
            </React.Fragment>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(ResetPasswordForm)));