import React from "react";
const EventTicketsContext = React.createContext({});
const EventTicketsProvider = EventTicketsContext.Provider;
const EventTicketsConsumer = EventTicketsContext.Consumer;
const withEventTicketsContext = Component => React.forwardRef((props, ref) => (
    <EventTicketsConsumer>
        {(response) => {
            return <Component {...props} context={response} ref={ref}/>;
        }}
    </EventTicketsConsumer>
));
export { EventTicketsProvider, EventTicketsContext, withEventTicketsContext };
