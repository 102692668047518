import styled from "styled-components";
import {Col, Container as ReactContainer, Form, Row} from "react-bootstrap";
import {Field} from "formik";
export const Container = styled.div`
    padding: 2em 0em;
`
export const PaymentContainer = styled.div`
  width: 100%;
  margin: 5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Card = styled.div`
    width: 100%;
    max-width: 440px;
    padding: 1.5em;
    box-shadow: 0 2px 5px var(--color-shadow-04), 0 0 2px var(--color-shadow-02);
    border: 1px solid var(--color-shadow-1);
    border-radius: var(--border-radius-3);
    margin-bottom: 1em;
    text-align: center;
`
export const BackButtonContainer = styled(Col)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & h1{
        font-size: 1.4em;
        margin-bottom: 0;
    }
      & a{
        width: 32px;
        height:32px;
        color: var(--color-text);
        opacity: .7;
        transition: all ease-in-out .3s;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:hover{
          color: var(--color-primary);
          opacity: 1;
          transition: all ease-in-out .3s;
        }
        & svg{
          width: 20px;
          height: 20px;
        }
      }
    
`
export const CartBarContainer  = styled.div`
  @keyframes smooth-appear {
    to{
      bottom: 0;
      opacity:1;
    }
  }
  border-top: 1px solid var(--color-border);
  position: fixed;
  left: 0;
  right: 0;
  bottom: -100%;
  opacity:0;
  z-index: 99999;
  background: var(--color-background);
  padding: 2em 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px -4px, rgba(0, 0, 0, 0.12) 0px 0px 2px;
  animation: smooth-appear 1s ease forwards;
`
export const CartBarWrapper  = styled(ReactContainer)`
  display: flex;
  justify-content: space-between;
  position: relative;
`
export const CartDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  & button{
    padding: 1em 3em;
    font-size: 1em;
    & svg{
      margin-left: .5em;
    }
  }
  @media screen and (max-width: 700px) {
    & button {
      padding: 1em;
    }
  }
`
export const CartTicketCounter = styled.div`
  width: 100px;
`
export const CartCheckout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & h3{
    font-size: 1.4em;
    margin: 0 .5em 0 1em;
  }
  & button{
    padding: 1em 3em;
    font-size: 1em;
  }
  @media screen and (max-width: 700px) {
    & button {
      padding: 1em;
    }
  }
  
`

export const CartDetailsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  color: var(--color-text);
  & svg{
    margin: 0 0.5em;
  }
  &:hover{
    color: var(--color-primary);
  }
`
export const CloseReservationPopupContainer = styled.div`
  padding: 1em;
`
export const PopupText = styled.div`
  font-size: 1em;
  font-weight: 400;
  padding: 0 1em;
  text-align: center;
  & svg{
    margin-bottom: 1em;
    width: 32px;
    height: 32px;
    color: var(--color-shadow-5)
  }
`
export const PopupMobileText = styled.div`
  font-size: .9em;
  font-weight: 400;
  padding: 0 1em;
  text-align: center;
  & svg{
    margin-bottom: 1em;
    width: 32px;
    height: 32px;
    color: var(--color-shadow-5)
  }
`
export const PopupButton = styled.div`
  padding: 1em;
  font-size: 1.2em;
  & button{
    padding: .5em 1em;
  }
`
export const CartBarDetailsListContainer = styled.div`
  @keyframes smooth-appear-list {
    from{
      bottom: 0;
      opacity:0.8;
    }
    to{
      bottom: calc(100% + 2.1em);
      opacity:1;
    }
  }
  position: absolute;
  min-height: 200px;
  width: 450px;
  @media screen and (max-width: 700px) {
    left: 1em;
    right: 1em;
    width: calc(100% - 2em);
  }
  border-radius: var(--border-radius-3);
  background: var(--color-background);
  -webkit-box-shadow: 0 1px 3px 0 var(--color-shadow-02), 0 3px 10px 0 var(--color-shadow-04), 0 7px 24px 0 var(--color-shadow-05), 0 24px 80px 0 var(--color-shadow-07);
  box-shadow: 0 1px 3px 0 var(--color-shadow-02), 0 3px 10px 0 var(--color-shadow-04), 0 7px 24px 0 var(--color-shadow-05), 0 24px 80px 0 var(--color-shadow-07);
  animation: smooth-appear-list .2s linear forwards;
`
export const ClearAllButton = styled.button`
    background: transparent;
    border: none;
    transition: all ease-in-out .3s;
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out .3s;
    }
`
export const Title = styled.h1`
    font-size: 1.2em;
    color: var(--color-shadow-8);
    font-weight: 600;
    margin: 1em 0;
`
export const CardBarDetailsTitle = styled.div`
  width: 100%;
  background: var(--color-shadow-06);
  border-radius: var(--border-radius-3) var(--border-radius-3) 0px 0px;
  padding: 1em;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const CardBarDetailsContent = styled.div`
  padding: 1em;
`
export const CardBarDetailsTicketItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const CardBarDetailsTicketItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-shadow-06);
  margin: 0.5em 0px;
  border-radius: var(--border-radius-2);
  & .delete{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2em 1.5em;
    cursor: pointer;
    transition: all ease-in-out .3s;
    & svg{
      width: 1.2em;
      height: 1.2em;
    }
    &:hover{
      color: var(--color-primary);
      transition: all ease-in-out .3s;
    }
  }
  & .package-info{
    padding: 1em;
    width: 100%;
    border-right: 1px dashed var(--color-shadow-06);
    & span{
      & b{
        margin-left: .3em;
      }
    }
  }
  & .package-name{
    & span{
      font-weight: 500;
      margin-left: .5em;
    }
  }
  & .package-price{
    margin-right: 1em;
  }
  & .ticket-row{
    margin-right: .5em;
  }
`
export const ListLoading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & .spinner-border{
        color: var(--color-primary) !important;
    }
`
export const MainContainer = styled(Row)`
    padding-top: 1em;
`
export const MainWrapper = styled(Col)`
    
`
export const EventCardContainer = styled.div`
    width: 100%;
    box-shadow: 0 2px 5px var(--color-shadow-04), 0 0 2px var(--color-shadow-02);
`
export const EventCardImage = styled.div`
    width: 100%;
    height: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('${(props) => props.image}');
    border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
`
export const EventDetails = styled.div`
    width: 100%;
    border: 1px solid var(--color-shadow-1);
    border-radius: 0 0 var(--border-radius-3) var(--border-radius-3);
    padding: 1.5em;
`
export const EventTitle = styled.h1`
    font-size: 1em;
    line-height: 1.7em;
`
export const EventPlace = styled.h3`
    font-size: .9em;
    font-weight: 400;
    color: var(--color-shadow-5);
`
export const EventTime = styled.div`
    padding-top: .5em;
`
export const DateTime = styled.div`
  color: var(--color-shadow-6);
  font-weight: 400;
  padding: .1em 0;
  font-size: .9em;
  margin-right: .6em;
  & svg{
    margin-right: .4em;
    width: 16px;
    height: 16px;
    color: var(--color-secondary);
  }
`
export const EventTicketSeats = styled.div`
    border-top: 1px solid var(--color-shadow-1);
    margin-top: 1em;
    padding-top: 1em;
`
export const EventTicketSeatRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1em 0;
`
export const EventTicketSeatRowIcon = styled.div`
    width: 50px;
    display: flex;
    justify-content: center;
    & svg{
        width: 24px;
        height: 24px;
        color: ${(props) => props.color};
    }
`
export const EventTicketSeatRowDetails = styled.div`
    width: 100%;
    & h1{
        font-size: 1em;
        font-weight: 500;
        margin: .2em 0;
    }
    & h3{
        font-size: .9em;
        font-weight: 400;
        & span{
            color: var(--color-shadow-6);
            &:last-child{
                margin-left: .5em;
            }
        }
    }
`
export const EventTicketSeatRowPrice = styled.div`
    & h1{
        font-size: 1em;
        font-weight: 500;
        margin: .2em 0;
        text-align: center;
    }
    & h3{
        font-size: .9em;
        font-weight: 400;
        white-space: nowrap;
        text-align: center;
    }
`
export const CardContainer = styled.div`
    width: 100%;
    padding: 1.5em;
    box-shadow: 0 2px 5px var(--color-shadow-04), 0 0 2px var(--color-shadow-02);
    border: 1px solid var(--color-shadow-1);
    border-radius: var(--border-radius-3);
    margin-bottom: 1em;
`
export const OptionContainer = styled.div`
    width: 100%;
    padding: 1em 1em 0 1em;
    & label{
        font-size: .9em;
        font-weight: 400;
            margin-bottom: 0.2em;
    }
`
export const RadioOptionContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5em;
`
export const RadioOptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em 0 1em;
    & div:first-child{
        border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
    }
    & div:last-child{
        border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
    }
`
export const RadioButton = styled.div`
    padding: 1em 0;
    margin-bottom: .4em;
    text-align: center;
    font-size: .9em;
    width: 100%;
    
    ${(props) => !props.active?(
        `
            cursor: pointer;
            background: var(--color-shadow-09);
            &:hover{
                background: var(--color-shadow-2);
                transition: all ease-in-out .3s;
            }
        `  
    ):(
        `
            background: var(--color-primary);
            color: #fff;
        `
    )}
`
export const RadioOption = styled(Field)`
    width: 1.4em;
    height: 1.4em;
    margin-right: .5em;
    
`
export const RadioOptionLabel = styled.div`
    font-size: 1em;
`
export const SummaryContainer = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`
export const SummaryRow = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1em;
    margin: .4em 0;
    & b{
        font-weight: 400;
    }
    ${(props) => props.topLine && `
        border-top: 1px solid var(--color-shadow-1);
        margin-top: 1.2em;
        padding-top: 1.2em;
    `}
    & .total{
        font-weight: 500;
        font-size: 1.3em;
    }
`