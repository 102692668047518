import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {RegisterContainer} from "./style";

class Registration extends Component {
    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                <Container>
                    <RegisterContainer>
                        {children}
                    </RegisterContainer>
                </Container>
            </React.Fragment>
        );
    }
}

export default Registration;