import React, {Component} from 'react';
import {Container} from "./style.jsx";
class SimpleCard extends Component {

    render() {
        const {children} = this.props;
        return (
            <Container>
                {children}
            </Container>
        );
    }
}
SimpleCard.propTypes = {

}
export default SimpleCard;