import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import Card from "../../components/ui/Card";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import CommonService from "../../services/CommonService";
import Swiper from "../../components/ui/Swiper";
import ContentLoading from "../../components/ui/ContentLoading";

class PastEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true
        }
    }
    componentDidMount = async () => {
        await this.fetchData();
    }
    fetchData = async () => {
        try{
            this.setState({ loading: true });
            let seconds = new Date().getTime();
            let result = await CommonService.doGet("/public/past_events_gallery");
            let events = [];
            result.map((row) => {
                if(row.gallery && row.gallery.length > 0){
                    row.gallery.map((gallery) => {
                        gallery.title = row.title;
                        events.push(gallery);
                    })

                }
            })
            events = this.splitArrIntoChunks(events);
            setTimeout(() => {
                this.setState({
                    data: events,
                    loading: false
                })
            }, 1000 - (new Date().getTime() - seconds))

        }catch (e){
            console.log(e)
        }
    }
    splitArrIntoChunks = (array) => {
        if(array.length <= 8) return [array];
        let accum = [];
        let indexToSplit = Math.ceil(array.length / 2);
        let first = array.slice(0, indexToSplit);
        let second = array.slice(indexToSplit);

        return [first, second];
    }
    renderLoadingData = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <Col md={12}>
                <ContentLoading height={"230px"}>
                    <rect x="0" y="0" rx="5" ry="5" width="30%" height="230" />
                    <rect x="31%" y="0" rx="5" ry="5" width="30%" height="230" />
                    <rect x="62%" y="0" rx="5" ry="5" width="30%" height="230" />
                    <rect x="93%" y="0" rx="5" ry="5" width="8%" height="230" />
                </ContentLoading>
                <ContentLoading height={"230px"}>
                    <rect x="0%" y="0" rx="5" ry="5" width="8%" height="230" />
                    <rect x="9%" y="0" rx="5" ry="5" width="30%" height="230" />
                    <rect x="40%" y="0" rx="5" ry="5" width="30%" height="230" />
                    <rect x="71%" y="0" rx="5" ry="5" width="30%" height="230" />
                </ContentLoading>
            </Col>

        )
    }
    render() {
        const {applicationContext} = this.props;
        const {data, loading} = this.state;
        return (
            <Card title={data && data.length === 0 && applicationContext.translator("Past Events")}>
                <Row>
                    {!loading && data.map((row, index) => {
                        return (<Swiper events={row} key={index}/>)
                    })}
                    {this.renderLoadingData()}
                </Row>
            </Card>
        );
    }
}

export default withApplicationContext(PastEvents);