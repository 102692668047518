import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {PaymentContainer, PaymentWrapper, Text, Title} from "./style";

class PaymentOptions extends Component {
    render() {
        const {applicationContext, line} = this.props;
        return (
            <PaymentWrapper line={line}>
                <Text marginTop={".5em"}>{applicationContext.translator("Fast & secured payment")}<br/>Instant confirmation</Text>
                <PaymentContainer>
                    <img src={"/assets/images/visa-logo.png"} />
                    <img src={"/assets/images/mastercard-logo.png"} />
                </PaymentContainer>
            </PaymentWrapper>
        );
    }
}

export default withApplicationContext(PaymentOptions);