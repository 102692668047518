import React, {Component} from 'react';
import {LoadingBox} from "./style.jsx";
import PropTypes from "prop-types";
import ContentLoader from "react-content-loader";
import {withApplicationContext} from "../../../contexts/ApplicationContext";

class ContentLoading extends Component {

    render() {
        const {children, height, smHeight, applicationContext, paddingTop} = this.props;
        let backgroundColor = "#f5f6f7";
        let foregroundColor = "#eee";
        if(applicationContext.state.theme === "dark-theme"){
            backgroundColor = "#121212";
            foregroundColor = "#3f3f3f";
        }
        return (
            <LoadingBox height={height} smHeight={smHeight} paddingTop={paddingTop}>
                <ContentLoader width={"100%"} height={"100%"} backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
                    {children}
                </ContentLoader>
            </LoadingBox>
        );
    }
}
ContentLoading.propTypes = {
    height: PropTypes.string,
    smHeight: PropTypes.string,
    paddingTop: PropTypes.string
}
export default withApplicationContext(ContentLoading);