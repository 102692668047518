import {convertToDate} from "./helpers";

Object.prototype.searchTrim = function(){
    Object.keys(this).forEach((key) => {
        if(typeof this[key] === "string")
            this[key] = this[key].replaceAll(" ", "");
    })
    return this;
}
Object.prototype.isEmptyArray = function(){
    let empty = true;
    Object.keys(this).forEach((key) => {
        if(this[key] !== "")
            empty = false;
    })
    return empty;
}
Object.prototype.parseDrawItems = function(){
    let _list = [];
    this.forEach(function (obj){
        let persianDate = convertToDate(obj['date'],  "fa", 'YYYY-MM-DD', 'dddd, D MMMM YYYY');
        _list.push({
            labelKey: obj['id'],
            value: obj['date'] + " ("+persianDate+")" + " #" + obj['num_of_draw'],
            isSelected: false
        })

    });
    return _list;
}
Object.prototype.disableAllItems = function(){
    let _list = [];
    this.forEach(function (obj){
        _list.push({
            labelKey: obj.labelKey,
            value: obj.value,
            isSelected: false
        })

    });
    return _list;
}

Object.prototype.emptyValues = function(){
    let output = [];
    Object.keys(this).forEach(function (key){
        output[key] = "";
    });
    return output;
}

Object.prototype.searchById = function(id){
    let output = null;
    this.forEach(function (item){
        if(item.id === id){
            output = item;
        }
    });
    return output;
}
