import React, {Component} from 'react';
import {Container, Item, ItemTitle, ItemValue, ItemValueWrapper} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
class DetailsTable extends Component {

    render() {
        const {data, applicationContext} = this.props;

        return (
            <Container>
                {data.map((item) => {
                    return (
                        <Item>
                            <ItemTitle>{applicationContext.translator(item.title)}</ItemTitle>
                            <ItemValue dir={applicationContext.translator("direction")}>
                                <ItemValueWrapper>
                                    {(typeof item.value === "string")?applicationContext.translator(item.value):item.value}
                                </ItemValueWrapper>
                            </ItemValue>
                        </Item>
                    )
                })}
            </Container>
        );
    }
}
DetailsTable.propTypes = {
    data: PropTypes.array.isRequired
}
export default withApplicationContext(DetailsTable);