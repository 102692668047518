import React, {Component} from 'react';
import {Container, LogoImage} from "./style.jsx";
import PropTypes from "prop-types";

class Logo extends Component {

    render() {
        const {marginBottom, width, native} = this.props;
        return (
            <Container marginBottom={marginBottom} native={native}>
                <LogoImage src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} width={width}/>
            </Container>
        );
    }
}
Logo.propTypes = {
    marginBottom: PropTypes.string,
    width: PropTypes.string,
    native: PropTypes.bool
}
export default Logo;