import React, {Component} from 'react';
import {withEventTicketsContext} from "./EventTicketsContext";
import {SelectTicketContainer} from "./style";
import CountDown from "../../components/ui/CountDown";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import SelectTicketButton from "./SelectTicketButton";
class SelectTicket extends Component {
    onCompletedTime = () => {
        const {context} = this.props;
        context.doUpdateState({
            closeReservation: true
        });
    }

    render() {
        const {context} = this.props;
        const {eventItem, closeReservation} = context.state;
        return (
            <SelectTicketContainer>
                <CountDown timer={new Date(eventItem['close_reservation'])} onCompletedTime={this.onCompletedTime} />
                <SelectTicketButton closeReservation={closeReservation} eventItem={eventItem} />
            </SelectTicketContainer>
        );
    }
}

export default withEventTicketsContext(SelectTicket);