import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container} from "./style";
import * as Yup from "yup";
import {parseErrorMessage, passwordCheck} from "../../../helpers";
import {Col, Form, Row} from "react-bootstrap";
import {FormDescription, FormTitle} from "../../Login/style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import FormErrorText from "../../../components/ui/FormErrorText";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import Service from "../../../services/Service";
import SuccessfullMessageBox from "../../../components/ui/SuccessfullMessageBox";
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                current_password: "",
                password: "",
                password_confirmation: ""
            },
            error: null,
            submitting: false,
            successfully: false
        }


    }
    handleSubmit = async (values, actions) => {
        const {applicationContext} = this.props;
        this.setState({ submitting: true });
        try {
            await Service.doChangePassword(values);
            this.setState({
                error: null,
                submitting: false,
                successfully: true
            })
        } catch (err) {
            let error = null;
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                error = applicationContext.translator(parseErrorMessage(err));
            }
            this.setState({
                submitting: false,
                error
            });

        }
    }
    render() {
        const {applicationContext} = this.props;
        const {inputs, error, submitting, successfully} = this.state;
        return (
            <Container>
                {(successfully)?(
                    <SuccessfullMessageBox
                        title={applicationContext.translator("Password Changed!")}
                        messages={[applicationContext.translator("Your password has been changed successfully.")]}
                    />
                ):(
                    <Formik
                        innerRef={(ref) => this.refForm = ref}
                        initialValues={inputs}
                        validationSchema={Yup.object().shape({
                            current_password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Current Password")),
                            password: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                                .test('password', applicationContext.translator("The password format is incorrect"), (value) => {
                                    let check = passwordCheck(value);
                                    return (check.message === "Strong")
                                })
                                .label(applicationContext.translator("Password")),
                            password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                                .oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))
                                .label(applicationContext.translator("Password Confirmation"))
                        })}
                        onSubmit={this.handleSubmit}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue
                          }) =>
                            (
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <FormTitle>{applicationContext.translator("Change Password")}</FormTitle>
                                    <FormDescription>{applicationContext.translator("Use the form below to change the password for your account")}</FormDescription>
                                    <Row>
                                        <Col md={12}>
                                            <InputBox type={"password"}
                                                      name={"current_password"}
                                                      onChange={handleChange}
                                                      placeholder={applicationContext.translator("Current Password")}
                                                      value={values.current_password}
                                                      save={"off"}
                                                      errors={errors}
                                                      touched={touched}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputBox type={"password"}
                                                      name={"password"}
                                                      onChange={handleChange}
                                                      placeholder={applicationContext.translator("Password")}
                                                      value={values.password}
                                                      save={"off"}
                                                      errors={errors}
                                                      touched={touched}
                                                      strength={true}
                                                      hint={"The password must contain at least 8 english characters and include upper and lower case letters, numbers and a symbol"}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputBox type={"password"}
                                                      name={"password_confirmation"}
                                                      onChange={handleChange}
                                                      placeholder={applicationContext.translator("Password Confirmation")}
                                                      value={values.password_confirmation}
                                                      save={"off"}
                                                      errors={errors}
                                                      touched={touched}
                                                      strength={true}
                                            />
                                        </Col>
                                    </Row>
                                    {error && <FormErrorText>{error}</FormErrorText>}
                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} fullWidth={true} size={3} submitting={submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Reset Password")}</Button>
                                    </Form.Group>
                                </Form>
                            )}
                    </Formik>
                )}

            </Container>

        );
    }
}

export default withApplicationContext(withRouter(withAccountContext(ChangePassword)));