import React, {Component} from 'react';
import {Code, Container, Wrapper} from "./style.jsx";
import Link from "../Link";

class PageNotFound extends Component {

    render() {
        return (
            <Container>
                <Wrapper>
                    <Code>404</Code>
                    <p>
                        Sorry, we couldn't find this page.<br/>
                        but don't worry you can find plenty of other<br/>
                        things on our <Link to={"/"}>homepage</Link>.
                    </p>
                </Wrapper>
            </Container>
        );
    }
}
export default PageNotFound;