import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withCartContext} from "./CartContext";
import Button from "../../components/ui/Button";
import {CartCheckout} from "./style";
import CountDown from "../../components/ui/CountDown";
import CloseReservationPopup from "./CloseReservationPopup";
import {withAccountContext} from "../../contexts/AccountContext";
import {generateUrl, parseErrorMessage} from "../../helpers";
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import LoginPopup from "../Login/Popup/LoginPopup";
import {ROUTE_CHECKOUT} from "../../routes/routes";

class CartBarCheckout extends Component {
    onCompletedTime = async () => {
        const {cartContext, applicationContext} = this.props;
        cartContext.doUpdateState({
            closeReservation: true
        })
        await cartContext.state.cart.clear();
        applicationContext.onOpenConfirmPopup(null,
            <CloseReservationPopup handleRefresh={this.handleRefresh}/>,
            null,
            this.handleRefresh,
            "sm",
            true,
            null
        );

    }
    handleRefresh = (e) => {
        window.location.reload();

    }
    onClickCheckout = async (e) => {
        const {cartContext} = this.props;
        if(cartContext.state.cart.count() === 0) return;
        await cartContext.onCheckAuthenticated(this.handleCheckout);
    }

    handleCheckout = async () => {
        const {applicationContext, alert, navigate, cartContext} = this.props;
        this.setState({ submitting: true })
        applicationContext.onClosePopup();
        try{
            navigate(generateUrl(ROUTE_CHECKOUT, ['basket_id'], [cartContext.state.cart.getCartId()]))
        }catch (err){
            this.setState({ submitting: false });
            alert.error(applicationContext.translator(parseErrorMessage(err)));
        }

    }
    render() {
        const {cartContext, applicationContext} = this.props;
        const {cart, closeReservation} = cartContext.state;
        return (
            <CartCheckout>
                <CountDown timer={cart.expire()}
                           theme={"normal"}
                           debug={true}
                           onCompletedTime={this.onCompletedTime}
                />
                <h3>{cart.total()}</h3>
                <Button disabled={closeReservation || cart.count() === 0} color="primary" size="lg" margin={"0 1em"} onClick={this.onClickCheckout}>
                    {applicationContext.translator("Checkout")}
                </Button>
            </CartCheckout>
        );
    }
}

export default withAlert()(withApplicationContext(withCartContext(withAccountContext(withRouter(CartBarCheckout)))));