import React, {Component} from 'react';
import {withCheckoutContext} from "./CheckoutContext"
import withRouter from "../../../contexts/withRouter";
import {BackButtonContainer} from "../style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {generateUrl, slugify} from "../../../helpers";
import {ROUTE_CART} from "../../../routes/routes";
import {Row} from "react-bootstrap";
import Link from "../../../components/ui/Link";
class BackToCart extends Component {
    render() {
        const {checkoutContext} = this.props;
        const {cart} = checkoutContext.state;
        return (
            <Row>
                <BackButtonContainer md={12}>
                    <Link to={generateUrl(ROUTE_CART, ["id", "slug"], [cart.event.id, slugify(cart.event.title)])}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                    <h1>Check out</h1>
                </BackButtonContainer>
            </Row>

        );
    }
}

export default withCheckoutContext(withRouter(BackToCart));