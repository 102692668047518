import styled from "styled-components";
import {Col, Row} from 'react-bootstrap';
export const LoadMore = styled.div`
    text-align: center;
    font-size: 1.1em;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display:flex;
    justify-content: center;
    align-items: center;
    a {
        background: var(--color-shadow-04);
        padding: 0.5em 1em;
        border-radius: var(--border-radius-2);
        color: var(--color-text);
        transition: all 0.3s ease-in-out;
    }
    & a:hover{
        color: var(--color-primary);
        transition: all 0.3s ease-in-out;
    }
`
export const ColContainer = styled(Col)`
    padding: ${(props) => props.padding?props.padding:`1em`};
    
  @media screen and (min-width: 700px) {
    
  }
`
export const Container = styled.div`
  padding: 1em 2em;
  @media screen and (max-width: 700px) {
    padding: 1em 0em;
    width: 100%;
  }
`
export const StageImage = styled.img`
    width: 100%;
    
`
export const StageImageTitle = styled.h1`
  font-size: .9em;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1em;
`
