import React, {Component} from 'react';
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormDescription, FormTitle} from "../Login/style";
import Link from "../../components/ui/Link/Link";
import {ROUTE_LOGIN, ROUTE_RESET_PASSWORD_BY_EMAIL, ROUTE_RESET_PASSWORD_BY_MOBILE} from "../../routes/routes";
import EmailCheckInbox from "./EmailCheckInbox";
import {Formik} from "formik";
import * as Yup from "yup";
import InputBox from "../../components/ui/InputBox/InputBox";
import Button from "../../components/ui/Button";
import CountryDropDown from "../../components/ui/CountryDropDown/CountryDropDown";
import EnterMobileCodeForm from "../Register/Forms/EnterMobileCodeForm";
import {parseErrorMessage, passwordCheck} from "../../helpers";
import FormErrorText from "../../components/ui/FormErrorText";
import Spinner from "../../components/ui/Spinner/Spinner";
import SuccessfullMessageBox from "../../components/ui/SuccessfullMessageBox";
import Service from "../../services/Service";
import CommonService from "../../services/CommonService";

class ResetByMobileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                mobile: "",
                mobile_country: props.applicationContext.state.common['default_country']['mobile_prefix_clear'],
            },
            endpoint: null,
            mobileClear: null,
            step: 1,
            submitting: false,
            successfully: false,
            loading: true,

        }
    }
    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    handleSubmit = async (values, actions) => {
        const {applicationContext} = this.props;
        this.setState({ submitting: true });
        try {
            const result = await Service.doResetPasswordByMobile({
                mobile_country: values.mobile_country,
                mobile: values.mobile,
            });
            this.setState({
                step: 2,
                endpoint: result.endpoint,
                mobileClear: result.mobile_no_nice,
                error: null,
                submitting: false
            })
        } catch (err) {
            let error = null;
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                error = applicationContext.translator(parseErrorMessage(err));
            }
            this.setState({
                submitting: false,
                error
            });

        }
    }
    handleVerifySubmit = async (values, actions) => {
        const {applicationContext, navigate} = this.props;
        this.setState({ submitting: true });
        try {
            const result = await CommonService.doPost(this.state.endpoint, {
                otp: values.code
            });
            navigate(result.endpoint);
        } catch (err) {
            let error = null;
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                error = applicationContext.translator(parseErrorMessage(err));
            }
            this.setState({
                submitting: false,
                error
            });

        }
    }
    render() {
        return (
            <React.Fragment>
                {this.renderComponent()}
            </React.Fragment>
        );
    }
    renderComponent = () => {
        const {applicationContext} = this.props;
        const {loading, successfully, step, submitting, mobileClear, error} = this.state;
        if(loading)
            return <Spinner size={"md"} show={loading} inline={false}/>
        else if(step === 1)
            return this.renderForm();
        else
            return (<EnterMobileCodeForm submitting={submitting}
                                         onSubmit={this.handleVerifySubmit}
                                         mobile={mobileClear}
                                         error={error}
            />)
    }
    renderForm = () => {
        const {applicationContext} = this.props;
        const {error, inputs, submitting} = this.state;
        return (
            <React.Fragment>
                <Formik
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.inputs}
                    validationSchema={Yup.object().shape({
                        mobile: Yup.string().required("You must enter your ${path}").min(9, applicationContext.translator("You must enter minimum 9 digits")).label(applicationContext.translator("Mobile number")),
                    })}
                    onSubmit={this.handleSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit}
                                  style={{direction: applicationContext.translator("direction")}}>
                                <FormTitle>{applicationContext.translator("Reset Password")}</FormTitle>
                                <FormDescription>{applicationContext.translator("Enter your mobile number to reset your password")}</FormDescription>
                                <Row>
                                    <Col md={6}>
                                        <CountryDropDown
                                            name={"mobile_country"}
                                            onChange={setFieldValue}
                                            value={values.mobile_country}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputBox type="text"
                                                  onlyNumber={true}
                                                  name={"mobile"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("Mobile number")}
                                                  value={values.mobile}
                                                  maxLength={12}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                    </Col>
                                </Row>
                                {error && <FormErrorText>{error}</FormErrorText>}
                                <Form.Group className={"text-center"}>
                                    <Button color={"primary"} fullWidth={true} size={3}
                                            submitting={this.state.submitting} radius={10} className={"btn btn-account"}
                                            type={"submit"}>{applicationContext.translator("Reset Password")}</Button>
                                </Form.Group>
                            </Form>
                        )}
                </Formik>

                <Form.Group as={Row} className={"mt-5"}>
                    <Col className={"text-center"}>
                        <BlockText>
                            {applicationContext.translator("Do you want use your e-mail address?")} <Link
                            to={ROUTE_RESET_PASSWORD_BY_EMAIL}>{applicationContext.translator("Click here to reset")}</Link>
                        </BlockText>
                    </Col>
                </Form.Group>
            </React.Fragment>
        )
    }
}

export default withAlert()(withRouter(withApplicationContext(ResetByMobileForm)));