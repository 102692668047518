import styled from "styled-components";

export const Container = styled.div`
    display: table;
  width: 100%;
  @media screen and (max-width: 700px) {
    display: block;
  }
`;
export const Item = styled.div`
    display: table-row;
  
  border-bottom: 2px solid rgba(0,0,0,0.2);
  &:last-child{
    border: 0;
  }
  @media screen and (max-width: 700px) {
    display: block;
  }
`;
export const ItemTitle = styled.div`
  display: table-cell;
  font-weight: 600;
  background: rgba(0,0,0,0.2);
  border-bottom: 2px solid var(--color-popup-background);
  @media screen and (min-width: 701px) {
    min-width: 180px;
    max-width: 180px;
    padding: .8em .8em;
  }
  
  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    padding: .5em;
    text-align: center;
  }
`;
export const ItemValue = styled.div`
  display: table-cell;
  overflow-wrap: break-word;
  text-align: ${(props) => props.dir === "rtl"?"left":"right"};
  padding: 0 1em;
  & a{
    color: var(--color-primary);
    text-decoration: underline;
    transition: all ease-in-out .3s;
    &:hover{
      color: var(--color-text);
    }
  }
  @media screen and (max-width: 700px) {
    display: block;
    width: 100%;
    padding: .5em 0;
    text-align: center;
    font-size: .9em;
  }
`;
export const ItemValueWrapper = styled.div`
  @media screen and (min-width: 701px) {
    max-width: 100%;
  }
  
`