import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withCheckoutContext} from "./CheckoutContext";
import Button from "../../../components/ui/Button";
import {CartCheckout} from "../style";
import CountDown from "../../../components/ui/CountDown";
import {withAccountContext} from "../../../contexts/AccountContext";
import {generateUrl, slugify} from "../../../helpers";
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {ROUTE_CART} from "../../../routes/routes";
import {withFormContext} from "../../../contexts/FormContext";
import {Spinner} from "react-bootstrap";

class CheckoutBarPay extends Component {
    onCompletedTime = async () => {
        const {checkoutContext, navigate} = this.props;
        navigate(generateUrl(ROUTE_CART, ["id", "slug"], [checkoutContext.state.cart.event.id, slugify(checkoutContext.state.cart.event.title)]));
    }

    onClickPay = async (e) => {
        const {checkoutContext} = this.props;
        if(checkoutContext.state.closeReservation || checkoutContext.state.cart.items.length === 0) return;



    }

    render() {
        const {checkoutContext, applicationContext, formContext} = this.props;
        const {cart, closeReservation} = checkoutContext.state;
        return (
            <CartCheckout>
                <CountDown timer={new Date(cart['expired_at'])}
                           theme={"normal"}
                           debug={true}
                           onCompletedTime={this.onCompletedTime}
                />
                <h3>{cart.summary.checkout.total}</h3>
                <Button disabled={closeReservation || cart.items.length === 0 || formContext.submitting} color="primary" size="lg" margin={"0 1em"} type={"submit"}>
                    {formContext.submitting?<Spinner animation="border" size={"sm"} />:applicationContext.translator("Pay")}
                </Button>
            </CartCheckout>
        );
    }
}

export default withAlert()(withApplicationContext(withCheckoutContext(withAccountContext(withRouter(withFormContext(CheckoutBarPay))))));