import React, {Component} from 'react';
import {CloseReservationPopupContainer, PopupButton, PopupText} from "./style";
import Button from "../../components/ui/Button";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withCartContext} from "./CartContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
class CloseReservationPopup extends Component {
    render() {
        const {applicationContext, handleRefresh} = this.props;
        return (
            <PopupText>
                <FontAwesomeIcon icon={faClock} /><br/>
                {applicationContext.translator("You've been away for a while.")}<br/>
                {applicationContext.translator("Just refresh the page to continue")}
                <PopupButton>
                    <Button color={"primary"} fullWidth={true} onClick={handleRefresh} margin={"0 .2em"}>
                        {applicationContext.translator("Refresh")}
                    </Button>
                </PopupButton>
            </PopupText>
        );
    }
}

export default withApplicationContext(withCartContext(CloseReservationPopup));