import React from "react";
const AdminContext = React.createContext({});
const AdminProvider = AdminContext.Provider;
const AdminConsumer = AdminContext.Consumer;
const withAdminContext = Component => React.forwardRef((props, ref) => (
    <AdminConsumer>
        {(response) => {
            return <Component {...props} adminContext={response} ref={ref}/>;
        }}
    </AdminConsumer>
));
export { AdminProvider, AdminConsumer, withAdminContext };
