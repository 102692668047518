import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
    ROUTE_ABOUT,
    ROUTE_ACCOUNT,
    ROUTE_ACCOUNT_CHANGE_PASSWORD, ROUTE_ACCOUNT_ENTER_NEW_MOBILE,
    ROUTE_ACCOUNT_PAYMENTS, ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL, ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE,
    ROUTE_ACCOUNT_TICKETS, ROUTE_ACCOUNT_UPDATE_EMAIL, ROUTE_ACCOUNT_UPDATING_EMAIL,
    ROUTE_ADMIN_ADD_ARTIST,
    ROUTE_ADMIN_ADD_EVENT,
    ROUTE_ADMIN_ADD_EVENT_PLACE,
    ROUTE_ADMIN_ALL_MEMBERS,
    ROUTE_ADMIN_ARTISTS,
    ROUTE_ADMIN_EVENT_PLACES,
    ROUTE_ADMIN_EVENTS,
    ROUTE_ADMIN_LOGIN,
    ROUTE_ADMIN_OVERVIEW, ROUTE_ADMIN_PAYMENTS, ROUTE_ADMIN_TICKET_VALIDITY, ROUTE_ADMIN_TICKETS,
    ROUTE_ADMIN_UPDATE_ARTIST,
    ROUTE_ADMIN_UPDATE_EVENT,
    ROUTE_ADMIN_UPDATE_EVENT_PLACE,
    ROUTE_CART,
    ROUTE_CHECKOUT,
    ROUTE_EVENT_TICKETS,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_MOBILE_VERIFICATION, ROUTE_MUSICIANS,
    ROUTE_NOTFOUND,
    ROUTE_PAYMENT,
    ROUTE_REGISTER,
    ROUTE_RESET_PASSWORD,
    ROUTE_RESET_PASSWORD_BY_EMAIL,
    ROUTE_RESET_PASSWORD_BY_MOBILE
} from "./routes";
import BaseMaster from "../scenes/BaseMaster";
import AdminBaseMaster from "../scenes/Admin/BaseMaster";
import Home from "../scenes/Home/Home";
import Login from "../scenes/Login/Login";
import AdminLogin from "../scenes/Admin/Login";
import Register from "../scenes/Register/Register";
import EventTickets from "../scenes/EventTickets/EventTickets";
import CartView from "../scenes/Cart/CartView";
import Page from "../scenes/Page/Page";
import Profile from "../scenes/MyAccount/Profile/Profile";
import MobileEnterCode from "../scenes/Register/MobileEnterCode";
import ResetPassword from "../scenes/ResetPassword/ResetPassword";
import ChangePassword from "../scenes/MyAccount/ChangePasswod";
import Overview from "../scenes/Admin/Overview";
import Users from "../scenes/Admin/Users";
import EventPlaces from "../scenes/Admin/EventPlaces";
import EventPlaceDataForm from "../scenes/Admin/EventPlaces/EventPlaceDataForm";
import Artists from "../scenes/Admin/Artists";
import ArtistFormData from "../scenes/Admin/Artists/ArtistFormData";
import {
    SERVICE_ARTISTS,
    SERVICE_EVENT_PLACES,
    SERVICE_EVENTS, SERVICE_PAYMENTS,
    SERVICE_TICKETS,
    SERVICE_USERS
} from "../services/services";
import Events from "../scenes/Admin/Events";
import EventFormData from "../scenes/Admin/Events/EventFormData";
import Checkout from "../scenes/Cart/Checkout";
import Error404Page from "../scenes/Error404Page";
import Payment from "../scenes/Payment";
import ClientTickets from "../scenes/MyAccount/Tickets";
import ClientPayments from "../scenes/MyAccount/Payments";
import AdminTickets from "../scenes/Admin/Tickets";
import AdminPayments from "../scenes/Admin/Payments";
import TicketValidity from "../scenes/Admin/Tickets/TicketValidity";
import Musicians from "../scenes/Musicians";
import About from "../scenes/About";
import CurrentEmailCheckInbox from "../scenes/MyAccount/Profile/CurrentEmailCheckInbox";
import EnterNewEmailForUpdate from "../scenes/MyAccount/Profile/EnterNewEmailForUpdate";
import UpdatingEmailProfile from "../scenes/MyAccount/Profile/UpdatingEmailProfile";
import MobileVerificationForUpdate from "../scenes/MyAccount/Profile/MobileVerificationForUpdate";
import EnterNewMobileForUpdate from "../scenes/MyAccount/Profile/EnterNewMobileForUpdate";

class Routers extends Component {
    render() {
        return (
            <Router>
                <Routes>
                    <Route path={ROUTE_HOME} element={<BaseMaster />}>
                        <Route index element={<Home />} />
                        <Route path={ROUTE_LOGIN} element={<Login />} />
                        <Route path={ROUTE_REGISTER} element={<Register />} />
                        <Route path={ROUTE_MOBILE_VERIFICATION} element={<MobileEnterCode />} />
                        <Route path={ROUTE_RESET_PASSWORD_BY_EMAIL} element={<ResetPassword method={"email"} />} />
                        <Route path={ROUTE_RESET_PASSWORD_BY_MOBILE} element={<ResetPassword method={"mobile"} />} />
                        <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />} />


                        <Route path={ROUTE_EVENT_TICKETS} element={<EventTickets />} />
                        <Route path={ROUTE_CART} element={<CartView />} />
                        <Route path={ROUTE_PAYMENT} element={<Payment />} />
                        <Route path={ROUTE_MUSICIANS} element={<Musicians />} />
                        <Route path={ROUTE_ABOUT} element={<About />} />
                        <Route path={ROUTE_NOTFOUND} element={<Error404Page />} />

                    </Route>
                    <Route  path={"/account"} element={<BaseMaster auth={true} />}>
                        <Route path={ROUTE_ACCOUNT} element={<Profile />} />
                        <Route path={ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL} element={<CurrentEmailCheckInbox />} />
                        <Route path={ROUTE_ACCOUNT_UPDATE_EMAIL} element={<EnterNewEmailForUpdate />} />
                        <Route path={ROUTE_ACCOUNT_UPDATING_EMAIL} element={<UpdatingEmailProfile />} />
                        <Route path={ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE} element={<MobileVerificationForUpdate />} />
                        <Route path={ROUTE_ACCOUNT_ENTER_NEW_MOBILE} element={<EnterNewMobileForUpdate />} />
                        <Route path={ROUTE_ACCOUNT_CHANGE_PASSWORD} element={<ChangePassword />} />
                        <Route path={ROUTE_ACCOUNT_TICKETS} element={<ClientTickets />} />
                        <Route path={ROUTE_ACCOUNT_PAYMENTS} element={<ClientPayments />} />


                    </Route>
                    <Route path={"/administrator"} element={<AdminBaseMaster />}>
                        <Route path={ROUTE_ADMIN_LOGIN} element={<AdminLogin />} />
                        <Route path={ROUTE_ADMIN_OVERVIEW} element={<Overview />} />
                        <Route path={ROUTE_ADMIN_ALL_MEMBERS} element={<Users service={SERVICE_USERS}/>} />
                        <Route path={ROUTE_ADMIN_EVENT_PLACES} element={<EventPlaces service={SERVICE_EVENT_PLACES}/>} />
                        <Route path={ROUTE_ADMIN_ADD_EVENT_PLACE} element={<EventPlaceDataForm key={"add"} service={SERVICE_EVENT_PLACES}/>} />
                        <Route path={ROUTE_ADMIN_UPDATE_EVENT_PLACE} element={<EventPlaceDataForm key={"update"} service={SERVICE_EVENT_PLACES}/>} />
                        <Route path={ROUTE_ADMIN_ARTISTS} element={<Artists service={SERVICE_ARTISTS}/>} />
                        <Route path={ROUTE_ADMIN_ADD_ARTIST} element={<ArtistFormData key={"add"} service={SERVICE_ARTISTS}/>} />
                        <Route path={ROUTE_ADMIN_UPDATE_ARTIST} element={<ArtistFormData key={"update"} service={SERVICE_ARTISTS}/>} />
                        <Route path={ROUTE_ADMIN_EVENTS} element={<Events service={SERVICE_EVENTS}/>} />
                        <Route path={ROUTE_ADMIN_ADD_EVENT} element={<EventFormData key={"add"} service={SERVICE_EVENTS}/>} />
                        <Route path={ROUTE_ADMIN_UPDATE_EVENT} element={<EventFormData key={"update"} service={SERVICE_EVENTS}/>} />
                        <Route path={ROUTE_ADMIN_TICKETS} element={<AdminTickets service={SERVICE_TICKETS}/>} />
                        <Route path={ROUTE_ADMIN_PAYMENTS} element={<AdminPayments service={SERVICE_PAYMENTS}/>} />
                        <Route path={ROUTE_ADMIN_TICKET_VALIDITY} element={<TicketValidity service={SERVICE_TICKETS}/>} />


                    </Route>
                    <Route  path={"/order"} element={<BaseMaster auth={true} />}>
                        <Route path={ROUTE_CHECKOUT} element={<Checkout />} />
                    </Route>

                </Routes>
            </Router>
        );
    }
}

export default Routers;