import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Text} from "./style";

class ContactEmail extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Text>{applicationContext.translator("Customer service E-Mail")}</Text>
                <Text link={true}><a href={"mailto:support@musicnova.live"}>support@musicnova.live</a></Text>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(ContactEmail);