import styled from "styled-components";
export const Container = styled.div`
    max-width: 600px;
    margin: 5em auto;
    @media screen and (max-width: 700px) {
        width: 100%;
    }
`
export const Title = styled.h1`
    font-size: 1.4em;
    font-weight: 500;
    text-align: center;
    margin: 1em 0 2em 0;
    text-transform: capitalize;
`
export const Loader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`