import React, {Component} from 'react';
import {
    Table, Row, Col, HeaderCol, Value, TableSmall, HeaderRow,
    Pagination, Page, NotFound, NotFoundWrapper, Sort, HeaderWrapper,
    SortIcon, FooterRow, FooterCol, Actions, Action, ActionSpinner, GreenValue, RedValue
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {
    accountId,
    condition,
    convertToDate,
    convertToTime,
    numberWithCommas, parsePropertyByConcatValue,
    parsePropertyByDotValue
} from "../../../helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loading from "../Loading";
import {NavLink} from "react-router-dom";
import {Spinner} from "react-bootstrap";
class DataList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meta: null,
            data: [],
            loading: true,
            min_page: 1,
            max_page: 10,
            submitting: false,
            sum: []
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && prevProps.data !== this.props.data)
            this.handlePropData();
    }
    handlePropData = () => {
        if(!this.props.data) return;
        let data = this.props.data.hasOwnProperty('data')?this.props.data.data:this.props.data;
        let meta = {
            current_page: 1,
            from: 1,
            last_page: 1,
            path: "",
            per_page: 10,
            to: 1,
            total: data.length
        };
        if(this.props.data.hasOwnProperty('meta'))
            meta = this.props.data['meta'];
        this.setState({
            data,
            meta,
            loading: false
        })
    }
    componentDidMount() {
        this.handlePropData();
    }

    number = (value, index) => {
        return ((index + 1) + (this.state.meta.per_page * (this.state.meta.current_page - 1)));
    }
    date = (value) => {
        const {applicationContext} = this.props;
        return convertToDate(value, applicationContext.state.locale);
    }
    image = (value) => {
        if(value.url)
            return (<img height={80} src={value.url} />);
    }
    short_date = (value) => {
        const {applicationContext} = this.props;
        return convertToDate(value, applicationContext.state.locale, 'YYYY/MM/DD HH:mm:ss', "ddd, MMMM DD,YYYY");
    }
    datetime = (value) => {
        const {applicationContext} = this.props;
        if(!value) return "";
        return convertToDate(value, applicationContext.state.locale, 'YYYY/MM/DD HH:mm:ss', "ddd, MMMM DD YYYY HH:mm:ss");
    }
    time = (value) => {
        const {applicationContext} = this.props;
        return convertToTime(value, applicationContext.state.locale);
    }
    amount = (value) => {
        return numberWithCommas(value) + ".00 USD";
    }
    amountColor = (value) => {
        if(value > 0)
            return <GreenValue>{numberWithCommas(value) + ".00 USD"}</GreenValue>
        return <RedValue>{numberWithCommas(value) + ".00 USD"}</RedValue>
    }
    uniqueId = (value) => {
        return accountId(value)
    }
    numberColor = (value) => {
        if(value > 0)
            return <GreenValue>{numberWithCommas(value, false, ",")}</GreenValue>
        return <RedValue>{numberWithCommas(value, false, ",")}</RedValue>
    }
    numberCommas = (value) => {
        return numberWithCommas(value, false, ",");
    }
    parse = (value, column, index = null) => {
        if(typeof value === "string")
            return <Value dir={column.hasOwnProperty('dir') && column.dir}>{value}</Value>
        return value;
    }
    onClickAction = (e, action, row, rowIndex) => {
        e.preventDefault();
        if(this.state.submitting) return;
        if(!action.hasOwnProperty('submitting'))
            this.setState({ submitting: `${action.label}-${row.id}` })
        if(action.hasOwnProperty('onClick'))
            return action.onClick(row, (data = null, act = "update") => {
                if(act === "update"){
                    if(data){
                        let rows = this.state.data;
                        rows[rowIndex] = data;
                        this.setState({ data: rows })
                    }
                }
                else if(act === "delete"){
                    let rows = this.state.data;
                    rows.map((r, i) => {
                        if(r.id === data.id)
                            rows.splice(i, 1);
                    })
                    this.setState({ data: rows })
                }

                this.setState({ submitting: false })

            });
    }
    renderAction = (action, row, rowIndex) => {
        let isShow = true;
        if(action.hasOwnProperty('hidden') && action.hidden && condition(row, action.hidden)){
            isShow = false;
        }
        if(isShow)
            return (
                <Action disabled={(this.state.submitting === `${action.label}-${row.id}`)}
                        color={action.color}
                        to={action.hasOwnProperty('route')?action.route:"#"}
                        onClick={action.hasOwnProperty('onClick')?((e) => this.onClickAction(e, action, row, rowIndex)):null}
                >
                    {(this.state.submitting === `${action.label}-${row.id}`)?<ActionSpinner className={"spinner"} animation="border" variant="light" size={"sm"} />:null}
                    {action.hasOwnProperty('label')?action.label:""}
                    {action.hasOwnProperty('icon')?<FontAwesomeIcon icon={action.icon} />:""}
                </Action>
            );
        return null;
    }
    renderValue = (row, column, rowIndex, colIndex, pure = false) => {
        const {applicationContext} = this.props;
        let key  = column.key;
        let value = parsePropertyByDotValue(row, key);
        if(pure) return value;
        if(column.hasOwnProperty('actions')){
            let actions = [];
            column.actions.map((action) => {
                let act = this.renderAction(action, row, rowIndex);
                if(act)
                    actions.push(act);
            })
            return <Actions>{actions}</Actions>;
        }
        if(column.hasOwnProperty('format') && column.format && this[column.format])
            return this.parse(this[column.format](value, rowIndex, row), column);

        if(column.hasOwnProperty('render') && column.render)
            return this.parse(column.render(value, rowIndex, row), column);

        if(typeof value === "string" && !value.indexOf(":"))
            value = applicationContext.translator(value);
        return this.parse(value, column);
    }
    renderPagination = () => {
        let items = [];
        if(this.state.meta.last_page <= 10){
            Array.from(Array(this.state.meta.last_page).keys()).map((p) =>
                items.push(<Page current={this.state.meta.current_page === (p + 1)} onClick={(e) => this.onNavigate(p + 1)}>{p + 1}</Page>)
            )
        }else{
            if(this.state.min_page > 1){
                items.push(<Page onClick={this.onClickPrevPage}>...</Page>)
            }
            for(let p = this.state.min_page; p <= this.state.max_page; p++){
                items.push(<Page current={this.state.meta.current_page === p} onClick={(e) => this.onNavigate(p)}>{p}</Page>)
            }
            if(this.state.max_page < this.state.meta.last_page){
                items.push(<Page className={"btn-more"} onClick={this.onClickNextPage}>...</Page>)
            }
        }
        return items;
    }
    onNext = async (e) => {
        const {action} = this.props;
        if(this.state.meta.last_page > this.state.meta.current_page){
            await action(this.state.meta.current_page + 1);
            if(this.state.meta.current_page > this.state.max_page){
                this.setState({
                    min_page: this.state.max_page + 1,
                    max_page: (this.state.max_page + 10 <= this.state.meta.last_page)?this.state.max_page + 10:this.state.meta.last_page,
                })
            }
        }
    }
    onPrev = async (e) => {
        const {action} = this.props;
        if(this.state.meta.current_page > 1){
            await action(this.state.meta.current_page - 1);
            if(this.state.meta.current_page < this.state.min_page){
                let min = this.state.min_page - 10;
                let max = this.state.min_page - 1;
                this.setState({
                    min_page: min,
                    max_page: max,
                })
            }
        }
    }
    onFirst = async () => {
        const {action} = this.props;
        if(this.state.meta.current_page > 1) {
            await action(1);
            this.setState({
                min_page: 1,
                max_page: (this.state.meta.last_page > 10)?10:this.state.meta.last_page,
            })
        }
    }
    onLast = async () => {
        const {action} = this.props;
        if(this.state.meta.last_page > this.state.meta.current_page){
            await action(this.state.meta.last_page);
            let n = (this.state.meta.last_page / 10).toString().split(".");
            let m = 10;
            if(n.length == 2)
                m = parseInt(n[1]) - 1
            this.setState({
                min_page: (this.state.meta.last_page) - m,
                max_page: this.state.meta.last_page,
            })
        }

    }
    onNavigate = async (page) => {
        const {action} = this.props;
        await action(page);
    }
    onClickPrevPage =  (e) => {
        const {action} = this.props;
        let min = this.state.min_page - 10;
        let max = this.state.min_page - 1;
        this.setState({
            min_page: min,
            max_page: max,
        }, async () => {
            await action(this.state.max_page);
        });
    }
    onClickNextPage = (e) => {
        const {action} = this.props;
        this.setState({
            min_page: this.state.max_page + 1,
            max_page: (this.state.max_page + 10 <= this.state.meta.last_page)?this.state.max_page + 10:this.state.meta.last_page
        }, async () => {
            await action(this.state.min_page);
        })

    }
    onSort = async (column, dir) => {
        const {action} = this.props;
        let sortKey = column.key;
        if(column.hasOwnProperty('sortKey'))
            sortKey = column.sortKey;
        await action(1, sortKey, dir);
    }
    isActiveSort = (column, sort, dir) => {
        if(column.hasOwnProperty('sortKey') && column.sortKey === sort.key && sort.dir === dir)
            return true;
        if(column.key === sort.key && sort.dir === dir)
            return true;
        return false;
    }
    render() {
        const {applicationContext, columns, search, sort, loading, sum, footer, disableNotFound} = this.props;
        let sumData = [];
        return (
            <React.Fragment>
                {search && search()}
                {loading && <Loading transparent={true} animation={true} /> }
                {!this.state.loading && !loading && this.state.data.length > 0?(
                    <React.Fragment>
                        <Table dir={applicationContext.translator("direction")}>
                            <HeaderRow className={"theader"}>
                                {columns.map((column, index) => {
                                    return (
                                        <HeaderCol className={"table_header"} dir={applicationContext.translator("direction")}>
                                            <HeaderWrapper>
                                                {column.hasOwnProperty('sortable') && column.sortable && <Sort>
                                                    <SortIcon active={this.isActiveSort(column, sort, "desc")} onClick={(e) => this.onSort(column, "desc")}>
                                                        <FontAwesomeIcon icon={"chevron-up"}/>
                                                    </SortIcon>
                                                    <SortIcon active={this.isActiveSort(column, sort, "asc")} onClick={(e) => this.onSort(column, "asc")}>
                                                        <FontAwesomeIcon icon={"chevron-down"}/>
                                                    </SortIcon>
                                                </Sort>}
                                                {applicationContext.translator(column.label)}
                                            </HeaderWrapper>
                                        </HeaderCol>
                                    )
                                })}
                            </HeaderRow>
                            {this.state.data.map((row, rowIndex) => {
                                return (
                                    <Row className={"table_row"} dir={applicationContext.translator("direction")}>
                                        {columns.map((column, colIndex) => {
                                            if(sum && sum.includes(column.key)){
                                                let v = parseInt(this.renderValue(row, column, rowIndex, colIndex, true));
                                                if(sumData.hasOwnProperty(column.key)){
                                                    sumData[column.key] += v;
                                                }else{
                                                    sumData[column.key] = v;
                                                }
                                            }
                                            return (
                                                <TableSmall className={"table_small"}>
                                                    <Col className={"table_cell"}>{applicationContext.translator(column.label)}</Col>
                                                    <Col className={"table_cell"}>{this.renderValue(row, column, rowIndex, colIndex)}</Col>
                                                </TableSmall>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                            {sum && (
                                <Row className={"table_row"} dir={applicationContext.translator("direction")}>
                                    {columns.map((column, colIndex) => {
                                        return (
                                            <TableSmall className={"table_small"}>
                                                <Col className={"table_cell"}>{applicationContext.translator(column.label)}</Col>
                                                <Col className={"table_cell"}>
                                                    {sum.includes(column.key) && sumData.hasOwnProperty(column.key)?this.renderValue(sumData, column, 0, 0):"-"}
                                                </Col>
                                            </TableSmall>
                                        )
                                    })}
                                </Row>
                            )}
                        </Table>
                        {!footer && <FooterRow dir={applicationContext.translator("direction")}>
                            <FooterCol className={"footer-col"}>
                                <b>{applicationContext.translator("Total records")}:</b>
                                <div>{numberWithCommas(this.state.meta.total, false, ",")}</div>
                            </FooterCol>
                            <FooterCol className={"footer-col"}>
                                <b>{applicationContext.translator("From")}:</b>
                                <div>{numberWithCommas(this.state.meta.from, false, ",")}</div>
                            </FooterCol>
                            <FooterCol className={"footer-col"}>
                                <b>{applicationContext.translator("To")}:</b>
                                <div>{numberWithCommas(this.state.meta.to, false, ",")}</div>
                            </FooterCol>
                        </FooterRow>}
                    </React.Fragment>
                ):(
                    !this.state.loading && !loading && !disableNotFound && <NotFound>
                        <NotFoundWrapper>
                            <img src={"/assets/images/norecordfound.png"} />
                            <p>{applicationContext.translator("No records found.")}</p>
                        </NotFoundWrapper>
                    </NotFound>
                )}
                {!this.state.loading && !loading && this.state.meta.last_page > 1 &&
                <div>
                    <Pagination>
                        <Page className={"btn-navigate"} button={true} disabled={this.state.meta.current_page <= 1} onClick={this.onFirst}>{applicationContext.translator("First")}</Page>
                        <Page className={"btn-navigate"} button={true} disabled={this.state.meta.current_page <= 1} onClick={this.onPrev}>{applicationContext.translator("Prev")}</Page>
                        {this.renderPagination()}
                        <Page className={"btn-navigate"} button={true} disabled={this.state.meta.last_page <= this.state.meta.current_page} onClick={this.onNext}>{applicationContext.translator("Next")}</Page>
                        <Page className={"btn-navigate"} button={true} disabled={this.state.meta.last_page <= this.state.meta.current_page} onClick={this.onLast}>{applicationContext.translator("Last")}</Page>
                    </Pagination>
                </div>}
            </React.Fragment>
        );
    }
}
DataList.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    action: PropTypes.func.isRequired,
    search: PropTypes.any,
    sum: PropTypes.array,
    sort: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    footer: PropTypes.bool,
    disableNotFound: PropTypes.bool
}
export default withApplicationContext(DataList);