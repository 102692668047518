import React, {Component} from 'react';
import {SupportFooterContainer} from "./style";
import {Row,Col} from "react-bootstrap";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import HaveQuestion from "./HaveQuestion";
import PaymentOptions from "./PaymentOptions";
import ContactPhone from "./ContactPhone";
import Social from "./Social";
import WhatsappContact from "./WhatsappContact";
import ContactEmail from "./ContactEmail";
import WorkDays from "./WorkDays";
class SupportFooter extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <SupportFooterContainer>
                <Row>
                    <Col md={6}>
                        <ContactPhone />
                        <ContactEmail />
                        <WorkDays />
                    </Col>
                    <Col md={6} style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div>
                            <HaveQuestion />
                            <WhatsappContact />
                        </div>

                    </Col>
                    <Col md={6} style={{ marginTop: "1.5em"}}>
                        <Social />
                    </Col>
                    <Col md={6} style={{ marginTop: "1.5em"}}>
                        <PaymentOptions />
                    </Col>
                </Row>
            </SupportFooterContainer>
        );
    }
}

export default withApplicationContext(SupportFooter);