import React, {Component} from 'react';
import {Card, Icon, Message, OrderID, Title} from "./style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import Button from "../../components/ui/Button";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {ROUTE_HOME} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";

class PaymentError extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            message: urlSearchParams.get('message'),
            refId: urlSearchParams.get('ref_id'),
        }
    }

    onClickToHome = (e) => {
        const {navigate} = this.props;

        navigate(ROUTE_HOME);
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <Card>
                <Icon>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </Icon>
                <Title>{applicationContext.translator("Payment Error")}</Title>
                <Message>{this.state.message}</Message>
                <OrderID>{applicationContext.translator("Reference id")}: {this.state.refId}</OrderID>
                <Button color="primary" size="md" margin={"1em"} onClick={this.onClickToHome}>
                    {applicationContext.translator("Go to homepage")}
                </Button>
            </Card>
        );
    }
}

export default withRouter(withApplicationContext(PaymentError));