import React from "react";
const CheckoutContext = React.createContext({});
const CheckoutProvider = CheckoutContext.Provider;
const CheckoutConsumer = CheckoutContext.Consumer;
const withCheckoutContext = Component => React.forwardRef((props, ref) => (
    <CheckoutConsumer>
        {(response) => {
            return <Component {...props} checkoutContext={response} ref={ref}/>;
        }}
    </CheckoutConsumer>
));
export { CheckoutProvider, CheckoutContext, withCheckoutContext };
