import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Text} from "./style";
import WhatsappContact from "./WhatsappContact";
import Contact from "./ContactPhone";

class HaveQuestion extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Text>{applicationContext.translator("Do you have any question?")}</Text>
                <Text>{applicationContext.translator("Please get in touch with us")}</Text>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(HaveQuestion);