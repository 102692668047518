import React, {Component} from 'react';
import {NormalTimerContainer, NormalTimerNumber} from "./style";

class NormalCountDown extends Component {
    
    render() {
        const {timer} = this.props;
        return (
            (timer)?(<NormalTimerContainer>
                {timer.days > 0 &&
                    <React.Fragment>
                        <NormalTimerNumber>
                            {timer.days.toString().padStart(2, "0")}
                        </NormalTimerNumber>
                        <NormalTimerNumber>-</NormalTimerNumber>
                    </React.Fragment>
                }
                {((timer.hours > 0) || (timer.hours <= 0 && timer.days > 0)) && <React.Fragment>
                    <NormalTimerNumber>
                        {timer.hours.toString().padStart(2, "0")}
                    </NormalTimerNumber>
                    <NormalTimerNumber>:</NormalTimerNumber>
                </React.Fragment>}
                <React.Fragment>
                    <NormalTimerNumber>
                        {timer.minutes.toString().padStart(2, "0")}
                    </NormalTimerNumber>
                    <NormalTimerNumber>:</NormalTimerNumber>
                </React.Fragment>
                <NormalTimerNumber>
                    {timer.seconds.toString().padStart(2, "0")}
                </NormalTimerNumber>
            </NormalTimerContainer>):(null)
        );
    }
}

export default NormalCountDown;