import React, {Component} from 'react';
import {PopupButton, PopupText} from "./style";
import Button from "../../../components/ui/Button";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
class MaximumPopup extends Component {
    render() {
        const {applicationContext, handleClose} = this.props;
        return (
            <PopupText>
                <FontAwesomeIcon icon={faTimesCircle} /><br/>
                {applicationContext.translator("We apologise, but you can only book up to 8 tickets at a time in any of the reserved categories. If you wish to purchase more than eight tickets at once, please make your booking by telephone.")}<br/>
                <PopupButton>
                    <Button color={"primary"} fullWidth={true} onClick={handleClose} margin={"0 .2em"}>
                        {applicationContext.translator("OK")}
                    </Button>
                </PopupButton>
            </PopupText>
        );
    }
}

export default withApplicationContext((MaximumPopup));