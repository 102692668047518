import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Main, Title} from "./style";
import {withAlert} from "react-alert";
import PageNotFound from "../../../components/ui/PageNotFound";
import CommonService from "../../../services/CommonService";
import Search from "./Search";
import DataList from "../../../components/ui/DataList/DataList";
import Link from "../../../components/ui/Link";
import {generateUrl, slugify} from "../../../helpers";
import {ROUTE_EVENT_TICKETS} from "../../../routes/routes";
import ContentLoading from "../../../components/ui/ContentLoading";
const FileDownload = require('js-file-download');
class Tickets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            notFound: false,
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }


    }
    componentDidMount = async () => {
        await this.fetchData(1);
    }
    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        const {applicationContext} = this.props;
        this.setState({ loading: true })

        let params = {
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search && search)
        };
        let data = await CommonService.doGet("/profile/tickets", params).catch((e) => {
            console.log(e.response.data.message);
            this.setState({ notFound: true });
        });
        if(data){
            setTimeout(() => {
                this.setState({
                    loading: false,
                    data: data,
                    sort:{
                        key: sort_field,
                        dir: sort_dir
                    },
                    search
                }, callback)
            }, 1000)
        }

    }
    renderDataLoading = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <ContentLoading height={"100vh"}>
                <rect x="0" y="0" rx="5" ry="5" width="20%" height="40" />
                <rect x="21%" y="0" rx="5" ry="5" width="20%" height="40" />
                <rect x="42%" y="0" rx="5" ry="5" width="20%" height="40" />
                <rect x="63%" y="0" rx="5" ry="5" width="40%" height="40" />
                <rect x="0" y="50" rx="5" ry="5" width="100%" height="80%" />
            </ContentLoading>

        )
    }
    onDownloadPdf = async (row, callback) => {
        let pdf = await CommonService.doGetBase(`/profile/ticket/download/${row.uid}`, {
            responseType: 'blob'
        }).then((blob) => {
            FileDownload(blob, row.ticket_name+".pdf");
            callback(row, "downloaded");
        })
    }
    renderEventUrl = (value, rowIndex, row) => {
        return (
            <React.Fragment>
                <div><img height={80} src={row.event.default_image.url} style={{ marginBottom: ".5em" }} /></div>
                <Link to={generateUrl(ROUTE_EVENT_TICKETS, ["id", "slug"], [row.event.id, slugify(row.event.title)])}>{row.event.title}</Link>
            </React.Fragment>
        )
    }
    renderViewComponent = () => {
        const {loading, notFound} = this.state;
        const {applicationContext} = this.props;
        if (loading) return null;
        if (notFound) return (<PageNotFound/>);
        return (
            <DataList
                columns={[
                    {key: "id", label: "#", format: "number"},
                    {key: "uid", label: "Ticket id", format: "uniqueId"},
                    {key: "event", label: "Event", render: this.renderEventUrl},
                    {key: "person.full_name", label: "Booked by"},
                    {key: "total_booked_seats", label: "Total booked seats"},
                    {key: "booking_date", label: "Booked date"},
                    {key: "payment.order.basket_details.summary.checkout.total", label: "Total price"},
                    {key: "payment.order.basket_details.summary.checkout.vat", label: "VAT"},
                    {key: "", label: "", actions: [
                        {label: "Download PDF", route: "#", onClick: this.onDownloadPdf, color: "#4caf50"},
                    ]}
                ]}
                data={this.state.data}
                action={this.fetchData}
                search={this.renderSearch}
                sort={this.state.sort}
                loading={this.state.loading}
            />
        )
    }
    renderSearch = () => {
        return <Search data={this.state.search} status={this.state.dataStatus} doFetchData={this.fetchData}/>;
    }
    render() {
        const {applicationContext, accountContext} = this.props;
        return (
            <Container>
                <Title>Your tickets</Title>
                <Main>
                    {this.renderDataLoading()}
                    {this.renderViewComponent()}
                </Main>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(Tickets))));