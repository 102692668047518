import React, {Component} from 'react';
import {
    CardContainer, RadioButton,
    RadioOption, RadioOptionContainer, RadioOptionLabel, RadioOptionWrapper, Title
} from "../style";
import {withCheckoutContext} from "./CheckoutContext"
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import CustomerForm from "./CustomerForm";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withFormContext} from "../../../contexts/FormContext";
import {Spinner} from "react-bootstrap";
import Button from "../../../components/ui/Button";
class CustomerInformation extends Component {
    componentDidMount() {
        this.putUserInfoToForm();
    }
    putUserInfoToForm = () => {
        const {formContext, accountContext} = this.props;
        const {setFieldValue} = formContext;
        let mobile = accountContext.state.userInfo['country']['flag'] + " (+"+accountContext.state.userInfo['country']['mobile_prefix_clear']+") "+accountContext.state.userInfo['mobile_trim'];
        setFieldValue('first_name', accountContext.state.userInfo['first_name']);
        setFieldValue('last_name', accountContext.state.userInfo['last_name']);
        setFieldValue('mobile_country', accountContext.state.userInfo['country']['mobile_prefix_clear']);
        setFieldValue('mobile', mobile);
        setFieldValue('email', accountContext.state.userInfo['email']);
        setFieldValue('national_id', accountContext.state.userInfo['national_id']);

    }
    onChangeType = (value) => {
        const {formContext} = this.props;
        const {handleChange, setFieldValue} = formContext;
        if(value === "1"){
            this.putUserInfoToForm();
        }else{
            setFieldValue('first_name', "");
            setFieldValue('last_name', "");
            setFieldValue('mobile_country', "");
            setFieldValue('mobile', "");
            setFieldValue('email', "");
            setFieldValue('national_id', "");
        }
        setFieldValue('type', value);
    }
    render() {
        const {applicationContext, formContext} = this.props;
        const {values} = formContext;
        return (
            <React.Fragment>
                <Title>{applicationContext.translator("Customer information")}</Title>
                <CardContainer>
                    <RadioOptionWrapper>
                        <RadioButton onClick={(e) => this.onChangeType("1")} active={values.type === "1"}>
                            {applicationContext.translator("Tickets are for me")}
                        </RadioButton>
                        <input type={"hidden"} name={"type"} value={values.type}/>
                        <RadioButton onClick={(e) => this.onChangeType("2")} active={values.type === "2"}>
                            {applicationContext.translator("Tickets are a present for a friend")}
                        </RadioButton>
                        {/*<Button color={values.type === "1"?"primary":"shadow"} size="md" fullWidth={true} margin={"1em .3em 1em 1em"} onClick={(e) => this.onChangeType("1")}>*/}
                        {/*    {applicationContext.translator("Tickets are for me")}*/}
                        {/*</Button>*/}
                        {/*<Button color={values.type === "2"?"primary":"shadow"} size="md" fullWidth={true} margin={"1em 1em 1em .3em"} onClick={(e) => this.onChangeType("2")}>*/}
                        {/*    {applicationContext.translator("Tickets are a present for a friend")}*/}
                        {/*</Button>*/}


                        {/*<RadioOptionContainer>*/}
                        {/*    <RadioOption type="radio" name="type" value="1" onChange={this.onChangeType}/>*/}
                        {/*    <RadioOptionLabel>{applicationContext.translator("Tickets are for me")}</RadioOptionLabel>*/}
                        {/*</RadioOptionContainer>*/}
                        {/*<RadioOptionContainer>*/}
                        {/*    <RadioOption type="radio" name="type" value="2"  onChange={this.onChangeType}/>*/}
                        {/*    <RadioOptionLabel>{applicationContext.translator("Tickets are a present for a friend")}</RadioOptionLabel>*/}
                        {/*</RadioOptionContainer>*/}
                    </RadioOptionWrapper>

                    {values.type === "1" && (
                        <CustomerForm />
                    )}

                    {values.type === "2" && (
                        <CustomerForm />
                    )}
                </CardContainer>
            </React.Fragment>
        );
    }
}
export default withApplicationContext((withCheckoutContext(withAccountContext(withFormContext(CustomerInformation)))));