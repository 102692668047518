import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import SuccessfullMessageBox from "../../components/ui/SuccessfullMessageBox";

class EmailVerification extends Component {
    render() {
        const {applicationContext, message} = this.props;
        return (
            <SuccessfullMessageBox
                messages={[
                    applicationContext.translator("Your Email has been subscribed successfully."),
                    applicationContext.translator("An email has been sent to your inbox")
                ]}
            />
        );
    }
}
export default (withApplicationContext(EmailVerification));