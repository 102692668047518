import api from "./api";
import TokenService from "./TokenService";
import StorageService from "./StorageService";
const getUserBoard = () => {
    return api.get("/profile/me").then((response) => {
        TokenService.setUser(response.data.user);
        return TokenService.getUser();
    });
};
const doChangePassword = (data) => {
    return api
        .post("/profile/password/change", data)
        .then((response) => {
            return response.data;
        });
};
const register = (data) => {
    return api.post("/auth/register", data).then((response) => {
        return response.data;
    });
};

const login = (username, password, version = '') => {
    return api
        .post("/auth/login", {
            username,
            password,
            version
        })
        .then((response) => {
            return response.data;
        });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return StorageService.get('user', true);
};

const getCurrentAdmin = () => {
    return StorageService.get('admin', true);
};


const doResetPasswordByEmail = (data) => {
    return api
        .post("/reset-password/reset/email", data)
        .then((response) => {
            return response.data;
        });
};
const doResetPasswordByMobile = (data) => {
    return api
        .post("/reset-password/reset/mobile", data)
        .then((response) => {
            return response.data;
        });
};
const Service = {
    register,
    login,
    logout,
    getCurrentUser,
    getCurrentAdmin,
    doResetPasswordByEmail,
    doResetPasswordByMobile,
    getUserBoard,
    doChangePassword
};

export default Service;