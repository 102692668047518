import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Title} from "./style";
import SimpleCard from "../../../components/ui/SimpleCard";
import CommonService from "../../../services/CommonService";
import {withAlert} from "react-alert";
import PageNotFound from "../../../components/ui/PageNotFound";
import {
    ROUTE_ACCOUNT,
    ROUTE_ACCOUNT_ENTER_NEW_MOBILE,
    ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL
} from "../../../routes/routes";
import EnterMobileCodeForm from "../../Register/Forms/EnterMobileCodeForm";
class MobileVerificationForUpdate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notFound: false,
            submitting: false,
            error: null,
            endpoint: null,
            mobile: ""
        }


    }
    componentDidMount() {
        const {applicationContext, accountContext, params} = this.props;
        if(!applicationContext.state.holderData) {
            this.setState({
                notFound: true
            });
        }else{
            this.setState({
                endpoint: applicationContext.state.holderData,
                mobile: accountContext.state.userInfo['mobile_no_nice']
            })
        }


    }
    onSubmit = async (values, actions) => {
        const {alert, navigate, applicationContext, accountContext} = this.props;
        this.setState({ submitting: true })
        let result = await CommonService.doPost(this.state.endpoint, values).catch((e) => {
            let error = e.response.data;
            if(error.hasOwnProperty('input_errors')){
                Object.keys(error['input_errors']).map((name) => {
                    actions.setFieldError(name, error['input_errors'][name][0]);
                })
            }else{
                alert.error(error.message);
            }
        })
        if(result){
            if(result.action === "COMPLETED"){
                accountContext.updateUserInfo(result.user, () => {
                    alert.success("The profile information has been updated successfully");
                    setTimeout(() => {
                        navigate(ROUTE_ACCOUNT);
                    }, 1000)
                })
            }else{
                applicationContext.doUpdateState({
                    holderData: result.endpoint
                });
                navigate(ROUTE_ACCOUNT_ENTER_NEW_MOBILE);
            }

        }
        this.setState({ submitting: false })
    }

    render() {
        const {applicationContext, accountContext} = this.props;
        if(this.state.notFound)
            return <PageNotFound/>;
        return (
            <Container>
               <SimpleCard>
                   <EnterMobileCodeForm
                       onSubmit={this.onSubmit}
                       submitting={this.state.submitting}
                       error={this.state.error}
                       mobile={this.state.mobile}
                   />
               </SimpleCard>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(MobileVerificationForUpdate))));