import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Title} from "./style";
import SimpleCard from "../../../components/ui/SimpleCard";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Col, Row, Spinner} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import CommonService from "../../../services/CommonService";
import {withAlert} from "react-alert";
import PageNotFound from "../../../components/ui/PageNotFound";
import {ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL} from "../../../routes/routes";
class EnterNewEmailForUpdate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notFound: false,
            submitting: false,
            endpoint: null,
            inputs: {
                current_email: "",
                email: ""
            }
        }


    }
    componentDidMount() {
        const {accountContext, params} = this.props;
        try{
            let hash = JSON.parse(decodeURIComponent(atob(params.hash)));
            this.setState({
                email: hash.e,
                endpoint: hash.u,
                inputs:{
                    current_email: hash.e
                }
            })
        }catch (e){
            this.setState({
                notFound: true,
            })
        }


    }
    onSubmit = async (values, actions) => {
        const {alert, navigate} = this.props;
        this.setState({ submitting: true })
        let result = await CommonService.doPost(this.state.endpoint, values).catch((e) => {
            let error = e.response.data;
            if(error.hasOwnProperty('input_errors')){
                Object.keys(error['input_errors']).map((name) => {
                    actions.setFieldError(name, error['input_errors'][name][0]);
                })
            }else{
                alert.error(error.message);
            }
        })
        if(result){
            navigate(ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL);
        }
        this.setState({ submitting: false })
    }

    render() {
        const {applicationContext, accountContext} = this.props;
        if(this.state.notFound)
            return <PageNotFound/>;
        return (
            <Container>
               <SimpleCard>
                   <Formik
                       enableReinitialize={true}
                       innerRef={(ref) => this.refForm = ref}
                       initialValues={this.state.inputs}
                       validationSchema={Yup.object().shape({
                           email: Yup.string().required("You must enter your ${path}").email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                       })}
                       onSubmit={this.onSubmit}
                   >
                       {({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleSubmit,
                             setFieldValue
                         }) =>
                           (
                               <Form onSubmit={handleSubmit} style={{direction: applicationContext.translator("direction")}}>
                                   <Title>Update your e-mail address</Title>
                                   <Row>
                                       <Col md={12}>
                                           <InputBox type="text"
                                                     name={"current_email"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("Current E-Mail Address")}
                                                     value={values.current_email}
                                                     disabled={true}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                       <Col md={12}>
                                           <InputBox type="text"
                                                     name={"email"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("New E-Mail Address")}
                                                     value={values.email}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>

                                        <Col md={12}>
                                            <Button disabled={this.state.submitting} color="primary" size="lg" margin={"1em 0"} type={"submit"} fullWidth={true}>
                                                {this.state.submitting?<Spinner animation="border" size={"sm"} />:applicationContext.translator("Update")}
                                            </Button>
                                        </Col>
                                   </Row>
                               </Form>
                           )
                       }
                   </Formik>
               </SimpleCard>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(EnterNewEmailForUpdate))));