import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Container} from "./style";
import Uploader from "../Uploader";
import PropTypes from "prop-types";
import {InputError} from "../InputBox/style";
import {acceptFormat, moveArrayIndex} from "../../../helpers";
import {UploaderProvider} from "./UploaderContext";
import {Label} from "../DateTimePicker/style";
class MultiUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            uploading: false,
            loading: true
        }
    }

    componentDidMount() {
        this.setState({
            data: [...this.props.uploaded],
            loading: false
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.uploaded.length !== this.props.uploaded.length && this.props.uploaded.length === 0) {
            this.setState({
                data: []
            })
        }
    }

    onMoveToUp = async (old_index) => {
        const {name, form, onUpdateRecord} = this.props;
        if(old_index === 0) return;
        let data = this.state.data;
        let new_index = old_index - 1;
        let updated = moveArrayIndex(data, old_index, new_index)

        this.setState({
            data: updated,
            loading: false
        }, async () => {
            await form.setFieldValue(name, updated);
            if(onUpdateRecord)
                await onUpdateRecord();
        })
    }
    onMoveToDown = async (old_index) => {
        const {name, form, onUpdateRecord} = this.props;
        let data = this.state.data;
        if(old_index === data.length - 1) return;
        let new_index = old_index + 1;
        let updated = moveArrayIndex(data, new_index, old_index)

        this.setState({
            data: updated,
            loading: false
        }, async () => {
            await form.setFieldValue(name, updated);
            if(onUpdateRecord)
                await onUpdateRecord();
        })
    }
    addNewUploader = async (index, file) => {
        const {name, form, onUpdateRecord} = this.props;
        let updated = this.state.data;
        updated.push(file);
        this.setState({
            data: updated,
            loading: false
        }, async () => {
            await form.setFieldValue(name, updated);
            if(onUpdateRecord)
                await onUpdateRecord();
        })
    }
    render() {
        const {name, touched, errors, label} = this.props;
        return (
            <Container>
                <UploaderProvider value={{
                    state: this.state,
                    name: this.props.name,
                    accept: acceptFormat(this.props.fileType),
                    formats: acceptFormat(this.props.fileType, false),
                    fileTypes: this.props.fileTypes,
                    maxSize: this.props.maxSize,
                    uploadToServer: this.props.uploadToServer,
                    uploader: this.props.uploader,
                    onDeleteServer: this.props.onDeleteServer,
                    onStartUpload: this.props.onStartUpload,
                    onCompleteUpload: this.props.onCompleteUpload,
                    addNewUploader: this.addNewUploader,
                    onDeleteUploader: this.onDeleteUploader,
                    onMoveToUp: this.onMoveToUp,
                    onMoveToDown: this.onMoveToDown,
                    error: (touched && touched[name] && errors[name])?errors[name]:null
                }}>
                    {label && <Label>{label}</Label>}
                    {this.renderElements()}
                </UploaderProvider>
            </Container>
        );
    }
    onDeleteUploader = async (index) => {
        const {form, name} = this.props;
        let updated = this.state.data;
        updated.splice(index, 1);
        this.setState({
            data: updated,
            loading: false
        }, () => {
            form.setFieldValue(name, updated)
        })
    }
    renderElements = () => {
        const {maxUploadCount} = this.props;
        let objects = [];
        if(this.state.loading) return (null);
        this.state.data.map((value, index) => {
            objects.push(this.renderUploader(index, value));
        })
        if(!maxUploadCount || this.state.data.length < maxUploadCount)
            objects.push(this.renderUploader(-1))
        return objects;
    }
    renderUploader = (index = -1, value = null) => {
        const {name, placeholder} = this.props;
        const {data} = this.state;
        if(index === -1)
            index = data.length;
        let key = name+"-"+index;
        if(value)
            key = name+"-uploaded-"+value.uploaded.id;

        return (
            <Uploader
                key={key}
                index={index}
                defaultValue={value}
                placeholder={placeholder}
            />
        );
    }
}
MultiUploader.propTypes = {
    fileType: PropTypes.array,
    name: PropTypes.string.isRequired,
    uploaded: PropTypes.array.isRequired,
    form: PropTypes.any.isRequired,
    maxSize: PropTypes.number.isRequired,
    uploadToServer: PropTypes.bool,
    onStartUpload: PropTypes.func,
    onCompleteUpload: PropTypes.func,
    onUpdateRecord: PropTypes.func,
    maxUploadCount: PropTypes.number,
    label: PropTypes.string,
    placeholder: PropTypes.string
}
export default withApplicationContext(MultiUploader);