import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAccountContext} from "../../contexts/AccountContext";
import withRouter from "../../contexts/withRouter";
import {Container} from "./style";
import {CartProvider} from "./CartContext";
import BackToEventTicket from "./BackToEventTicket";
import StageMap from "../../components/ui/StageMap";
import CartBar from "./CartBar";
import CommonService from "../../services/CommonService";
import {withAlert} from "react-alert";
import Cart from "./Cart";
import PageNotFound from "../../components/ui/PageNotFound";
import ContentLoading from "../../components/ui/ContentLoading/ContentLoading";
import LoginPopup from "../Login/Popup/LoginPopup";
import Button from "../../components/ui/Button";
import CloseReservationPopup from "./CloseReservationPopup";
import DesktopUsePopup from "./DesktopUsePopup";

class CartView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            eventItem: null,
            packages: {},
            closeReservation: false,
            cart: null ,
            cartExpireTime: null,
            selectedSeats: [],
            reservedSeats: [],
            soldSeats: [],
            cartBarOpen: false,
            notFound: false
        }

    }
    componentDidMount = async () => {
        await this.getEventById();
    }
    componentWillUnmount() {
        const {applicationContext} = this.props;
        applicationContext.doChangePageTitle();
    }
    removeCartItem = async (ticket) => {
        let {cart} = this.state;
        if(!cart) return;
        await cart.delete(ticket.row, ticket.place);
        this.setState({
            submitting: false,
            cart,
            selectedSeats: cart.getIds()
        });
        return cart;

    }
    removeAllCartItem = async () => {
        const {cart} = this.state;
        await cart.clear();
        this.setState({
            cart,
            selectedSeats: cart.getIds()
        })
    }
    addTicketToCart = async (ticket, action = 'add') => {
        this.setState({submitting: ticket.id });
        let cart = this.state.cart;
        if(action === 'add'){
            await cart.add(ticket);
            this.setState({
                submitting: false,
                cart,
                selectedSeats: cart.getIds()
            });
        }else{
            return this.removeCartItem(ticket);
        }
    }
    routeToNotFound = () => {
        this.setState({
            notFound : true,
            loading: false
        })
    }
    onCloseDesktopUsePopup = (e) => {
        const {applicationContext} = this.props;
        applicationContext.onCloseConfirmPopup(e, () => {});
    }
    getEventById = async () => {
        const {params, applicationContext, accountContext, alert} = this.props;
        const {userInfo} = accountContext.state;
        let item = await CommonService.doGet(`/public/get_event_place_in_cart/${params['id']}`).catch((e) => {
            this.routeToNotFound();
        });
        if(!item || !item['place']['seats'] || new Date(item['close_reservation']) <= new Date()) {
            this.routeToNotFound();
            return;
        }
        applicationContext.doChangePageTitle(item.title);
        let packages = {};
        item.varieties.map((variety) => packages[variety.name] = variety);
        let cart = new Cart(item, userInfo, alert);
        await cart.fetchCartFromServer();
        if(window.screen.width <= 700){
            applicationContext.onOpenConfirmPopup(null,
                <DesktopUsePopup handleClose={this.onCloseDesktopUsePopup}/>,
                null,
                this.onCloseDesktopUsePopup,
                "sm",
                true,
                null
            );
        }
        this.setState({
            packages,
            eventItem: item,
            cart,
            selectedSeats: cart.getIds(),
            reservedSeats: item['reserved_seats'],
            soldSeats: item['sold_seats'],
            loading: false,
        })
    }
    doUpdateState = (state) => {
        this.setState(state);
    }
    renderDataLoading = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <ContentLoading height={"100vh"}>
                <rect x="0" y="0" rx="5" ry="5" width="70%" height="60" />
                <rect x="71%" y="0" rx="5" ry="5" width="30%" height="60" />
                <rect x="0" y="70" rx="5" ry="5" width="100%" height="80%" />
            </ContentLoading>

        )
    }
    renderViewComponent = () => {
        const {loading, notFound, eventItem, closeReservation, selectedSeats, reservedSeats, soldSeats, packages} = this.state;
        if(loading) return null;
        if(notFound) return (<PageNotFound />);
        return (
            <Container>
                <BackToEventTicket />
                <StageMap
                    eventItem={eventItem}
                    packages={packages}
                    selectedSeats={selectedSeats}
                    reservedSeats={reservedSeats}
                    soldSeats={soldSeats}
                    onSelectSeat={this.addTicketToCart}
                    closeReservation={closeReservation}
                    maxSeatsSelect={eventItem['max_seats_count']}
                />
                <CartBar />
            </Container>
        );
    }
    onCheckAuthenticated = async (callback) => {
        const {applicationContext, accountContext} = this.props;
        if(!accountContext.state.loggedIn)
            return applicationContext.onOpenPopup("Log In", <LoginPopup onCallback={callback} />);
        await callback();
    }
    render() {
        return (
            <CartProvider value={{
                state: this.state,
                removeCartItem: this.removeCartItem,
                removeAllCartItem: this.removeAllCartItem,
                doUpdateState: this.doUpdateState,
                onCheckAuthenticated: this.onCheckAuthenticated
            }}>
                {this.renderDataLoading()}
                {this.renderViewComponent()}
            </CartProvider>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(CartView))));