import styled from "styled-components";
export const Container = styled.div`
    width: 100%
    margin: 5em auto;
    
`
export const Title = styled.h1`
    font-size: 1em;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1em;
    text-transform: capitalize;
`
export const Main = styled.div`
  padding-bottom: 2em;
`
export const StatusLabel = styled.div`
    ${(props) => props.status === "PENDING" && `color: #dda600;`}
    ${(props) => props.status === "SUCCESSFULLY" && `color: #198754;`}
    ${(props) => props.status === "CANCEL" && `color: #dc3545;`}
    ${(props) => props.status === "ERROR" && `color: #dc3545;`}
`
