import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withCartContext} from "./CartContext";
import {CartDetails} from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Button from "../../components/ui/Button";
class CartBarDetails extends Component {
    onOpenClick = (e) => {
        const {cartContext} = this.props;
        cartContext.doUpdateState({
            cartBarOpen: !cartContext.state.cartBarOpen
        })
    }
    render() {
        const {cartContext, applicationContext} = this.props;
        const {cart, closeReservation} = cartContext.state;
        return (
            <CartDetails>
                <Button disabled={closeReservation} color={cartContext.state.cartBarOpen?"primary":"outline-primary"} size="lg" margin={"0 1em"} onClick={this.onOpenClick}>
                    {applicationContext.translator("Details")} (x{cart.count()}) {cartContext.state.cartBarOpen?
                    <FontAwesomeIcon icon={faChevronDown} />:
                    <FontAwesomeIcon icon={faChevronUp} />}
                </Button>

            </CartDetails>
        );
    }
}

export default withApplicationContext(withCartContext(CartBarDetails));