import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Title} from "./style";
import SimpleCard from "../../../components/ui/SimpleCard";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Col, Row, Spinner} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import CommonService from "../../../services/CommonService";
import {withAlert} from "react-alert";
import PageNotFound from "../../../components/ui/PageNotFound";
import {ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL, ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE} from "../../../routes/routes";
import CountryDropDown from "../../../components/ui/CountryDropDown/CountryDropDown";
class EnterNewMobileForUpdate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notFound: false,
            submitting: false,
            endpoint: null,
            inputs: {
                current_mobile: "",
                mobile_country: props.applicationContext.state.common['default_country']['mobile_prefix_clear'],
                mobile: ""
            }
        }


    }
    componentDidMount() {
        const {applicationContext, accountContext} = this.props;
        if(!applicationContext.state.holderData){
            this.setState({
                notFound: true
            });
        }else{
            this.setState({
                endpoint: applicationContext.state.holderData,
                inputs:{
                    current_mobile: accountContext.state.userInfo['mobile_no_nice']
                }
            })
        }
    }
    onSubmit = async (values, actions) => {
        const {alert, navigate, applicationContext} = this.props;
        this.setState({ submitting: true })
        let result = await CommonService.doPost(this.state.endpoint, values).catch((e) => {
            let error = e.response.data;
            if(error.hasOwnProperty('input_errors')){
                Object.keys(error['input_errors']).map((name) => {
                    actions.setFieldError(name, error['input_errors'][name][0]);
                })
            }else{
                alert.error(error.message);
            }
        })
        if(result){
            applicationContext.doUpdateState({
                holderData: result.endpoint
            });
            navigate(ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE);
        }
        this.setState({ submitting: false })
    }

    render() {
        const {applicationContext, accountContext} = this.props;
        if(this.state.notFound)
            return <PageNotFound/>;
        return (
            <Container>
               <SimpleCard>
                   <Formik
                       enableReinitialize={true}
                       innerRef={(ref) => this.refForm = ref}
                       initialValues={this.state.inputs}
                       validationSchema={Yup.object().shape({
                           mobile: Yup.string().required("You must enter your ${path}").min(9, applicationContext.translator("You must enter minimum 9 digits")).label(applicationContext.translator("Mobile number")),
                       })}
                       onSubmit={this.onSubmit}
                   >
                       {({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleSubmit,
                             setFieldValue
                         }) =>
                           (
                               <Form onSubmit={handleSubmit} style={{direction: applicationContext.translator("direction")}}>
                                   <Title>Update your e-mail address</Title>
                                   <Row>
                                       <Col md={12}>
                                           <InputBox type="text"
                                                     name={"current_mobile"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("Current Mobile Number")}
                                                     value={values.current_mobile}
                                                     disabled={true}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                       <Col md={6}>
                                           <CountryDropDown
                                               name={"mobile_country"}
                                               onChange={setFieldValue}
                                               value={values.mobile_country}
                                               errors={errors}
                                               touched={touched}
                                           />
                                       </Col>
                                       <Col md={6}>
                                           <InputBox type="text"
                                                     onlyNumber={true}
                                                     name={"mobile"}
                                                     onChange={handleChange}
                                                     placeholder={applicationContext.translator("Mobile number")}
                                                     value={values.mobile}
                                                     maxLength={12}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>

                                        <Col md={12}>
                                            <Button disabled={this.state.submitting} color="primary" size="lg" margin={"1em 0"} type={"submit"} fullWidth={true}>
                                                {this.state.submitting?<Spinner animation="border" size={"sm"} />:applicationContext.translator("Update")}
                                            </Button>
                                        </Col>
                                   </Row>
                               </Form>
                           )
                       }
                   </Formik>
               </SimpleCard>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(EnterNewMobileForUpdate))));