import styled from "styled-components";

export const Container = styled.div`
  
`
export const BaseContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`
export const Row = styled.div`
  width: 100%;
`
export const SideBar = styled.div`
  min-height: 100vh;
  width: 350px;
  background: var(--color-sidebar-background);
  color: var(--color-sidebar-text);
  @media screen and (min-width: 701px) {
    transition: all linear .3s;
    &.opened{
      margin-left: 0;
    }
    &.closed{
      & *{
        visibility: hidden;
      }
      margin-left: -350px;
    }  
  }
  
  @media screen and (max-width: 700px) {
    position: fixed;
    z-index: 999999;
    bottom: 0;
    top: 0;
    transition: all linear .3s;
    &.opened{
      left: 0;
    }
    &.closed{
      & *{
        visibility: hidden;
      }
      left: -350px;
    }
  }
`

export const Main = styled.div`
  width: 100%;
  @media screen and (min-width: 701px) {
    transition: all linear .3s;
    ${(props) => props.sidebar === "opened"?`
        width: calc(100% - 350px);  
      `:`
        width: 100%;
      `}
  }
  
`
export const Header = styled.div`
  width: 100%;
  height: 70px;
  padding-left: 15px;
  padding-right: 15px;
  background: var(--color-headerbar-background);
  color: var(--color-headerbar-text);
  border-bottom: 1px solid var(--color-headerbar-border);
  display: flex;
  align-items: center;
  text-transform: uppercase;
`
export const Body = styled.div`
  padding: 1em;
`
export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid var(--color-headerbar-border);
  background: var(--color-headerbar-background);
  padding: 0 15px;
  
  
`
export const HeaderLogoName = styled.div`
  display: flex;
  align-items: center;
  & img{
    height: 50px;
  }
  & div{
    font-weight: 600;
    margin-left: 1em;
  }
`
export const HeaderMenuIcon = styled.div`
  font-size: 1.5em;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  ${(props) => props.center?`
    justify-content: center;
  `:`
    justify-content: flex-end;
  `}
  
  align-items: center;
  &:hover{
    color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
`
export const MenuItems = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  
`
export const MenuItemLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const MenuItemCounter = styled.div`
  min-width: 32px;
  min-height: 32px;
  background: #d50f0a;
  color: #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const MenuItem = styled.li`
  padding-right: 20px;
  margin: .3em 0;
  & a{
    padding: .8em 1em;
    display: flex;
    color: #878787;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: all ease-in-out .3s;
    text-transform: uppercase;
    ${(props) => props.active && `
        background: #0f1015;
        color: #fff;
        border-left: 3px solid var(--color-primary);
   `}
    &:hover{
        background: #0f1015;
        color: #fff;
    }
  }
  
`
export const MenuItemProfile = styled.li`
  display: flex;
  align-items: center;
  padding: 2em 1em 1em 1em;
`
export const Profile = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
  min-width: 230px;
`
export const ProfileAvatar = styled.div`
  width: 48px;
  height: 48px;
  & img{
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }
`
export const ProfileInfo = styled.div`
  margin-left: .7em;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  & span{
    font-size: .85em;
    color: #7a7a7a;
  }  
`
export const HeaderWrapper = styled.div`
  width: 100%;
`
export const LogoutButton = styled.div`
  width: 40px;
  color: #d50f0a;
  font-size: 1.5em;
  cursor: pointer;
  &:hover{
    color: #fff;
    transition: all ease-in-out .3s;
  }
`
export const MenuItemCategory = styled.li`
  color: #6c7293;
  font-weight: 600;
  padding: 1em;
`