import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import EnterMobileCodeForm from "./Forms/EnterMobileCodeForm";
import {LoginContainer} from "./style";
import {ROUTE_ACCOUNT, ROUTE_NOT_FOUND} from "../../routes/routes";
import { Navigate } from 'react-router-dom';
import Service from "../../services/Service";
import TokenService from "../../services/TokenService";
import {parseErrorMessage} from "../../helpers";
import CommonService from "../../services/CommonService";
import {withAccountContext} from "../../contexts/AccountContext";
import StorageService from "../../services/StorageService";
class MobileEnterCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: "",
            endpoint: null,
            mobile: null,
            submitting: false,
            successfully: false,
            error :null,
            redirect: false
        }

    }

    componentDidMount = async () => {
        const {params} = this.props;
        try{
            let hash = JSON.parse(decodeURIComponent(atob(params.hash)));
            this.setState({
                mobile: hash.e,
                endpoint: hash.u,
            })
        }catch (e){
            this.setState({
                redirect: true,
            })
        }

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, accountContext, navigate} = this.props;
        this.setState({ submitting: true });

        try {
            const token  = await CommonService.doGet(this.state.endpoint, {
                otp: values.code
            });
            TokenService.updateLocalAccessToken(token.auth);
            await Service.getUserBoard().then((data) => {
                accountContext.updateUserInfo(data, () => {
                    let redirect = (StorageService.get('redirect'))??ROUTE_ACCOUNT;
                    StorageService.remove('redirect');
                    navigate(redirect)
                });
            });

        } catch (err) {
            this.setState({
                submitting: false,
                error: applicationContext.translator(parseErrorMessage(err))
            });
        }
    }
    render() {
        if(this.state.redirect)
            return <Navigate to={ROUTE_NOT_FOUND} />;

        return (
            <React.Fragment>
                <LoginContainer>
                    <EnterMobileCodeForm
                        onSubmit={this.handleSubmit}
                        submitting={this.state.submitting}
                        error={this.state.error}
                        mobile={this.state.mobile}
                    />
                </LoginContainer>
            </React.Fragment>

        );
    }
}

export default withAlert()(withRouter(withApplicationContext(withAccountContext(MobileEnterCode))));