import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withCartContext} from "./CartContext";
import {
    CartBarDetailsListContainer,
    CardBarDetailsTitle,
    CardBarDetailsContent,
    ClearAllButton,
    CardBarDetailsTicketItems, CardBarDetailsTicketItem, ListLoading
} from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import {numberWithCommas} from "../../helpers";
import {Spinner} from "react-bootstrap";
class CartBarDetailsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleting: false,
            removeAllLoading: false
        }
    }

    onClickRemoveAllTickets = (e) => {
        const {cartContext} = this.props;
        this.setState({
            removeAllLoading: true
        }, () => {
            cartContext.removeAllCartItem();
        })

    }
    onClickRemove = (ticket) => {
        const {cartContext} = this.props;
        this.setState({
            deleting: ticket.id
        }, () => {
            cartContext.removeCartItem(ticket)
        })
    }
    render() {
        const {cartContext, applicationContext, open} = this.props;
        const {cart, closeReservation} = cartContext.state;
        return (
            (!closeReservation && open)?(<CartBarDetailsListContainer>
                <CardBarDetailsTitle>
                    <div>{applicationContext.translator("Ticket details")}</div>
                    <ClearAllButton onClick={this.onClickRemoveAllTickets}>{applicationContext.translator("Clear all")}</ClearAllButton>
                </CardBarDetailsTitle>
                <CardBarDetailsContent>
                    <CardBarDetailsTicketItems>
                        {this.state.removeAllLoading &&
                            <ListLoading>
                                <Spinner animation="border" variant={"primary"} inline={true} size={"sm"} />
                            </ListLoading>
                        }
                        {!this.state.removeAllLoading && cart.items().map((ticket, index) => {
                            return (
                                <CardBarDetailsTicketItem key={index}>
                                    <div className={"package-info"}>
                                        <div className={"package-name"}>{ticket.variety.label}, <span>{ticket.seat}</span></div>
                                        <div>
                                            <span className={"package-price"}>{ticket.price}</span>
                                            <span className={"ticket-row"}>
                                                {applicationContext.translator("Row")}:
                                                <b>{ticket.row}</b>
                                            </span>
                                            <span className={"ticket-seat"}>
                                                {applicationContext.translator("Seat")}:
                                                <b>{ticket.seat}</b>
                                            </span>
                                        </div>

                                    </div>
                                    <div className={"delete"} onClick={(e) => this.onClickRemove(ticket)}>
                                        {this.state.deleting === ticket.id?<Spinner animation="border" size={"sm"} />:<FontAwesomeIcon icon={faTrashAlt} />}
                                    </div>
                                </CardBarDetailsTicketItem>
                            );
                        })}
                    </CardBarDetailsTicketItems>
                </CardBarDetailsContent>
            </CartBarDetailsListContainer>):(null)
        );
    }
}

export default withApplicationContext(withCartContext(CartBarDetailsList));