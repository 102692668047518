import React from "react";
const CartContext = React.createContext({});
const CartProvider = CartContext.Provider;
const CartConsumer = CartContext.Consumer;
const withCartContext = Component => React.forwardRef((props, ref) => (
    <CartConsumer>
        {(response) => {
            return <Component {...props} cartContext={response} ref={ref}/>;
        }}
    </CartConsumer>
));
export { CartProvider, CartContext, withCartContext };
