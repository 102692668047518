import styled from "styled-components";

export const Container = styled.div`
  ${(props) => !props.native && `
        display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  `};
  width: 100%;
  padding-bottom: ${(props => props.marginBottom)};
  
`;
export const LogoImage = styled.img`
  width: ${(props => props.width??"120px")};

`;
