import React, {Component} from 'react';
import {SupportRowFooterContainer, SupportRowWrapper} from "./style";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import HaveQuestion from "./HaveQuestion";
import PaymentOptions from "./PaymentOptions";
import ContactPhone from "./ContactPhone";
import WhatsappContact from "./WhatsappContact";
import ContactEmail from "./ContactEmail";
import WorkDays from "./WorkDays";
class RowSupportFooter extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <SupportRowFooterContainer>
                <SupportRowWrapper>
                    <HaveQuestion />
                </SupportRowWrapper>
                <SupportRowWrapper>
                    <ContactEmail />
                </SupportRowWrapper>
                <SupportRowWrapper>
                    <ContactPhone />
                </SupportRowWrapper>
                <SupportRowWrapper>
                    <WorkDays />
                    <WhatsappContact row={true}/>
                </SupportRowWrapper>
                <SupportRowWrapper>
                    <PaymentOptions line={true}/>
                </SupportRowWrapper>
            </SupportRowFooterContainer>
        );
    }
}

export default withApplicationContext(RowSupportFooter);