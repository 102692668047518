import StorageService from "./StorageService";

const getLocalAccessToken = () => {
    let token = StorageService.get('token', true);
    return token?.token
};


const updateLocalAccessToken = (token) => {
    StorageService.set('token', token, true)
};

const getToken = () => {
    return StorageService.get('token', true);
};

const getUser = () => {
    return StorageService.get('user', true);
};

const setUser = (user) => {
    StorageService.set("user", user, true);
};

const removeUser = () => {
    StorageService.remove(["user", "token"]);
};

const TokenService = {
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
    getToken
};

export default TokenService;