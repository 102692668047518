import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
`
export const Card = styled.div`
  background: var(--color-card-background);
  color: var(--color-card-text);
  border-radius: var(--border-radius-2);
  padding: 3em;
`
