import React, {Component} from 'react';
import {
    Wrapper
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Row, Spinner as ReactSpinner} from "react-bootstrap";
import PropTypes from "prop-types";
class Spinner extends Component {

    render() {
        const {size, show, inline} = this.props;

        return (
            show?(
                <Row>
                    <Wrapper md={12} inline={inline}>
                        <ReactSpinner animation="border" variant="primary"  size={size??"sm"} />
                    </Wrapper>
                </Row>
            ):(null)
        );
    }
}
Spinner.propTypes = {
    size: PropTypes.string,
    show: PropTypes.bool,
    inline: PropTypes.bool,
}
export default withApplicationContext(Spinner);