
const set = (key, value, json = false) => {
    if(json)
        value = JSON.stringify(value);
    localStorage.setItem(process.env.REACT_APP_STORAGE_PREFIX+"-"+key, value)
};
const get = (key, parse = false) => {
    let data = localStorage.getItem(process.env.REACT_APP_STORAGE_PREFIX+"-"+key)
    if(parse)
        return JSON.parse(data);
    return data;
};
const remove = (key) => {
    if(typeof key === "object")
        key.forEach((o) => {
            localStorage.removeItem(process.env.REACT_APP_STORAGE_PREFIX+"-"+o);
        })
    else
        localStorage.removeItem(process.env.REACT_APP_STORAGE_PREFIX+"-"+key);
};

const StorageService = {
    set,
    get,
    remove
};

export default StorageService;