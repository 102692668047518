import React, {Component} from 'react';
import {withCheckoutContext} from "./CheckoutContext";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {CartBarContainer, CartBarWrapper, CartDetails} from "../style";
import CheckoutBarPay from "./CheckoutBarPay";

class CheckoutBar extends Component {
    render() {
        const {checkoutContext} = this.props;
        const {cart} = checkoutContext.state;
        return (
            (cart.items.length > 0)?(
            <CartBarContainer>
                <CartBarWrapper>
                    <CartDetails />
                    <CheckoutBarPay />
                </CartBarWrapper>
            </CartBarContainer>):(null)
        );
    }
}

export default withApplicationContext(withCheckoutContext(CheckoutBar));