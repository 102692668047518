import React, {Component} from 'react';
import {withStageMapContext} from "./StageMapContext";
import StageLocation from "./StageLocation";
import {SeatContainer} from "./style";
import SeatColumn from "./SeatColumn";
import StageHelp from "./StageHelp";

class Seats extends Component {
    render() {
        const {context} = this.props;
        const {seats, loading} = context;
        return (
            !loading?(
            <React.Fragment>
                <StageLocation align={"top"} />
                <SeatContainer>
                    {seats['seats'].map((seatColumn, seatColumnIndex) => {
                        return <SeatColumn key={seatColumnIndex} seatColumn={seatColumn} seatColumnIndex={seatColumnIndex}/>;
                    })}
                </SeatContainer>
                <StageLocation align={"bottom"} />
                <StageHelp />
            </React.Fragment>):(null)
        );
    }
}

export default withStageMapContext(Seats);