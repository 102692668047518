import styled from "styled-components";
import {Col, Container} from "react-bootstrap";

export const Wrapper = styled(Col)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => !props.inline && `min-height: 300px;`}
  & .spinner-border{
    color: var(--color-primary) !important;
  }
`;
