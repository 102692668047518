import styled from "styled-components";
import {Col, Container, Form} from "react-bootstrap";

export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  ${(props) => props.padding && `
        padding: ${props.padding} !important;
  `}
  & .react-country-dropdown{
    & .nv__menu{
        background-color: var(--form-element-background);
        & .nv__option{
          background-color: var(--form-element-background);
          &:hover{
            background : var(--color-shadow-1);
          }
        }
        & .nv__option--is-selected{
          background: var(--color-primary);
        }
    }
    & .nv__control{
        padding: 0.3em 0.7em 0.3em 0.8em;
        border-radius: var(--border-radius-2);
        background-color: var(--form-element-background);
        color: var(--form-element-text-color);
        border-color: var(--color-shadow-08);
    }
    & .nv__control--is-focused{
        border:1px solid  var(--color-shadow-2) !important;
        box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
    }
    & .nv__input-container, & .nv__single-value{
        color: var(--form-element-text-color);
    }
    & .nv__indicator-separator{
        display: none;
    }
    & .nv__dropdown-indicator{
      padding-right: 0;
      & svg{
        fill: var(--form-element-text-color);
        width: 18px;
        height: 18px;
      }
    }
    
  }
  & .nv__control--is-disabled{
    background: var(--form-element-background-disabled) !important;
    color: var(--form-element-text-color-disabled) !important;  
  }
  & .nv__single-value--is-disabled{
    color: var(--form-element-text-color-disabled) !important;
  }
  
`