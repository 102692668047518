import styled from "styled-components";
import {Col} from "react-bootstrap";
import {NavLink} from "react-router-dom";

export const Container = styled(Col)`
  color: var(--color-background);
  margin: 1em 0;
  ${(props) => props.padding && `padding: ${props.padding};`}
  height: 100%;  
`;
export const InnerContent = styled.div`
    position: relative;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    background: var(--color-background);
    z-index: 2;
    height: 100%;
`
export const Wrapper = styled.div`
  border: 2px solid var(--color-border);
  padding: 22px 22px 30px 22px;
  transition: all ease-in-out .3s;
  border-radius: var(--border-radius-2);;
  position: relative;
  z-index: 1;
  height: 100%;
  transition: all 0.2s ease-in-out;
  &:before{
    content: ' ';
    left: 0;
    right: 0;   
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
    background: var(--color-border);
    border-radius: var(--border-radius-2);;
    transition: all 0.2s ease-in-out;
    opacity: 0;
  }
  &:after{
    display: block;
    content: ' ';
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    position: absolute;
    z-index: 1;
    background: var(--color-background);
    border-radius: var(--border-radius-2);;
    transition: all 0.3s ease-in-out;
  }
  &:hover{
    border: 2px solid var(--color-background);
    &:before{
        background-image: linear-gradient(30deg, var(--color-primary) 5%, var(--color-secondary) 100%);
        opacity:1;
        transition: all 0.2s ease-in-out;
    }
    & .read-more{
      transform: rotate(0deg);
      color: var(--color-primary);
      transition: all 0.2s ease-in-out;
    }
  }
`
export const Image = styled.div`
  height: 230px;
  border-radius: var(--border-radius-2);;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: .5em;
`
export const Date = styled.div`
  color: var(--color-shadow-4);
  font-weight: 500;
  padding: .7em 0;
  font-size: 1em;
  & svg{
    margin-right: .4em;
  }
`
export const Title = styled.div`
  padding: .4em 0;
  font-weight: 500;
  min-height: 4.4em;
  max-height: 4.4em;
  overflow: hidden;
  & a{
    color: var(--color-text);
    :hover{
        color: var(--color-primary);
    }
  }
`
export const Description = styled.div`
  color: var(--color-shadow-4);
  font-size: 1em;
  padding: 0;
  min-height: 5.4em;
  max-height: 5.4em;
  overflow: hidden;
`
export const LinkReadMore = styled.div`
  & a{
    color: var(--color-text);
    transition: all ease-in-out .3s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & span{
      border-bottom: 1px solid var(--color-text);
    }
    &:hover{
      color: var(--color-primary);
      & span{
        border-color: var(--color-primary);
      }

    }
  }
`

export const ReadMoreIcon = styled.div`
    width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1em;
  border-radius: 100%;
  background: var(--color-shadow-2);
  & svg{
    width: 22px;
    height: 22px;
  }
  transform: rotate(-45deg);
`

