import React, {Component} from 'react';
import {
    AddIcon,
    FileSelector,
    FileSelectorWrapper,
    InputCol,
    InputContainer,
    InputGroup,
    Wrapper, FileUploadWrapper
} from "./style";
import {Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faVideo, faFileAudio} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner/Spinner";
import {
    formatBytes, getFileTypeByExtension,
    handleValidationErrors
} from "../../../helpers";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withUploaderContext} from "./UploaderContext";
import FileInfo from "./FileInfo";
import Tools from "./Tools";
import UploadService from "../../../services/UploadService";
class Uploader extends Component {
    refUploader = React.createRef();
    constructor(props) {
        super(props);
        const {name} = props.context.state;
        this.state = {
            name,
            fileInfo: null,
            image: null,
            preview: null,
            submitting: false,
            percent: 0,
            error: false
        }
    }

    componentDidMount() {
        const {defaultValue} = this.props;
        if(defaultValue && defaultValue.uploaded)
            this.updateFileInfo(defaultValue.uploaded);

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.error !== this.props.context.error){
            this.setState({
                error: this.props.context.error
            })
        }
    }

    updateFileInfo = (fileInfo, callback = null) => {
        let preview = (fileInfo['file_type'] === "audio")?<FontAwesomeIcon icon={faFileAudio} />:(<FontAwesomeIcon icon={faVideo} />);
        let image = null;
        if(fileInfo['file_type'] === "image") {
            preview = "";
            image = fileInfo['url'];
        }
        this.setState({
            fileInfo: fileInfo,
            preview,
            image,
            submitting: false,
            error: false
        }, callback)
    }
    resetValues = () => {
        this.setState({
            image: null,
            preview: null,
            fileInfo: null,
            percent: 0,
            error: false,
            submitting: false,
        });
    }

    onSelectFileHandler = async(e) => {
        const {context, applicationContext} = this.props;
        const {maxSize, index, uploadToServer, formats, accept, onStartUpload, onCompleteUpload} = context;
        if(e.currentTarget.files.length <= 0) return;
        let file = e.currentTarget.files[0];
        this.resetValues();
        file.uploaded = null;
        if(formats && !formats.includes(file.name.split('.').pop())){
            this.setState({
                error: applicationContext.translator("Only "+accept+" files are allowed")
            })
            return;
        }
        if(file.size > maxSize){
            this.setState({
                error: applicationContext.translator("File size must be less than " + formatBytes(maxSize))
            })
            return;
        }
        if(!uploadToServer){
            context.addNewUploader(index, file);
            return;
        }
        this.setState({ submitting: true });
        if(onStartUpload) onStartUpload();

        try{
            let ft = getFileTypeByExtension(file.name.split('.').pop());
            let result = await UploadService.doUpload(file, ft, this.onUploadProgress);
            if(result){
                file.uploaded = result;
                this.updateFileInfo(result, () => {
                    context.addNewUploader(index, file);
                });
            }
        }catch (e){
            let errors = handleValidationErrors(e);
            this.setState({
                error: errors.join("\n"),
                submitting: false
            })
        }
        if(onCompleteUpload) onCompleteUpload();

    }
    onUploadProgress = (e) => {
        let percent = Math.floor((e.loaded / e.total) * 100);
        this.setState({
            percent
        })
    }
    onOpenFileSelect = (e) => {
        if(this.state.fileInfo) return;
        this.refUploader.click();
    }
    render() {
        const {context, index, placeholder} = this.props;
        const {name} = context;
        const {submitting, fileInfo, preview, image, error, percent} = this.state;
        let inputProps = Object.assign({}, this.props);
        Object.keys(inputProps).forEach((prop) => {
            if(typeof inputProps[prop] === "object")
                delete inputProps[prop];
        })
        return (
            <InputGroup as={Row}>
                <InputCol>
                    <Wrapper>
                        <InputContainer
                            {...inputProps}
                            name={name+"["+index+"]"}
                            disabled={submitting || fileInfo}
                            type="file"
                            ref={(ref) => this.refUploader = ref}
                            onChange={this.onSelectFileHandler}
                        />
                        <FileUploadWrapper>
                            <FileSelector onClick={this.onOpenFileSelect}
                                          preview={image}
                                          disabled={fileInfo}
                                          isInvalid={error}
                            >
                                <FileSelectorWrapper className={"selector"}>
                                    <AddIcon>
                                        <Spinner size={"sm"} show={submitting} inline={true}/>
                                        {!submitting?(preview?preview:<FontAwesomeIcon icon={faPlus} />):(
                                            <div className={"uploading"}>
                                                uploading...<br/>
                                                {percent}%
                                            </div>
                                        )}
                                    </AddIcon>
                                </FileSelectorWrapper>
                            </FileSelector>
                            <FileInfo fileInfo={fileInfo} error={error} placeholder={placeholder} />
                            <Tools fileInfo={fileInfo} index={index} resetValues={this.resetValues}/>

                        </FileUploadWrapper>

                    </Wrapper>

                </InputCol>
            </InputGroup>
        );
    }
}
Uploader.propTypes = {
    index: PropTypes.number,
    defaultValue: PropTypes.object,
    placeholder: PropTypes.string
}
export default withApplicationContext(withUploaderContext(Uploader));