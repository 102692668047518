import React, {Component} from 'react';
import {Container, PickerButton, CalendarContainer} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import Calendar from "react-calendar";
import {convertToDate, toDate} from "../../../helpers";
import {Label} from "../InputBox/style";

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            picker: false,
            date: null,
            nativeDate: null
        }
    }
    onChange = (date) => {
        const {applicationContext, format, onChange} = this.props;
        let formatDate = convertToDate(date, applicationContext.state.locale, 'YYYY/MM/DD HH:mm:ss', format??'dddd, D MMMM YYYY')
        this.setState({
            picker: false,
            date: formatDate,
            nativeDate: date
        })
        if(onChange) onChange(date, formatDate);
    }
    onClickPicker = (e) => {
        this.setState({
            picker: !this.state.picker
        })
    }
    render() {
        const {placeholder, margin, paddingAround, label} = this.props;

        return (
            <Container margin={margin} paddingAround={paddingAround}>
                {label && <Label>{label}</Label>}
                <PickerButton onClick={this.onClickPicker}>{this.state.date??placeholder}</PickerButton>
                {this.state.picker && <CalendarContainer><Calendar value={this.state.nativeDate} onChange={this.onChange} selectRange={false}/></CalendarContainer>}
            </Container>
        );
    }
}
DatePicker.propTypes = {
    placeholder: PropTypes.string,
    margin: PropTypes.string,
    format: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    paddingAround: PropTypes.string,
    label: PropTypes.string,
}
export default withApplicationContext(DatePicker);