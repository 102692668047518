import api from "./adminApi";
import AdminTokenService from "./AdminTokenService";
const getUserBoard = () => {
    return api.get("/profile/me").then((response) => {
        AdminTokenService.setUser(response.data.user);
        return AdminTokenService.getUser();
    })
};

const list = (service, data) => {
    return api
        .get(`/admin/${service}/list`, {
            params: data
        })
        .then((response) => {
            return response.data;
        })
};
const get = (service, id) => {
    return api
        .get(`/admin/${service}/get/${id}`)
        .then((response) => {
            return response.data;
        })
};
const doGet = (service, url, id, params = {}) => {
    return api
        .get(`/admin/${service}/${url}/${id}`, params)
        .then((response) => {
            return response.data;
        })
};
const doCreate = (service, data) => {
    data = {
        ...data,
        locale: "en"
    }
    return api
        .post(`/admin/${service}/create`, data)
        .then((response) => {
            return response.data;
        })
};
const doUpdate = (service, id, data) => {
    return api
        .post(`/admin/${service}/update/${id}`, data)
        .then((response) => {
            return response.data;
        })
};
const doDelete = (service, id) => {
    return api
        .delete(`/admin/${service}/delete/${id}`)
        .then((response) => {
            return response.data;
        })
};

const AdminService = {
    getUserBoard,
    list,
    get,
    doGet,
    doCreate,
    doDelete,
    doUpdate
};

export default AdminService;