import React from "react";
const ApplicationContext = React.createContext({});
const ApplicationProvider = ApplicationContext.Provider;
const ApplicationConsumer = ApplicationContext.Consumer;
const withApplicationContext = Component => React.forwardRef((props, ref) => (
    <ApplicationConsumer>
        {(response) => {
            return <Component {...props} applicationContext={response} ref={ref}/>;
        }}
    </ApplicationConsumer>
));
export { ApplicationProvider, ApplicationConsumer, withApplicationContext };
