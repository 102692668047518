import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import AdminService from "../../../services/AdminService";
import DataList from "../../../components/ui/DataList/DataList";
import Search from "./Search";
import {StatusLabel} from "./style";

class Payments extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {
                ...urlSearchParams.get('id') && {id: urlSearchParams.get('id')}
            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
            dataStatus: props.status,
            description: null,
            submitting: false
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);

        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        const {alert, service} = this.props;
        this.setState({ loadingData: true })
        if(this.state.search && this.state.search.hasOwnProperty('id')){
            search = {
                id: this.state.search.id,
            };
        }

        let params = {
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search && search)
        };
        let data = await AdminService.list(service, params).catch((e) => {
            alert.error(e.response.data.message)
            this.setState({
                loadingData: false,
                data: []
            });
        });
        if(data){
            this.setState({
                loadingData: false,
                data: data,
                sort:{
                    key: sort_field,
                    dir: sort_dir
                },
                search
            }, callback)
        }

    }
    renderSearch = () => {
        return <Search data={this.state.search} status={this.state.dataStatus} doFetchData={this.fetchData}/>;
    }
    renderStatus = (value, rowIndex, row) => {
        return <StatusLabel status={value}>{value}</StatusLabel>
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "ref_id", label: "Reference id"},
                        {key: "order.id", label: "Order id", format: "uniqueId"},
                        {key: "order.basket_details.summary.checkout.total", label: "Total price"},
                        {key: "status", label: "Status", render: this.renderStatus},
                        {key: "callback_at", label: "Paid time", format: "datetime"}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext((Payments)))));