import React, {Component} from 'react';
import {
    InputGroup,
    InputCol,
    Wrapper,
    Label, RowForm, ColForm, ToolsItem,

} from "./style.jsx";
import PropTypes from "prop-types";
import {Row} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes, faPlus, faChevronUp, faChevronDown, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner/Spinner";
import InputBox from "../InputBox/InputBox";
import Dropdown from "../Dropdown/Dropdown";
import {moveArrayIndex, uuid} from "../../../helpers";
import {InputError} from "../InputBox/style";
class InputObject extends Component {
    refInput = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            output: props.value
        }
    }
    componentDidMount = () => {
        let {output} = this.state;
        if(output.length === 0)
            this.addEmptyRow();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value.length !== this.state.output.length && this.props.value.length === 0){
            this.setState({
                output: []
            }, () => {
                this.addEmptyRow();
            })
        }
    }

    addEmptyRow = () => {
        let {output} = this.state;
        const {elements, onChange, name} = this.props;
        let row = {};
        elements.map((element) => row[element.key] = element.value);
        row.id = uuid();
        output.push(row);
        this.setState({
            output
        }, () => {
            if(onChange)
                onChange(name, output)
        })
    }
    onAddNewRow = (e) => {
        this.addEmptyRow();
    }
    onChangeValue = (value, item, index) => {
        const {onChange, name} = this.props;
        let {output} = this.state;
        output[index][item.key] = value;
        this.setState({
            output
        }, () => {
            if(onChange)
                onChange(name, output)
        })
    }
    renderObject = (row, item, index) => {
        const {output} = this.state;
        const {name, touched, errors} = this.props;
        if(index === -1)
            index = output.length;
        let errs = {
            [item.key]: ""
        };
        let touchs = {
            [item.key]: ""
        };
        if(errors[name] && errors[name][index] && errors[name][index].hasOwnProperty(item.key))
            errs[item.key] = errors[name][index][item.key];
        if(touched[name] && touched[name][index] && touched[name][index].hasOwnProperty(item.key))
            touchs[item.key] = touched[name][index][item.key];
        switch (item.object){
            case 'text':
                return (
                    <InputBox type={"text"}
                              name={item.key}
                              margin={"0"}
                              placeholder={item.label}
                              paddingAround={".5em"}
                              onChange={(e) => this.onChangeValue(e.target.value, item, index)}
                              value={(row && row[item.key])?row[item.key]:""}
                              touched={touchs}
                              errors={errs}
                    />
                )
            break;
            case 'number':
                return (
                    <InputBox type={"text"}
                              onlyNumber={true}
                              margin={"0"}
                              placeholder={item.label}
                              name={item.key}
                              paddingAround={".5em"}
                              onChange={(e) => this.onChangeValue(e.target.value, item, index)}
                              value={(row && row[item.key])?row[item.key]:""}
                              touched={touchs}
                              errors={errs}
                    />
                )
            break;
            case 'select':
                return (
                    <Dropdown
                        name={item.key}
                        value={(row && row[item.key])?row[item.key]:""}
                        placeholder={item.label}
                        margin={"0"}
                        paddingAround={".5em"}
                        onChange={(e) => this.onChangeValue(e.target.value, item, index)}
                        data={item.choices}
                        touched={touchs}
                        errors={errs}
                    />
                )
            break;
        }
        return null;
    }
    onDeleteRow = (index) => {
        const {onChange, name} = this.props;
        let {output} = this.state;
        if(output.length === 1) return;
        output.splice(index, 1);
        this.setState({
            output
        }, () => {
            if(onChange)
                onChange(name, output)
        })
    }
    onMoveToUp = (old_index) => {
        const {onChange, name} = this.props;
        if(old_index === 0) return;
        let data = this.state.output;
        let new_index = old_index - 1;
        let updated = moveArrayIndex(data, old_index, new_index)

        this.setState({
            output: updated
        }, () => {
            if(onChange)
                onChange(name, updated)
        })
    }
    onMoveToDown = (old_index) => {
        const {onChange, name} = this.props;
        let data = this.state.output;
        if(old_index === data.length - 1) return;
        let new_index = old_index + 1;
        let updated = moveArrayIndex(data, new_index, old_index)

        this.setState({
            output: updated
        }, () => {
            if(onChange)
                onChange(name, updated)
        })
    }
    renderElementsRow = (rowIndex, row = null) => {
        const {elements} = this.props;
        const {output} = this.state;
        let size = 0;
        return (
            <RowForm key={row.id}>
                {elements.map((element, colIndex) => {
                    size += element.size;
                    return (
                        <ColForm key={colIndex} md={element.size}>
                            {this.renderObject(row, element, rowIndex)}
                        </ColForm>
                    );
                })}
                <ColForm md={2}>
                    <ToolsItem onClick={(e) => this.onMoveToUp(rowIndex)} disabled={rowIndex === 0}>
                        <FontAwesomeIcon icon={faChevronUp} alt={"Move to up"}/>
                    </ToolsItem>
                    <ToolsItem onClick={(e) => this.onMoveToDown(rowIndex)} disabled={rowIndex === output.length - 1}>
                        <FontAwesomeIcon icon={faChevronDown} alt={"Move to down"}/>
                    </ToolsItem>
                    <ToolsItem onClick={(e) => this.onDeleteRow(rowIndex)} delete={true} disabled={output.length <= 1}>
                        <FontAwesomeIcon icon={faTrashAlt} alt={"Delete"}/>
                    </ToolsItem>
                    {rowIndex === output.length - 1 && <ToolsItem add={true} onClick={this.onAddNewRow}>
                        <FontAwesomeIcon icon={faPlus} />
                    </ToolsItem>}


                </ColForm>
            </RowForm>
        )
    }

    render() {
        const {label, margin, paddingAround, line, touched, errors, name} = this.props;
        const {output} = this.state;
        return (
            <InputGroup as={Row} margin={margin}>
                <InputCol paddingAround={paddingAround}>
                    <Wrapper line={line}>
                        {label && <Label>{label} {line?":":""}</Label>}
                        {output.map((row, index) => {
                            return this.renderElementsRow(index, row);
                        })}
                    </Wrapper>

                    {touched && touched[name] && typeof errors[name] === 'string' &&
                        <InputError hidden={errors[name] === undefined && true}>{errors[name]}</InputError>
                    }
                </InputCol>
            </InputGroup>
        );
    }
}
InputObject.propTypes = {
    name: PropTypes.string,
    form: PropTypes.object,
    value: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.string,
    paddingAround: PropTypes.string,
    line: PropTypes.bool,
    elements: PropTypes.array
}
export default InputObject;