import React, {Component} from 'react';
import {ButtonContainer} from "./style.jsx";
import PropTypes from 'prop-types';
import {Spinner} from "react-bootstrap";
const spinner = <Spinner animation="border" variant="light" size={"sm"} />;
class Button extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const {color, radius, children, submitting, fullWidth, margin, size, disabled} = this.props;

        return (
            <ButtonContainer
                {...this.props}
                color={color}
                radius={radius}
                fullWidth={fullWidth}
                margin={margin}
                size={size}
                disabled={disabled || submitting}
            >
                {submitting?spinner:children}
            </ButtonContainer>
        );
    }
}
Button.propTypes = {
    color: PropTypes.string,
    margin: PropTypes.bool,
    radius: PropTypes.number,
    fullWidth: PropTypes.bool,
    size: PropTypes.number,
    submitting: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
}
export default (Button);