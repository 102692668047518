import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 3em 0;
  
  ${(props) => props['transparent']?`
        position: relative;
        background: transparent;
  `:`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99999;
      background: var(--color-background);
  `}
  ${(props) => props['bg'] && `
        background: ${props['bg']};
  `}
`;
export const Logo = styled.img`
  width: 80px;
  pointer-events: none;
  ${(props) => props['animation'] && `
      animation: zoom-in-zoom-out 1s ease infinite;
      @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  `}

`;
