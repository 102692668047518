import React, {Component} from 'react';
import {PopupButton, PopupMobileText} from "./style";
import Button from "../../components/ui/Button";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withCartContext} from "./CartContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobile } from '@fortawesome/free-solid-svg-icons'
class DesktopUsePopup extends Component {
    render() {
        const {applicationContext, handleClose} = this.props;
        return (
            <PopupMobileText>
                <FontAwesomeIcon icon={faMobile} /><br/>
                {applicationContext.translator("To ensure the best experience while booking seats, we recommend using a desktop computer rather than a mobile device. The desktop version of our booking platform provides a more comprehensive view of available seating options, a smoother navigation experience, and enhanced functionality. This will allow you to easily select and confirm your preferred seats with greater accuracy and efficiency. Thank you for your cooperation!")}<br/>
                <PopupButton>
                    <Button color={"primary"} fullWidth={true} onClick={handleClose} margin={"0 .2em"}>
                        {applicationContext.translator("OK")}
                    </Button>
                </PopupButton>
            </PopupMobileText>
        );
    }
}

export default withApplicationContext(withCartContext(DesktopUsePopup));