import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAccountContext} from "../../../contexts/AccountContext";
import {CheckoutProvider} from "./CheckoutContext";
import {Container,  MainContainer} from "../style";
import CommonService from "../../../services/CommonService";
import PageNotFound from "../../../components/ui/PageNotFound";
import ContentLoader from "react-content-loader";
import CheckoutBar from "./CheckoutBar";
import BackToCart from "./BackToCart";
import {Form} from "react-bootstrap";
import OrderSummary from "./OrderSummary";
import PaymentMethod from "./PaymentMethod";
import * as Yup from "yup";
import {Formik} from "formik";
import {FormProvider} from "../../../contexts/FormContext";
import {parseErrorMessage} from "../../../helpers";
import PaymentPage from "./PaymentPage";
import ContentLoading from "../../../components/ui/ContentLoading";

class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            page: 'checkout',
            dataInput: null,
            checkoutInfo: null,
            cart: null,
            inputs: {
                type: "1",
                first_name: "",
                last_name: "",
                mobile_country: "",
                mobile: "",
                email: "",
                address: "",
                national_id: "",
                send_via_sms: "",
                deliver_to_address: ""
            },
            submitting: false,
            closeReservation: false,
            loading: true
        }
    }
    componentDidMount = async () => {
        await this.getEventById();
    }
    routeToNotFound = () => {
        this.setState({
            notFound : true,
            loading: false
        })
    }
    getEventById = async () => {
        const {params, applicationContext, accountContext} = this.props;
        let cart = await CommonService.doGet(`/basket/basket-checkout/${params['basket_id']}`).catch((e) => {
            this.routeToNotFound();
        });
        if(!cart || cart['reservation_closed'] || cart.items.length === 0){
            this.routeToNotFound();
            return;
        }
        applicationContext.doChangePageTitle(cart.event.title);
        this.setState({
            notFound: false,
            closeReservation: cart['reservation_closed'],
            cart,
            loading: false,
        })

    }
    renderDataLoading = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <ContentLoading paddingTop={"2em"}>
                <rect x="0" y="0" rx="5" ry="5" width="70%" height="60" />
                <rect x="71%" y="0" rx="5" ry="5" width="30%" height="60" />
                <rect x="0" y="70" rx="5" ry="5" width="100%" height="80%" />
            </ContentLoading>

        )
    }
    onPay = async (values, actions) => {
        const {alert, applicationContext} = this.props;
        this.setState({
            page: 'pay',
            dataInput: values
        })
        // let order = await CommonService.doPost(`/checkout/create-order/${this.state.cart.id}`, values).catch((e) => {
        //     alert.error(applicationContext.translator(parseErrorMessage(e)));
        // });
        // if(order){
        //     this.setState({
        //         page: 'pay',
        //         checkoutInfo: order
        //     })
        // }else{
        //     this.setState({ submitting: false })
        // }
    }
    renderViewComponent = () => {
        const {loading, notFound, page} = this.state;
        const {applicationContext} = this.props;
        if(loading) return null;
        if(notFound) return (<PageNotFound />);
        if(page === "pay"){
            return (<PaymentPage />);
        }
        return (
            <React.Fragment>
                <BackToCart />
                <Formik
                    enableReinitialize={true}
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.inputs}
                    validationSchema={Yup.object().shape({
                        type: Yup.string().required("You must choose the customer information"),
                        first_name: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("first name")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        last_name: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("last name")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        mobile: Yup.string().required("You must enter your ${path}").min(9, applicationContext.translator("You must enter minimum 9 digits")).label(applicationContext.translator("mobile number")),
                        email: Yup.string().required("You must enter your ${path}").email(applicationContext.translator("E-Mail address format is invalid.")).label(applicationContext.translator("e-mail address")),
                    })}
                    onSubmit={this.onPay}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue
                      }) =>
                        (
                            <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                <FormProvider value={{
                                    values,
                                    handleChange,
                                    setFieldValue,
                                    errors,
                                    touched,
                                    submitting: this.state.submitting
                                }}>
                                    <MainContainer>
                                        <OrderSummary />
                                        <PaymentMethod />
                                    </MainContainer>
                                    <CheckoutBar />
                                </FormProvider>
                            </Form>
                        )
                    }
                </Formik>
            </React.Fragment>
        );
    }
    doUpdateState = (state) => {
        this.setState(state);
    }
    render() {
        return (
            <CheckoutProvider value={{
                state: this.state,
                doUpdateState: this.doUpdateState
            }}>
                <Container>
                    {this.renderDataLoading()}
                    {this.renderViewComponent()}
                </Container>
            </CheckoutProvider>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(Checkout))));