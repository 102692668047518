import styled from "styled-components";

export const AppContainer = styled.div`
    direction: ${(props) => props.direction};
    text-align: ${(props) => (props.direction === "rtl")?"right":"left"};
    height: 100%;
    position: relative;
`;
export const AppHeader = styled.div`
    
`;