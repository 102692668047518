import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import SearchTable from "../../../components/ui/SearchTable";
import Button from "../../../components/ui/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchButton} from "../../../components/ui/SearchTable/style";
import {faPlus} from "@fortawesome/free-solid-svg-icons"
import {ROUTE_ADMIN_ADD_ARTIST, ROUTE_ADMIN_ADD_EVENT} from "../../../routes/routes";
class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount = async () => {
        this.setState({
            loading: false
        })
    }
    onClickAddNew  = (e) => {
        const {navigate} =this.props;
        navigate(ROUTE_ADMIN_ADD_EVENT);
    }
    render() {
        const {doFetchData} = this.props;
        return (
            <SearchTable
                loading={this.state.loading}
                search={[
                    {id: 'keyword', type: 'textbox', label: "Search by keyword"},
                    {id: 'status', type: 'dropdown', label: "Search by status", data: [
                        { key: "1", label: "Active"},
                        { key: "2", label: "Deactive"},
                    ], defaultValue: this.props.status, defaultLabel: "All Status"},
                ]}
                sort={{
                    field: 'id',
                    dir: 'asc'
                }}
                doFetchData={doFetchData}
            >
                <Button color={"success"} size={3}  margin={"0"} radius={10} onClick={this.onClickAddNew}><FontAwesomeIcon icon={faPlus} /> <span>Add new event</span></Button>
            </SearchTable>
        );
    }
}
Search.propTypes = {
    data: PropTypes.any,
    doFetchData: PropTypes.func
}
export default withApplicationContext(withRouter(withAdminContext((Search))));