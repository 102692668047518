import React, {Component} from 'react';
import {ActionIcon, ToolsContainer} from "./style";
import {withUploaderContext} from "./UploaderContext";
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt, faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import UploadService from "../../../services/UploadService";

class Tools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteSubmitting: false,
            upSubmitting: false,
            downSubmitting: false,
        }
    }
    onDelete = async (e) => {
        const {context, index, fileInfo, resetValues} = this.props;
        const {uploadToServer, onDeleteUploader} = context;
        if(!fileInfo) return;
        if(!uploadToServer){
            if(onDeleteUploader)
                await onDeleteUploader(index);
            return;
        }
        this.setState({ deleteSubmitting: true })
        await UploadService.doDelete(fileInfo.id).then(async (data) => {
            resetValues();
            if(onDeleteUploader)
                await onDeleteUploader(index);
        });
    }
    onClickToUp = async (e) => {
        const {context, index, fileInfo, resetValues} = this.props;
        const {onMoveToUp} = context;
        this.setState({ upSubmitting: true })
        await onMoveToUp(index);
        this.setState({ upSubmitting: false })
    }
    onClickToDown = async (e) => {
        const {context, index, fileInfo, resetValues} = this.props;
        const {onMoveToDown} = context;
        this.setState({ downSubmitting: true })
        await onMoveToDown(index);
        this.setState({ downSubmitting: false })
    }
    render() {
        const {fileInfo, index, context} = this.props;
        const {deleteSubmitting, upSubmitting, downSubmitting} = this.state;
        if(!fileInfo) return null;
        return (
            <ToolsContainer>
                <ActionIcon onClick={this.onClickToUp} disabled={index === 0}>
                    <Spinner size={"sm"} show={upSubmitting} inline={true}/>
                    {!upSubmitting && <FontAwesomeIcon icon={faChevronUp} alt={"Move to up"}/>}
                </ActionIcon>
                <ActionIcon onClick={this.onClickToDown} disabled={index === context.state.data.length - 1}>
                    <Spinner size={"sm"} show={downSubmitting} inline={true}/>
                    {!downSubmitting && <FontAwesomeIcon icon={faChevronDown} alt={"Move to down"}/>}
                </ActionIcon>
                <ActionIcon onClick={this.onDelete}>
                    <Spinner size={"sm"} show={deleteSubmitting} inline={true}/>
                    {!deleteSubmitting && <FontAwesomeIcon icon={faTrashAlt} alt={"Delete"}/>}
                </ActionIcon>

            </ToolsContainer>
        );
    }
}
Tools.propTypes = {
    fileInfo: PropTypes.object,
    index: PropTypes.number,
    resetValues: PropTypes.func
}
export default withUploaderContext(Tools);