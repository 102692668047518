import React, {Component} from 'react';
import { Container } from "./style.jsx";
class FormErrorText extends Component {
    render() {
        const {children} = this.props;
        return (
            <Container>
                {children}
            </Container>
        );
    }
}
export default FormErrorText;