import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import SearchTable from "../../../components/ui/SearchTable";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount = async () => {
        this.setState({
            loading: false
        })
    }
    render() {
        const {doFetchData} = this.props;
        return (
            <SearchTable
                loading={this.state.loading}
                search={[
                    {id: 'keyword', type: 'textbox', label: "Search by keyword | #id , first_name, last_name, national_id, email, mobile number", defaultValue: this.props.data.keyword},
                ]}
                sort={{
                    field: 'id',
                    dir: 'desc'
                }}
                doFetchData={doFetchData}
            />
        );
    }
}
Search.propTypes = {
    data: PropTypes.any,
    doFetchData: PropTypes.func
}
export default withApplicationContext(withRouter(withAdminContext(Search)));