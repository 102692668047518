import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import SlideShow from "../../components/ui/SlideShow/SlideShow";
import RowSupportFooter from "../../components/ui/Footer/RowSupportFooter";
import MusiciansText from "./MusiciansText";

class Musicians extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        let images = [
            { slideshow: { url: "/assets/images/musicians-01.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-02.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-03.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-04.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-05.jpg?v=1" } }
        ]
        return (
            <React.Fragment>
                <Container>
                    <SlideShow disabledFooter={true} fixedFooter={true} images={images}/>
                    <MusiciansText />
                    <RowSupportFooter />
                </Container>
            </React.Fragment>


        );
    }
}

export default withAlert()((withRouter(Musicians)));