import React, {Component} from 'react';
import {SeatColumnContainer} from "./style";
import SeatRow from "./SeatRow";
import {withStageMapContext} from "./StageMapContext";
import PropTypes from "prop-types";

class SeatColumn extends Component {
    render() {
        const {seatColumn, seatColumnIndex} = this.props;
        return (
            <SeatColumnContainer key={seatColumnIndex}>
                {seatColumn.map((seatRow, seatRowIndex) => {
                    return <SeatRow seatRow={seatRow}
                                    seatRowIndex={seatRowIndex}
                                    seatColumnIndex={seatColumnIndex}
                                    key={seatRowIndex}
                    />
                })}
            </SeatColumnContainer>
        )
    }
}
SeatColumn.propTypes = {
    seatColumn: PropTypes.array,
    seatColumnIndex: PropTypes.number
}
export default withStageMapContext(SeatColumn);