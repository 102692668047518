import React, {Component} from 'react';
import {OptionContainer} from "../style";
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withCheckoutContext} from "./CheckoutContext";
import {Col, Row} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import CountryDropDown from "../../../components/ui/CountryDropDown/CountryDropDown";
import {withFormContext} from "../../../contexts/FormContext";
import {withAccountContext} from "../../../contexts/AccountContext";

class CustomerForm extends Component {
    render() {
        const {formContext, applicationContext, accountContext} = this.props;
        const {handleChange, values, errors, touched, setFieldValue} = formContext;
        return (
            <OptionContainer>
                <Row>
                    <Col md={6}>
                        <InputBox type={"text"}
                                  name={"first_name"}
                                  onChange={handleChange}
                                  label={applicationContext.translator("First name")}
                                  value={values['first_name']}
                                  disabled={values.type === "1"}
                                  requiredStar={true}
                                  errors={errors}
                                  touched={touched}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox type={"text"}
                                  name={"last_name"}
                                  onChange={handleChange}
                                  label={applicationContext.translator("Last name")}
                                  value={values['last_name']}
                                  disabled={values.type === "1"}
                                  requiredStar={true}
                                  errors={errors}
                                  touched={touched}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox type="text"
                                  name={"email"}
                                  onChange={handleChange}
                                  label={applicationContext.translator("E-Mail Address")}
                                  value={values.email}
                                  disabled={values.type === "1"}
                                  requiredStar={true}
                                  errors={errors}
                                  touched={touched}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox type="text"
                                  name={"national_id"}
                                  onlyNumber={true}
                                  onChange={handleChange}
                                  label={applicationContext.translator("ID Card Number")}
                                  value={values['national_id']}
                                  disabled={values.type === "1" && accountContext.state.userInfo['national_id']}
                                  requiredStar={values.type === "1"}
                                  errors={errors}
                                  touched={touched}
                        />
                    </Col>
                    <Col md={6} style={{ display: (values.type === "1")?"none":""}}>
                        <CountryDropDown
                            name={"mobile_country"}
                            onChange={setFieldValue}
                            value={values.mobile_country}
                            onlyCountryCode={true}
                            label={applicationContext.translator("Mobile country code")}
                            requiredStar={true}
                            disabled={values.type === "1"}
                            errors={errors}
                            touched={touched}
                        />
                    </Col>
                    <Col md={(values.type === "1")?12:6}>
                        <InputBox type="text"
                                  onlyNumber={true}
                                  name={"mobile"}
                                  onChange={handleChange}
                                  label={applicationContext.translator("Mobile number")}
                                  value={values.mobile}
                                  disabled={values.type === "1"}
                                  requiredStar={true}
                                  maxLength={12}
                                  errors={errors}
                                  touched={touched}
                        />
                    </Col>

                </Row>
            </OptionContainer>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withCheckoutContext(withFormContext(withAccountContext(CustomerForm))))));