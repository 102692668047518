import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import Registration from "./Registration";
import EnterInformation from "./Forms/EnterInformation";
import Service from "../../services/Service";
import TokenService from "../../services/TokenService";
import {parseErrorMessage} from "../../helpers";
import EmailCheckInbox from "./EmailCheckInbox";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: "",
            submitting: false,
            successfully: false,
            inputErrors: null
        }

    }


    componentDidMount() {

    }

    handleSubmit = async (values, actions) => {
        const {applicationContext, accountContext, alert, onCallback} = this.props;
        this.setState({ submitting: true });
        try {
            values.mobile_country = values.mobile_country.replaceAll("+", "");
            if(values.mobile && values.mobile.startsWith("0")){
                values.mobile = values.mobile.substring(1, values.mobile.length)
            }
            const user  = await Service.register(values);
            this.setState({
                successfully: true
            })
        } catch (err) {
            this.setState({ submitting: false });
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }
    }
    render() {
        const {successfully, inputErrors} = this.state;
        return (
            <React.Fragment>
                <Registration>
                    {(successfully)?(
                        <EmailCheckInbox />
                    ):(
                        <EnterInformation
                            onSubmit={this.handleSubmit}
                            submitting={this.state.submitting}
                            inputErrors={inputErrors}
                        />
                    )}
                </Registration>
            </React.Fragment>



        );
    }
}

export default withAlert()(withRouter(withApplicationContext(Register)));