import React from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {AlertContainer, Close, Message} from "./style";
import {faTimesCircle, faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {faTimes, faInfo} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const renderIcon = (type) => {
    switch (type){
        case "error":
            return (<FontAwesomeIcon icon={faTimesCircle} />);
            break;
        case "success":
            return (<FontAwesomeIcon icon={faCheckCircle} />);
            break;
    }
    return (
        <svg version="1.1" viewBox="0 0 32 32">
            <g>
                <path d="M17.962,24.725l1.806,0.096v2.531h-7.534v-2.406l1.045-0.094c0.568-0.063,0.916-0.254,0.916-1.014v-8.801
                    c0-0.699-0.188-0.92-0.791-0.92l-1.106-0.062v-2.626h5.666L17.962,24.725L17.962,24.725z M15.747,4.648
                    c1.394,0,2.405,1.047,2.405,2.374c0,1.331-1.014,2.313-2.438,2.313c-1.454,0-2.404-0.982-2.404-2.313
                    C13.31,5.695,14.26,4.648,15.747,4.648z M16,32C7.178,32,0,24.822,0,16S7.178,0,16,0c8.82,0,16,7.178,16,16S24.82,32,16,32z M16,3
                    C8.832,3,3,8.832,3,16s5.832,13,13,13s13-5.832,13-13S23.168,3,16,3z"/>
            </g>
        </svg>
    );
}
const CustomAlertTemplate = function CustomAlertTemplate({ style, options, message, close }) {
    return (
        <AlertContainer type={options.type}>
            <Message type={options.type}>
                {renderIcon(options.type)} {message}
            </Message>
            <Close onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
            </Close>
        </AlertContainer>
    );
};

export default withApplicationContext(CustomAlertTemplate);
