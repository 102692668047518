import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
export const Container = styled.div`
  display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0;
`;
export const Wrapper = styled.div`
  position: relative;
  border: 1px solid var(--color-border);
  padding: 1em;
  border-radius: var(--border-radius-2);;
`;
export const SeatContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const Package = styled(Col)`
  display: flex;
  flex: 1;
  margin-top: .5em;
  justify-content: center;
`
export const PackageWrapper = styled(Row)`
    padding-top: 2em;
  width: fit-content;
  @media screen and (max-width: 700px) {
    
  }
`
export const PackageColor = styled.div`
    background: ${(props) => props.bg};
    min-width: 100px;
    padding: 0.3em 0.6em;
    border-radius: var(--border-radius-1);
      display: flex;
      justify-content: center;
      align-items: center;
    color: #fff;
    font-weight: 500;
    margin-right: .5em;
    text-transform: uppercase;
    ${(props) => props.left && `margin-left: ${props.left}`}
`
export const PackagePrice = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0.5em;
    font-weight: 500;
  min-width: 70px;
`
export const SeatColumnContainer = styled.div`
  width: fit-content;
`;
export const SeatRowContainer = styled.div`
  display: flex;
  flex:1;
`;
export const SeatLabel = styled.div`
  height: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcff8c;
    font-weight: 500;
    color: #ad0b1a;
  font-size: ${(props) => ((props.size * 3.6) / 100)}em;
  border-radius: var(--border-radius-1);
    margin: 1px;
`;
export const SeatCell = styled.div`
    height: ${(props) => props.size}px;
    min-width: ${(props) => props.size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: var(--border-radius-1);
    margin: 1px;
    ${(props) => props.bg && `background: ${props.bg};`}
    font-size: ${(props) => ((props.size * 3.6) / 100)}em;
    position: relative;
    &:after{
        content: " ";
        position: absolute;
        left:0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255,255,255,.3);
        transition: all 0.2s ease-in-out;
        border-radius: 3px;
        opacity: 0;
        ${(props) => props.selected && `
            opacity: 1;
            transition: all 0.2s ease-in-out;
        `}    
    }
    
    
  ${(props) => props.disabled?`
    cursor: initial;
   
  `:`
    cursor: pointer;
    &:hover{
        &:after{
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
    }
  `}
`;
export const WhiteSpace = styled.div`
  height: ${(props) => props.size}px;
  width: 100%;
`;
export const Header = styled.div`
  width: 100%;
  text-align: center;
    padding: 1em 1em 2em 1em;
    font-weight: 600;
`;
export const StageLabel = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-size: 2em;
    margin-top: 1em;
    & div{
      text-transform: uppercase;
      padding: .5em;
      background: var(--color-border);
      width: 40%;    
    }
`
export const SeatRowWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const SeatPopupSeatInfoWrapper = styled.div`
    width: 100%
`
export const SeatPopupWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em;
`
export const SeatPopup = styled.div`
    position: absolute;
    min-width: 150px;
    height: 80px;
    background: var(--color-background);
    border: 1px solid var(--color-border);
    box-shadow: 0 4px 16px -4px var(--color-shadow-2), 0 0 2px var(--color-shadow-2);
    border-radius: var(--border-radius-2);;
    z-index: 9999;
    bottom: ${(props) => (props.size + 15)}px;
    & .package-color{
        margin: 0;
        border-radius: var(--border-radius-1) var(--border-radius-1) 0 0 ;
    }
    &:after{
        position: absolute;
        bottom: calc((8px) * -1);
        left: calc(50% - (7px + .5em));
        content: "";
        margin:0 0.5em;
        display:inline-block;
        border: 7px solid transparent;
        border-top:8px solid var(--color-background);
        border-bottom:0 none;
    }
    
`
export const SeatInfo = styled.div`
    color: var(--color-text);
    width: 100%;
    display: flex;
    justify-content: space-between;
    & div{
        font-weight: 500;
        text-transform: uppercase;
    }
`
export const PopupText = styled.div`
  font-size: 1em;
  font-weight: 400;
  padding: 0 1em;
  text-align: center;
  & svg{
    margin-bottom: 1em;
    width: 32px;
    height: 32px;
    color: var(--color-primary)
  }
`
export const PopupButton = styled.div`
  padding: 1em;
  font-size: 1.2em;
  & button{
    padding: .5em 1em;
  }
`