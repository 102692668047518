import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import * as Yup from "yup";
import Card from "../../../components/ui/Card";
import {Col, Form, Row} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import AdminService from "../../../services/AdminService";
import Dropdown from "../../../components/ui/Dropdown";
import {
    convertArrayToFiles,
    convertListToKeyValue, convertListToListOfKeys,
    parseErrorMessage,
    prepareParametersData
} from "../../../helpers";
import MultiUploader from "../../../components/ui/Uploader/MultiUploader";
import HtmlEditor from "../../../components/ui/HtmlEditor";
import DateTimePicker from "../../../components/ui/DateTimePicker";
import InputTags from "../../../components/ui/InputTags";
import {SERVICE_ARTISTS, SERVICE_EVENT_PLACES} from "../../../services/services";
import InputObject from "../../../components/ui/InputObject";
const MaxFileUploadSize = 1048576;
class EventFormData extends Component {
    refForm = React.createRef();
    defaultInputs = {
        event_place_id: "",
        title: "",
        description: "",
        content: "",
        owner_message: "",
        policies: "",
        start_time_of_event: "",
        end_time_of_event: "",
        open_reservation: "",
        close_reservation: "",
        doors_open: "",
        max_seats_count: 8,
        slideshows: [],
        gallery: [],
        listing_image: [],
        reservation_banner: [],
        reservation_music: [],
        artists: [],
        varieties: []
    }
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            artists: [],
            eventPlaces: [],
            updatingId: null,
            inputs: this.defaultInputs,
            formDisabled: false
        }

    }
    componentDidMount = async () => {
        const {navigate, params, service} = this.props;
        const {id} = params;
        let artists = await this.fetchArtistsList();
        let eventPlaces = await this.fetchEventPlacesList();

        if(id){
            try {
                let data = await AdminService.get(service, id);
                if (data) {
                    let values = {
                        event_place_id: data.place.id,
                        title: data.title,
                        description: data.description,
                        content: data.content,
                        owner_message: data.owner_message,
                        policies: data.policies,
                        start_time_of_event: new Date(data.start_time_of_event),
                        end_time_of_event: new Date(data.end_time_of_event),
                        open_reservation: new Date(data.open_reservation),
                        close_reservation: new Date(data.close_reservation),
                        doors_open: new Date(data.doors_open),
                        max_seats_count: data.max_seats_count,
                        slideshows: convertArrayToFiles(data.slideshows),
                        gallery: convertArrayToFiles(data.gallery),
                        listing_image: convertArrayToFiles(data.default_image),
                        reservation_banner: convertArrayToFiles(data.banner),
                        reservation_music: convertArrayToFiles(data.music),
                        artists: convertListToListOfKeys(data.artists, 'id'),
                        varieties: data.varieties
                    };
                    this.setState({
                        updatingId: data.id,
                        artists,
                        eventPlaces,
                        inputs: values,
                        loading: false
                    })
                }
            }catch (e){
                console.log(e)
                //navigate(ROUTE_ADMIN_EVENTS);
                return;
            }

        }else{
            this.setState({
                artists,
                eventPlaces,
                loading: false
            })
        }

    }

    onSubmit = async (values, actions) => {
        const {updatingId} = this.state;
        this.setState({ submitting: true });

        if(updatingId){
            await this.onUpdateRecord(values, actions);
        }else{
            await this.onAddRecord(values, actions)
        }


    }

    onAddRecord = async (values, actions) => {
        const {applicationContext, alert, service} = this.props;
        await AdminService.doCreate(service, prepareParametersData(values)).then((data) => {
            alert.success(applicationContext.translator("Event created successfully"));
            actions.resetForm();
            actions.setValues(this.defaultInputs);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    onUpdateRecord = async (values = null, actions = null) => {
        const {applicationContext, service, alert} = this.props;
        const {updatingId} = this.state;
        if(!updatingId) return;
        if(!values) values = this.refForm.values
        await AdminService.doUpdate(service, updatingId, prepareParametersData(values)).then((data) => {
            alert.success(applicationContext.translator("Event updated successfully"));
        }).catch((err) => {
            if(err.response.data.hasOwnProperty('input_errors')){
                if(actions) actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    fetchArtistsList = async () => {
        return await AdminService.list(SERVICE_ARTISTS, {
            page: 1,
            limit: 10000,
            sort_field: 'name',
            sort_dir: 'asc',
        });
    }
    fetchEventPlacesList = async () => {
        return await AdminService.list(SERVICE_EVENT_PLACES, {
            page: 1,
            limit: 10000,
            sort_field: 'id',
            sort_dir: 'desc',
        });
    }
    render() {
        const {applicationContext} = this.props;
        const {formDisabled, updatingId} = this.state;
        Yup.addMethod(Yup.object, 'uniqueProperty', function (propertyName, message) {
            return this.test('unique', message, function (value) {
                if (!value || !value[propertyName]) {
                    return true;
                }
                const { path } = this;
                const options = [...this.parent];
                const currentIndex = options.indexOf(value);

                const subOptions = options.slice(0, currentIndex);

                if (subOptions.some((option) => option[propertyName] === value[propertyName])) {
                    throw this.createError({
                        path: `${path}.${propertyName}`,
                        message,
                    });
                }
                return true;
            });
        });
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    enableReinitialize={true}
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.inputs}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Title")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        description: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Description")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        start_time_of_event: Yup.date()
                            .required(applicationContext.translator("You must select the ${path}"))
                            .label(applicationContext.translator("Start time of event")),
                        end_time_of_event: Yup.date()
                            .required(applicationContext.translator("You must select the ${path}"))
                            .label(applicationContext.translator("End time of event"))
                            .test('end_time_of_event', applicationContext.translator("The selected date must be after selected start time"), (value) => {
                                return (value > this.refForm.values.start_time_of_event)
                            }),
                        open_reservation: Yup.date()
                            .required(applicationContext.translator("You must select the ${path}"))
                            .label(applicationContext.translator("Start time of reservation")),
                        close_reservation: Yup.date()
                            .required(applicationContext.translator("You must select the ${path}"))
                            .label(applicationContext.translator("End time of reservation"))
                            .test('close_reservation', applicationContext.translator("The selected date must be after selected open reservation time"), (value) => {
                                return (value > this.refForm.values.open_reservation)
                            }),
                        doors_open: Yup.date()
                            .required(applicationContext.translator("You must select the ${path}"))
                            .label(applicationContext.translator("Time of open doors")),
                        max_seats_count: Yup.number()
                            .min(1, "It's must be bigger than 1")
                            .max(20, "It's must be smaller than 20")
                            .required(applicationContext.translator("You must enter the ${path}"))
                            .label(applicationContext.translator("Maximum seats")),
                        listing_image: Yup.array().min(1, "You must upload at least one file"),
                        reservation_banner: Yup.array().min(1, "You must upload at least one file"),
                        slideshows: Yup.array().min(1, "You must upload at least one file"),
                        artists: Yup.array().min(1, "You must choose at least one artist"),
                        varieties: Yup.array()
                            .of(
                                Yup.object().shape({
                                    name: Yup.string().required("Required"),
                                    label: Yup.string().required("Required"),
                                    price: Yup.number().required("Required"),
                                    currency: Yup.string().required("Required"),
                                    color: Yup.string().required("Required"),
                                }).uniqueProperty('name', 'Dupliate')
                            ).min(1, "You must create at least one variety for tickets")


                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue
                      }) =>
                        (
                            <Card center={true} top={"2em"}>
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <Row>
                                        <Col md={12}>
                                            <Dropdown
                                                name={"event_place_id"}
                                                value={values.event_place_id}
                                                placeholder={applicationContext.translator("Event Place")}
                                                onChange={handleChange}
                                                data={convertListToKeyValue(this.state.eventPlaces, 'id', 'title', 'key', 'label')}
                                                touched={touched}
                                                errors={errors}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputBox type={"text"}
                                                      name={"title"}
                                                      onChange={handleChange}
                                                      label={applicationContext.translator("Title")}
                                                      value={values.title}
                                                      errors={errors}
                                                      touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <DateTimePicker
                                                name={"start_time_of_event"}
                                                value={values.start_time_of_event}
                                                label={applicationContext.translator("Start time of event")}
                                                onChange={(date) => {
                                                    console.log(date)
                                                    setFieldValue("start_time_of_event", date)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <DateTimePicker
                                                name={"end_time_of_event"}
                                                value={values.end_time_of_event}
                                                label={applicationContext.translator("End time of event")}
                                                onChange={(date) => {
                                                    console.log(date)
                                                    setFieldValue("end_time_of_event", date)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <DateTimePicker
                                                name={"open_reservation"}
                                                value={values.open_reservation}
                                                label={applicationContext.translator("Start time of reservation")}
                                                onChange={(date) => {
                                                    console.log(date)
                                                    setFieldValue("open_reservation", date)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <DateTimePicker
                                                name={"close_reservation"}
                                                value={values.close_reservation}
                                                label={applicationContext.translator("End time of reservation")}
                                                onChange={(date) => {
                                                    console.log(date)
                                                    setFieldValue("close_reservation", date)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <DateTimePicker
                                                name={"doors_open"}
                                                value={values.doors_open}
                                                label={applicationContext.translator("Time of open doors")}
                                                onChange={(date) => {
                                                    console.log(date)
                                                    setFieldValue("doors_open", date)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>

                                            <InputBox type={"text"}
                                                      name={"max_seats_count"}
                                                      onlyNumber={true}
                                                      onChange={handleChange}
                                                      label={applicationContext.translator("Max Seats User Can Select")}
                                                      value={values.max_seats_count}
                                                      errors={errors}
                                                      touched={touched}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputBox as={"textarea"}
                                                      rows={2}
                                                      name={"description"}
                                                      onChange={handleChange}
                                                      label={applicationContext.translator("Description")}
                                                      value={values.description}
                                                      errors={errors}
                                                      touched={touched}
                                            />

                                        </Col>
                                        <Col md={6}>
                                            <InputBox as={"textarea"}
                                                      rows={2}
                                                      name={"owner_message"}
                                                      onChange={handleChange}
                                                      label={applicationContext.translator("Owner Hint")}
                                                      value={values.owner_message}
                                                      errors={errors}
                                                      touched={touched}
                                            />

                                        </Col>
                                        <Col md={12}>
                                            <HtmlEditor
                                                name={"policies"}
                                                onChange={(html) => setFieldValue('policies', html)}
                                                value={values.policies}
                                                label={applicationContext.translator("Policies")}
                                                margin={true}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputObject
                                                name={"varieties"}
                                                form={this.refForm}
                                                onChange={setFieldValue}
                                                value={values.varieties}
                                                elements={[
                                                    {object: "text", key: "name", label: "Code", value: "", size: 2},
                                                    {object: "text", key: "label", label: "Label", value: "", size: 2},
                                                    {object: "number", key: "price", label: "Price", value: "", size: 2},
                                                    {object: "select", key: "currency", label: "Currency", choices: [
                                                        { key: "AED", label: "AED"},
                                                        { key: "USD", label: "USD"}
                                                    ], value: "AED", size: 2},
                                                    {object: "text", key: "color", label: "Color", value: "", size: 2},
                                                ]}
                                                label={applicationContext.translator("Variety tickets")}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>

                                        <Col md={12}>
                                            <MultiUploader
                                                name={"listing_image"}
                                                fileType={["image"]}
                                                uploaded={values.listing_image}
                                                label={applicationContext.translator("Listing Image")}
                                                form={this.refForm}
                                                maxUploadCount={1}
                                                maxSize={MaxFileUploadSize}
                                                uploadToServer={true}
                                                onUpdateRecord={this.onUpdateRecord}
                                                onStartUpload={() => this.setState({formDisabled: true})}
                                                onCompleteUpload={() => this.setState({formDisabled: false})}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <MultiUploader
                                                name={"reservation_banner"}
                                                fileType={["image"]}
                                                uploaded={values.reservation_banner}
                                                label={applicationContext.translator("Reservation Banner Image")}
                                                form={this.refForm}
                                                maxUploadCount={1}
                                                maxSize={MaxFileUploadSize * 2}
                                                uploadToServer={true}
                                                onUpdateRecord={this.onUpdateRecord}
                                                onStartUpload={() => this.setState({formDisabled: true})}
                                                onCompleteUpload={() => this.setState({formDisabled: false})}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <MultiUploader
                                                name={"reservation_music"}
                                                fileType={["audio"]}
                                                uploaded={values.reservation_music}
                                                label={applicationContext.translator("Music playing in reservation page")}
                                                form={this.refForm}
                                                maxUploadCount={1}
                                                maxSize={MaxFileUploadSize * 20}
                                                uploadToServer={true}
                                                onUpdateRecord={this.onUpdateRecord}
                                                onStartUpload={() => this.setState({formDisabled: true})}
                                                onCompleteUpload={() => this.setState({formDisabled: false})}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <InputTags
                                                name={"artists"}
                                                form={this.refForm}
                                                value={values.artists}
                                                suggestions={convertListToKeyValue(this.state.artists, 'id', 'name')}
                                                label={applicationContext.translator("Artists")}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <MultiUploader
                                                name={"slideshows"}
                                                fileType={["image"]}
                                                uploaded={values.slideshows}
                                                label={applicationContext.translator("Slideshow images")}
                                                form={this.refForm}
                                                maxSize={MaxFileUploadSize * 2}
                                                uploadToServer={true}
                                                onUpdateRecord={this.onUpdateRecord}
                                                onStartUpload={() => this.setState({formDisabled: true})}
                                                onCompleteUpload={() => this.setState({formDisabled: false})}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <MultiUploader
                                                name={"gallery"}
                                                fileType={["image", "video"]}
                                                uploaded={values.gallery}
                                                label={applicationContext.translator("Event Gallery")}
                                                form={this.refForm}
                                                maxSize={MaxFileUploadSize * 2}
                                                uploadToServer={true}
                                                onUpdateRecord={this.onUpdateRecord}
                                                onStartUpload={() => this.setState({formDisabled: true})}
                                                onCompleteUpload={() => this.setState({formDisabled: false})}
                                                errors={errors}
                                                touched={touched}
                                            />
                                        </Col>
                                    </Row>




                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} disabled={formDisabled} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                            {this.state.submitting?"Submitting...":applicationContext.translator("Save")}
                                        </Button>
                                    </Form.Group>


                                </Form>
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }

}

export default withAlert()(withApplicationContext(withRouter(withAdminContext((EventFormData)))));