import styled from "styled-components";
import {Col} from "react-bootstrap";

export const Container = styled.div`
  text-align: center;
  & h1{
    font-size: 1.2em;
    font-weight: 500;
  }
  & p{
    font-size: 1em;
    font-weight: 400;
  }
  
  line-height: 1.7em;
  & svg{
    color: #4caf50;
    font-size: 4em;
    margin-bottom: 0.3em;
  }
`
