import StorageService from "../../services/StorageService";
import {addSecondsToDate, parseErrorMessage, uuid} from "../../helpers";
import CommonService from "../../services/CommonService";
export default class Cart {
    user;
    cart;
    event;
    key;
    alert;
    constructor(event, user, alert) {
        this.event = event;
        this.alert = alert;
        this.user = user;
        if(event.basket_id){
            StorageService.set("cart", event.basket_id);
        }

    }
    getCartId = () => {
        let idValue = StorageService.get("cart");
        if(idValue) {
            return idValue;
        }
        return null;
    }
    fetchCartFromServer = async () => {
        if(!this.getCartId()) return;
        let result = await CommonService.doGet(`/basket/summary`, {
            basket_id: this.getCartId()
        }).catch((e) => {
            let response = e.response.data;
            if(e.response.status === 401){
                this.reload();
            }else{
                if(response.hasOwnProperty('input_errors') && response['input_errors'].hasOwnProperty('basket_id')){
                    this.reload();
                }else{
                    this.alert.error(parseErrorMessage(e));
                }
            }


        });
        if(result)
            this.cart = result;
        this.id = this.getCartId();
    }
    clear = async () => {
        let result = await CommonService.doPost(`/basket/delete`, {
            basket_id: this.getCartId()
        }).catch((e) => {
            this.alert.error(parseErrorMessage(e));
        });
        if(result)
            this.reload()

    }
    reload = () => {
        StorageService.remove("cart");
        this.cart = null;
    }
    refresh = () => {
        let packages = {};
        this.event.varieties.map((variety) => packages[variety.id] = variety);
        if(this.user){
            this.reload();
            this.event['reserved_seats'].map((seatRow) => {
                if(seatRow['user_id'] === this.user.id){
                    let packageRow = packages[seatRow.variety_id];
                    let row = seatRow['seat'].split("-")[0];
                    let cell = seatRow['seat'].split("-")[1];
                    this.add({
                        id: seatRow['seat'],
                        row,
                        seat: cell,
                        price: parseFloat(packageRow.price),
                        currency: packageRow.currency,
                        packageName: packageRow.label,
                        packageColor: packageRow.color,
                    }, seatRow['expired_at']);
                }
            });
        }
        return this.store();
    }
    add = async (item, expire = null) => {
        let result = await CommonService.doPost(`/basket/add-item`, {
            basket_id: this.getCartId(),
            event_id: this.event.id,
            row: item.row,
            place: parseInt(item.place)
        }).catch((e) => {
            this.alert.error(parseErrorMessage(e));
        });
        if(result){
            StorageService.set("cart", result.id);
            this.cart = result;
        }
        return this.cart;
    }
    getIds = () => {
        let selected = [];
        if(!this.cart) return selected;
        this.cart.items.map((row) => {
            selected.push(row.seat);
        })
        return selected;
    }
    items = () => {
        if(!this.cart) return [];
        return this.cart.items;
    }
    count = () => {
        if(!this.cart) return 0;
        return this.cart.summary['items_count'];
    }
    total = () => {
        if(!this.cart) return 0;
        return this.cart.summary['total'];
    }
    currency = () => {
        if(!this.cart) return "";
        return this.cart.summary['currency'];
    }
    expire = () => {
        if(!this.cart) return null;
        return new Date(this.cart['expired_at']);
    }
    delete = async (row, place) => {
        if(!this.getCartId()) return;
        let result = await CommonService.doPost(`/basket/delete-item`, {
            basket_id: this.getCartId(),
            event_id: this.event.id,
            row,
            place: parseInt(place)
        }).catch((e) => {
            this.alert.error(parseErrorMessage(e));
        });
        if(result){
            this.cart = result;
            if(this.count() === 0){
                await this.clear();
            }
        }

    }
}