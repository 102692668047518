import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {withAdminContext} from "../../contexts/AdminContext";
import {BaseContainer, Container, SideBar, Main, HeaderLogoName, Header, HeaderLogo, HeaderMenuIcon,
    MenuItems, MenuItem, MenuItemProfile, ProfileAvatar, ProfileInfo, Body, MenuItemLink, LogoutButton, HeaderWrapper, MenuItemCategory, MenuItemCounter} from "./style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {
    ROUTE_ADMIN_ADD_EVENT_PLACE,
    ROUTE_ADMIN_ALL_MEMBERS, ROUTE_ADMIN_ARTISTS, ROUTE_ADMIN_EVENT_PLACES, ROUTE_ADMIN_EVENTS,
    ROUTE_ADMIN_LOGIN, ROUTE_ADMIN_OVERVIEW, ROUTE_ADMIN_PAYMENTS, ROUTE_ADMIN_TICKETS
} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";
import Loading from "../../components/ui/Loading";
import Button from "../../components/ui/Button";
import Avatar from "../../components/icons/Avatar";


class LoggedIn extends Component {
    constructor(props) {
        super(props);
        const {applicationContext} = this.props;
        this.state = {
            menu: "opened",
            items: []
        }
    }
    componentDidMount() {
        const {applicationContext, adminContext} = this.props;
        this.handleWindowSize();
        this.setState({
            items: [
                {category: applicationContext.translator("Navigation")},
                {title: applicationContext.translator("Overview"), route: ROUTE_ADMIN_OVERVIEW},
                {category: ""},
                {title: applicationContext.translator("Event Places"), route: ROUTE_ADMIN_EVENT_PLACES},
                {title: applicationContext.translator("Artists"), route: ROUTE_ADMIN_ARTISTS},
                {title: applicationContext.translator("Events"), route: ROUTE_ADMIN_EVENTS},
                {title: applicationContext.translator("Tickets"), route: ROUTE_ADMIN_TICKETS},
                {title: applicationContext.translator("Payments"), route: ROUTE_ADMIN_PAYMENTS},
                {title: applicationContext.translator("All Members A/Z"), route: ROUTE_ADMIN_ALL_MEMBERS},

            ]
        }, () => {
            window.addEventListener('resize', this.handleWindowSize, true);
        })

    }
    handleWindowSize = (e) => {
        this.setState({
            menu: (window.innerWidth <= 700)?"closed":"opened"
        })

    }
    onMenuBar = (e) => {
        this.setState({
            menu: (this.state.menu === "opened")?"closed":"opened"
        });
    }
    onMenuClick = (route) => {
        const {location, navigate} = this.props;
        if(location.pathname === route)
            navigate(0);

    }

    onLogout = (e) => {
        e.preventDefault();
        const {applicationContext} = this.props;
        let component = (applicationContext.translator("Are you sure to logout your account?"));
        let footer = (
            <React.Fragment>
                <Button color={"success"} onClick={this.handleLogout} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} onClick={applicationContext.onCloseConfirmPopup} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Log Out", component, footer)

    }
    handleLogout = (e) => {
        const {applicationContext, adminContext, navigate} = this.props;
        adminContext.handleLogout(() => {
            applicationContext.onCloseConfirmPopup();
            navigate(ROUTE_ADMIN_LOGIN);
        })
    }

    render() {
        const {adminContext, applicationContext, children, location, loading} = this.props;
        return (
            <Container>
                <BaseContainer>
                    <SideBar className={this.state.menu}>
                        <HeaderLogo>
                            <HeaderLogoName>
                                <img src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}/>
                                <div>
                                    {applicationContext.translator("Lotto 6-46")}
                                    <br/>
                                    <small>{applicationContext.translator("Admin Panel")}</small>
                                </div>
                            </HeaderLogoName>
                            <HeaderMenuIcon onClick={this.onMenuBar}>
                                <FontAwesomeIcon icon={"bars"} />
                            </HeaderMenuIcon>
                        </HeaderLogo>
                        <MenuItems>
                            <MenuItemProfile>
                                <ProfileAvatar>
                                    <Avatar width={"3.5em"} height={"3.5em"}/>
                                </ProfileAvatar>
                                <ProfileInfo>
                                    {adminContext.state.userInfo['full_name']}<br/>
                                    <span>{adminContext.state.userInfo['role'][0]}</span>
                                </ProfileInfo>
                                <LogoutButton onClick={this.onLogout}>
                                    <FontAwesomeIcon icon={"power-off"} />
                                </LogoutButton>
                            </MenuItemProfile>
                            {this.state.items.map((menu, index) => {
                                if(menu.hasOwnProperty('category')){
                                    return (
                                        <MenuItemCategory>
                                            {menu.category}
                                        </MenuItemCategory>
                                    )
                                }else{
                                    return (
                                        <MenuItem active={location.pathname === menu.route}>
                                            <NavLink to={menu.route} onClick={(e) => this.onMenuClick(menu.route)}>
                                                <MenuItemLink>
                                                    <div>{menu.title}</div>
                                                    {menu.hasOwnProperty('counter') && adminContext.state.counter.hasOwnProperty(menu.counter) && adminContext.state.counter[menu.counter] > 0 &&
                                                    <MenuItemCounter>{adminContext.state.counter[menu.counter]}</MenuItemCounter>}

                                                </MenuItemLink>
                                            </NavLink>
                                        </MenuItem>
                                    )
                                }
                            })}
                        </MenuItems>
                    </SideBar>
                    <Main sidebar={this.state.menu}>
                        <Header>
                            {this.state.menu === "closed" &&
                            <HeaderMenuIcon center={true} onClick={this.onMenuBar}>
                                <FontAwesomeIcon icon={"bars"} />
                            </HeaderMenuIcon>
                            }
                            <HeaderWrapper>

                            </HeaderWrapper>

                        </Header>
                        <Body>
                            {loading?<Loading animation={true} transparent={true} />:children}
                        </Body>
                    </Main>
                </BaseContainer>

            </Container>
        );
    }
}

export default withApplicationContext(withAdminContext(withRouter(LoggedIn)));