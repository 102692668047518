import React, {Component} from 'react';
import {
    DateTime,
    EventCardContainer,
    EventCardImage,
    EventDetails,
    EventPlace, EventTicketSeatRow, EventTicketSeatRowDetails, EventTicketSeatRowIcon, EventTicketSeatRowPrice,
    EventTicketSeats,
    EventTime,
    EventTitle
} from "../style";
import {withCheckoutContext} from "./CheckoutContext"
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock, faHourglass} from "@fortawesome/free-regular-svg-icons"
import {faTicket} from "@fortawesome/free-solid-svg-icons"
import moment from "jalali-moment";
import {numberWithCommas} from "../../../helpers";
class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: []
        }
    }

    componentDidMount() {
        const {checkoutContext} = this.props;
        const {cart} = checkoutContext.state;
        let tickets = [];
        cart.items.map((ticket) => {
            let key = ticket.row+"-"+ticket.variety.name;
            if(tickets.hasOwnProperty(key)) {
                tickets[key].seats.push(ticket.place)
                tickets[key].price += ticket['float_price'];
            }else{
                tickets[key] = {
                    row: ticket.row,
                    currency: ticket.currency,
                    title: ticket.variety.label,
                    color: ticket.variety.color,
                    seats: [ticket.place],
                    price: ticket['float_price']
                }
            }
        })
        this.setState({
            tickets
        })
    }

    renderImage = () => {
        const {checkoutContext} = this.props;
        const {cart} = checkoutContext.state;
        if(cart.event.slideshow)
            return cart.event.slideshow.url;
        return cart.event['default_image'].url;
    }

    render() {
        const {tickets} = this.state;
        const {checkoutContext, applicationContext} = this.props;
        const {cart} = checkoutContext.state;
        const {event} = cart;
        return (
            <EventCardContainer>
                <EventCardImage image={this.renderImage()} />
                <EventDetails>
                    <EventTitle>{event.title}</EventTitle>
                    <EventPlace>{event.place.title}</EventPlace>
                    <EventTime>
                        <DateTime>
                            <FontAwesomeIcon icon={faCalendar} />
                            {moment(event['date_of_event'], 'YYYY-MM-DD').format('DD MMMM YYYY')}
                        </DateTime>
                        <DateTime>
                            <FontAwesomeIcon icon={faClock} />
                            The show starts at {moment(new Date(event['start_time_of_event']), 'YYYY/MM/DD').format('HH:mm')}, Doors open at {moment(new Date(event['doors_open']), 'YYYY/MM/DD').format('HH:mm')}
                        </DateTime>
                        <DateTime>
                            <FontAwesomeIcon icon={faHourglass} />
                            Duration: {event['duration']}
                        </DateTime>

                    </EventTime>
                    <EventTicketSeats>
                        {Object.keys(tickets).map((ticketRow, index) => {
                            return (
                                <EventTicketSeatRow key={index}>
                                    <EventTicketSeatRowIcon color={tickets[ticketRow].color}>
                                        <FontAwesomeIcon icon={faTicket} />
                                    </EventTicketSeatRowIcon>
                                    <EventTicketSeatRowDetails>
                                        <h1>{tickets[ticketRow].title}</h1>
                                        <h3><span>{applicationContext.translator("Row")}:</span> {tickets[ticketRow].row} <span>{applicationContext.translator("Seat")}:</span> {tickets[ticketRow].seats.join(', ')}</h3>
                                    </EventTicketSeatRowDetails>
                                    <EventTicketSeatRowPrice>
                                        <h1>x{tickets[ticketRow].seats.length}</h1>
                                        <h3>{numberWithCommas(tickets[ticketRow].price, false, ",")} {tickets[ticketRow].currency}</h3>
                                    </EventTicketSeatRowPrice>
                                </EventTicketSeatRow>
                            )
                        })}
                    </EventTicketSeats>

                </EventDetails>
            </EventCardContainer>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withCheckoutContext(EventCard))));