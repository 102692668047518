import styled from "styled-components";
import {Row, Col, Form} from "react-bootstrap";
export const Container = styled.div`
  padding: 1em 0;
`
export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  ${(props) => props.padding && `
        padding: ${props.padding} !important;
  `}
  
  
`
export const InputCol = styled(Col)`
  position: relative;
`
export const Wrapper = styled.div`
  position: relative;
  ${(props) => props.padding && ` padding:${props.padding} !important; `}
`
export const InputContainer = styled(Form.Control)`
  padding: ${(props) => !props.padding?`0.8em 1.3em;`:`${props.padding};`}
  visibility: hidden;
  width: 1px;
  height: 1px;
  
`
export const FileSelector = styled.div`
  position: relative;
  min-width: 120px;
  width: 120px;
  height: 120px;
  background: var(--color-shadow-08);
  border-radius: var(--border-radius-2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out .3s;
  ${(props) => !props.disabled && `
      cursor: pointer;
      ${props.isInvalid && `
        background: rgba(220, 53, 69, .2);
        & *{
          color: #dc3545 !important;
        }
      `}
      &:hover{
        & .selector *{
          opacity: 1;
          transition: all ease-in-out .3s;
        }
        transition: all ease-in-out .3s;
        background: var(--color-shadow-1);
      }
  `}
  ${(props) => props.preview && `
    background-image: url(${props.preview});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    & .selector *{
      opacity: 0;
      transition: all ease-in-out .3s;
    }
  `}
`
export const FileSelectorWrapper = styled.div`
  
`
export const AddIcon = styled.div`
  text-align:center;
  & svg{
    width: 20px;
    height: 20px;
    color: var(--color-shadow-2);
  }
  & .uploading{
    margin-top: .3em;
    color: var(--color-shadow-2);
    font-size: .8em;
  }
`
export const Label = styled.div`
  font-weight: 500;
  color: var(--form-element-text-color);
  color: var(--color-shadow-3);
  font-size: .8em;
  margin-bottom: .5em;
`
export const FileUploadWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
export const ToolsContainer = styled.div`
  width: fit-content;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FileInfoContainer = styled.div`
  width: 100%;
  padding: 0 1em;
  font-size: .9em;
  font-weight: 400;
  & h1{
    font-size: .9em;
    font-weight: 400;
    margin-bottom: 0.5em;
  }
  & p{
    margin-bottom: 0.3em;
  }
  & b{
    margin-right: .3em;
  }
  
  color: var(--color-shadow-5);
`
export const ActionIcon = styled.div`
    width: 25px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in-out .3s;
      & svg{
        color: var(--color-shadow-1);
        width: 16px;
        height: 16px;
      }
    ${(props) => !props.disabled && `
        cursor: pointer;
        & svg{
            color: var(--color-shadow-3);
        }
        &:hover{
          & svg{
            color: var(--color-shadow-7);
            transition: all ease-in-out .3s;
          }
        }
    
    `}
    
    
`
export const Hint = styled.div`
    color: var(--color-shadow-4);
    margin: 0em 0.3em;
    font-size: .9em;
    ${(props) => props.error && `color: #dc3545;`}
`