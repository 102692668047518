import React, {Component} from 'react';
import * as Yup from "yup";
import {Form} from "react-bootstrap";
import {FormTitle, SubmitError} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from "prop-types";
import Service from "../../../services/Service";
import TokenService from "../../../services/TokenService";
import {parseErrorMessage} from "../../../helpers";
import {withAlert} from "react-alert";
import {withAccountContext} from "../../../contexts/AccountContext";

class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errorText: null,
            submitting: false
        }
    }
    handleSubmit = async (values, actions) => {
        const {applicationContext, accountContext, alert, onCallback, popup} = this.props;
        this.setState({ submitting: true, errorText: null });
        try {
            const token  = await Service.login(values.email, values.password);
            TokenService.updateLocalAccessToken(token.auth);
            await Service.getUserBoard().then((data) => {
                accountContext.updateUserInfo(data, onCallback);
            });

        } catch (err) {
            if(popup){
                this.setState({
                    submitting: false,
                    errorText: applicationContext.translator(parseErrorMessage(err))
                });
            }else{
                this.setState({ submitting: false });
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }
    }
    render() {
        const {errorText} = this.state;
        const {applicationContext, title} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={this.state}
                validationSchema={Yup.object().shape({
                    email: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("E-Mail Address")),
                    password: Yup.string().required(applicationContext.translator("You must enter your ${path}")).label(applicationContext.translator("Password")).min(8, applicationContext.translator("Password must be minimum 8 characters"))
                })}
                onSubmit={this.handleSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>{title??applicationContext.translator("Enter username and password.")}</FormTitle>
                            <InputBox type="text"
                                      name={"email"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("E-Mail Address or mobile")}
                                      value={values.email}
                                      save={"off"}
                                      focus={true}
                                      errors={errors}
                                      touched={touched}
                            />
                            <InputBox type={this.state.showPassword?"text":"password"}
                                      name={"password"}
                                      onChange={handleChange}
                                      placeholder={applicationContext.translator("Password")}
                                      value={values.password}
                                      save={"off"}
                                      errors={errors}
                                      touched={touched}
                            />
                            {errorText && <SubmitError>{errorText}</SubmitError>}
                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Log In")}</Button>
                            </Form.Group>
                        </Form>
                    )}
            </Formik>
        );
    }
}
LoginForm.propTypes = {
    onCallback: PropTypes.func.isRequired,
    title: PropTypes.string,
    popup: PropTypes.bool
}
export default withAlert()(withApplicationContext(withAccountContext(LoginForm)));