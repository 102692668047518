import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Col} from "react-bootstrap";

export const AlertContainer = styled.div`
  background-color: var(--color-background-alert);
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
  ${(props) => props.type === "error" && `
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  `}
  ${(props) => props.type === "success" && `
    color: #0f5132;
    background-color: #d1e7dd;
    border-color: #badbcc;
  `}
  ${(props) => props.type === "warning" && `
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
  `}
  padding: 1.2em 1.5em;
  border-radius: var(--border-radius-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.1);
  font-family: "Poppins", "IranSANS";
  min-width: 400px;
  box-sizing: border-box;
  font-size: .8em;
  margin: 1em;
`
export const AlertWrapper = styled.div`
`
export const Message = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: break-spaces;
    & svg{
      width: 20px;
      height: 20px;
      margin-right: 10px;
      color: #055160;
      fill: #055160;
      ${(props) => props.type === "error" && `
        color: #842029;
        fill: #842029;
      `}
        ${(props) => props.type === "success" && `
        color: #0f5132;
        fill: #0f5132;
      `}
    }
`
export const Close = styled.button`
  width: 30px;
  background: no-repeat;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg{
    width: 16px;
    height: 16px;
    color: rgba(0,0,0,.5);
    margin-left: 10px;
    
  }
`