import styled from "styled-components";
import {Col} from "react-bootstrap";
export const Container = styled.div`
  padding-bottom: 2em;
  @media screen and (max-width: 700px) {
      padding: 10px 30px;
    }
`
export const SlideshowWrapper = styled.div`
    
  
`;
export const SlideshowContainer = styled.div`
  & .default-nav{
    width: 60px;
    height: 60px;
    background-color: var(--color-background);
    color: var(--color-text);
    border-radius: 50%;
    box-shadow: 0 4px 16px -4px var(--color-shadow-2), 0 0 2px var(--color-shadow-2);
    padding: 0;
    &:hover{
      background-color: var(--color-background);
    }
    & svg{
      width: 18px;
      height: 18px;
      fill: var(--color-text);
    }
  }
  & .nav:last-of-type{
    right: -30px;
  }
  & .nav:first-of-type{
    left: -30px;
  }
  ${(props) => !props.fixedFooter && `
    & .nav{
      top: calc(50% - 90px);
    }
  `}
  
  
`;

export const SlideshowItem = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  ${(props) => props.height?`height: ${props.height}px;`:`height: 550px;`}
  border-radius: var(--border-radius-2);;
  background-image: url('${(props) => props.image}');
  background-position: center center;
  transition: all ease-in-out .3s;
    &:after{
        content: ' ';
        position: absolute;
        width: 100%;
        opacity: 0;
        transition: all ease-in-out .3s;
        border-radius: var(--border-radius-3);
        height: 100%;
        background: var(--color-shadow-2);
        z-index: 999999;
        top: 0;
    }
    &:hover{
      &:after{
        transition: all ease-in-out .3s;
        opacity: 1;
      }  
    }
  @media screen and (max-width: 700px) {
    background-size: cover;
    height: 180px;
  }
`;
export const SlideshowSubItem = styled.div`
  padding: 1em;
  @media screen and (max-width: 700px) {
    padding: 1em .3em;
  }
  background: var(--color-primary);
  color: #fff;
  border-radius: var(--border-radius-2);;
  margin: .2em 0;
  & .flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const BigText = styled.div`
    
  font-size: 1.1em;
  font-weight: 600;
  ${(props) => props.uppercase && `text-transform: uppercase;`}
`
export const SmallText = styled.div`
  font-size: .9em;
`
export const Wrapper = styled.div`
  ${(props) => !props.noBorder && `border-right: 1px solid var(--color-shadow-2);`}
  text-align: center;
  height: 100%;
  @media screen and (max-width: 700px) {
    border: 0;
    text-align: center;
  }
  padding: .5em 1em;
`
export const SelectTicketButtonContainer = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  & button{
    font-size: 1em;
    width: 90%;
    border-radius: 8px
  }

`
export const Volume = styled.div`
  min-width: 2.5em;
  text-align: center;
  margin: 0px .5em;
    background: rgba(255,255,255,1);
    color: var(--color-primary);
    padding: 0.5em;
    border-radius: var(--border-radius-2);
    &:hover{
     background: rgba(255,255,255,.8);
        color: var(--color-primary);
    }
  & svg{
    cursor: pointer;
    transition: all ease-in-out .2s;
    &:hover{
      transition: all ease-in-out .2s;
    }
  }
`