import React, {Component} from 'react';
import {SeatCell} from "./style";
import StageMapPopup from "./StageMapPopup";
import {withStageMapContext} from "./StageMapContext";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {withAccountContext} from "../../../contexts/AccountContext";
import LoginPopup from "../../../scenes/Login/Popup/LoginPopup";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withAlert} from "react-alert";
import {withCartContext} from "../../../scenes/Cart/CartContext";
import {Spinner} from "react-bootstrap";
import DesktopUsePopup from "../../../scenes/Cart/DesktopUsePopup";
import MaximumPopup from "./MaximumPopup";

class Seat extends Component {
    constructor(props) {
        super(props);
        const {seatCell, seatRow, context} = props;
        this.state = {
            seatRow,
            seatCell,
            name: seatRow.label + "-" + seatCell,
            isNotEmpty: (seatCell > 0),
            disabled: context.closeReservation,
            isReserved: false,
            isSold: false
        }
    }

    componentDidMount() {
        this.checkReserve();
        this.checkSold();
    }
    checkReserve = () => {
        const {name, isNotEmpty, disabled} = this.state;
        const {context} = this.props;
        const {reservedSeats} = context;
        if(isNotEmpty){
            let isReserved = false;
            if(!this.isSelected())
                isReserved = reservedSeats.includes(name);

            this.setState({
                isReserved,
                disabled: (!disabled && isReserved)?true:disabled
            })
        }
    }
    checkSold = () => {
        const {name, isNotEmpty, disabled} = this.state;
        const {context} = this.props;
        const {soldSeats} = context;
        if(isNotEmpty){
            let isSold = false;
            if(!this.isSelected())
                isSold = soldSeats.includes(name);

            this.setState({
                isSold,
                disabled: (!disabled && isSold)?true:disabled
            })
        }
    }

    onMouseMoveToCell = (e) => {
        const {name, disabled} = this.state;
        const {context} = this.props;
        if(disabled) return;
        context.onUpdateState({
            cellHover: name
        })
    }
    onMouseOutFromCell = (e) => {
        const {context} = this.props;
        context.onUpdateState({
            cellHover: null
        })
    }
    handleReserve = async () => {
        const {context, applicationContext, alert} = this.props;
        const {seatCell, seatRow, name} = this.state;
        this.setState({ submitting: true });
        applicationContext.onClosePopup();
        let item = {
            id: name,
            row: seatRow.label,
            place: seatCell,
            packageId: context.packages[seatRow.package].id,
            price: parseFloat(context.packages[seatRow.package].price),
            currency: context.packages[seatRow.package].currency,
            packageName: context.packages[seatRow.package].label,
            packageColor: context.packages[seatRow.package].color,
        };
        if(!this.isSelected()) {
            if(context.selectedSeats.length >= context.maxSeatsSelect){
                applicationContext.onOpenConfirmPopup(null,
                    <MaximumPopup handleClose={(e) => applicationContext.onCloseConfirmPopup(e, () => {})}/>,
                    null,
                    (e) => applicationContext.onCloseConfirmPopup(e, () => {}),
                    "sm",
                    true,
                    null
                );
                //alert.error(`We apologise, but you can only book up to ${context.maxSeatsSelect} tickets at a time in any of the reserved categories. If you wish to purchase more than eight tickets at once, please make your booking by telephone.`)
                return;
            }
            context.onClickSeat(item);
        }else{
            context.onClickRemoveSeat(item)
        }
    }
    onSeatClicked = async (e) => {
        const {disabled, isReserved, isSold} = this.state;
        const {cartContext} = this.props;
        if(disabled || isReserved || isSold) return;
        await cartContext.onCheckAuthenticated(this.handleReserve);
    }
    isSelected = () => {
        const {name} = this.state;
        const {context} = this.props;
        const {selectedSeats} = context;
        return selectedSeats.includes(name);
    }
    renderBackground = () => {
        const {context} = this.props;
        const {isNotEmpty, seatRow, isSold, isReserved} = this.state;
        const {packages} = context;
        if(isNotEmpty){
            if(isSold)
                return "#44536a";
            if(isReserved)
                return "#44536a";
            if(packages && [seatRow.package])
                return packages[seatRow.package]['color'];
        }
        return false;
    }
    render() {
        const {isNotEmpty, seatCell, seatRow, disabled, name} = this.state;
        const {context} = this.props;
        const {cellSize} = context;
        return (
            (isNotEmpty)?(<SeatCell
                size={cellSize}
                bg={this.renderBackground()}
                noBorder={seatCell === 0}
                selected={this.isSelected()}
                onMouseOut={this.onMouseOutFromCell}
                onMouseMove={this.onMouseMoveToCell}
                onClick={this.onSeatClicked}
                disabled={disabled}
            >
                {this.renderName()}
                <StageMapPopup name={name} seatRow={seatRow}/>
            </SeatCell>):(null)
        );
    }
    renderName = () => {
        const {isNotEmpty, seatCell, name} = this.state;
        let submitting = this.props.cartContext.state.submitting;
        if(isNotEmpty){
            if(submitting && submitting === name)
                return <Spinner animation="border" variant="light" size={"sm"} />;
            if(this.isSelected()){
                return <FontAwesomeIcon icon={faCheck} />
            }else{
                return seatCell;
            }
        }
        return "";
    }
}
Seat.propTypes = {
    seatCell: PropTypes.number,
    seatRow: PropTypes.object
}
export default withAlert()(withApplicationContext(withAccountContext(withStageMapContext(withCartContext(Seat)))));