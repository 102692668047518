import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import * as Yup from "yup";
import Card from "../../../components/ui/Card";
import {Form} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import {Formik} from "formik";
import AdminService from "../../../services/AdminService";
import Dropdown from "../../../components/ui/Dropdown";
import Uploader from "../../../components/ui/Uploader";
import {file, formatBytes, getRandomInt, parseErrorMessage, prepareParametersData} from "../../../helpers";
import {ROUTE_ADMIN_ARTISTS, ROUTE_ADMIN_EVENT_PLACES} from "../../../routes/routes";
import MultiUploader from "../../../components/ui/Uploader/MultiUploader";
const MaxFileUploadSize = 2048576;
class ArtistFormData extends Component {
    refForm = React.createRef();
    defaultInputs = {
        type: "ARTIST",
        name: "",
        about: "",
        avatar: []
    }
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            updatingId: null,
            inputs: this.defaultInputs,
            formDisabled: false
        }

    }
    componentDidMount = async () => {
        const {navigate, params, service} = this.props;
        const {id} = params;
        if(id){
            try {
                let data = await AdminService.get(service, id);
                if (data) {
                    let avatar = [];
                    if(data['avatar'])
                        avatar.push(file(data['avatar']))

                    this.setState({
                        updatingId: data.id,
                        inputs: {
                            type: data.type,
                            name: data.name,
                            about: data.about,
                            avatar
                        },
                        loading: false
                    })
                }
            }catch (e){
                navigate(ROUTE_ADMIN_ARTISTS);
                return;
            }

        }else{
            this.setState({ loading: false })
        }

    }

    onSubmit = async (values, actions) => {
        const {updatingId} = this.state;
        this.setState({ submitting: true });

        if(updatingId){
            await this.onUpdateRecord(values, actions);
        }else{
            await this.onAddRecord(values, actions)
        }


    }
    onAddRecord = async (values, actions) => {
        const {applicationContext, alert, service} = this.props;
        await AdminService.doCreate(service, prepareParametersData(values)).then((data) => {
            alert.success(applicationContext.translator("Artist created successfully"));
            actions.resetForm();
            actions.setValues(this.defaultInputs);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    onUpdateRecord = async (values = null, actions = null) => {
        const {applicationContext, service, alert} = this.props;
        const {updatingId} = this.state;
        if(!updatingId) return;
        if(!values) values = this.refForm.values
        await AdminService.doUpdate(service, updatingId, prepareParametersData(values)).then((data) => {
            alert.success(applicationContext.translator("Artist updated successfully"));
        }).catch((err) => {
            if(err.response.data.hasOwnProperty('input_errors')){
                if(actions) actions.setErrors(err.response.data['input_errors'])
            }else{
                alert.error(applicationContext.translator(parseErrorMessage(err)));
            }

        }).finally(() => {
            this.setState({ submitting: false });
        })
    }
    render() {
        const {applicationContext} = this.props;
        const {formDisabled, updatingId} = this.state;
        return (
            <LoggedIn loading={this.state.loading}>
                <Formik
                    enableReinitialize={true}
                    innerRef={(ref) => this.refForm = ref}
                    initialValues={this.state.inputs}
                    validationSchema={Yup.object().shape({
                        type: Yup.string().required(applicationContext.translator("You must select the ${path}")).label(applicationContext.translator("Artist type")),
                        name: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("Name")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                        avatar: Yup.array().min(1, "You must upload at least one file")


                    })}
                    onSubmit={this.onSubmit}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit
                      }) =>
                        (
                            <Card maxWidth={800} center={true} top={"2em"}>
                                <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                    <Dropdown
                                        name={"type"}
                                        value={values.type}
                                        onChange={handleChange}
                                        line={true}
                                        label={applicationContext.translator("Artist type")}
                                        data={[
                                            { key: "ARTIST", label: "Artist"}
                                        ]}
                                        errors={errors}
                                        touched={touched}
                                    />
                                    <InputBox type={"text"}
                                              name={"name"}
                                              onChange={handleChange}
                                              line={true}
                                              label={applicationContext.translator("Name")}
                                              value={values.name}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <InputBox as={"textarea"}
                                              rows={6}
                                              name={"about"}
                                              line={true}
                                              onChange={handleChange}
                                              label={applicationContext.translator("About")}
                                              value={values.about}
                                              errors={errors}
                                              touched={touched}
                                    />
                                    <MultiUploader
                                        name={"avatar"}
                                        fileType={["image"]}
                                        uploaded={values.avatar}
                                        form={this.refForm}
                                        maxSize={MaxFileUploadSize}
                                        maxUploadCount={1}
                                        uploadToServer={true}
                                        onUpdateRecord={this.onUpdateRecord}
                                        onStartUpload={() => this.setState({formDisabled: true})}
                                        onCompleteUpload={() => this.setState({formDisabled: false})}
                                        errors={errors}
                                        touched={touched}
                                    />

                                    <Form.Group className={"text-center"}>
                                        <Button color={"primary"} disabled={formDisabled} fullWidth={true} size={3} submitting={this.state.submitting} type={"submit"}>
                                            {this.state.submitting?"Submitting...":applicationContext.translator("Save")}
                                        </Button>
                                    </Form.Group>


                                </Form>
                            </Card>
                        )}
                </Formik>
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext((ArtistFormData)))));