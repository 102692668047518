import React, {Component} from 'react';
import {
    Container, SwiperSlide, Wrapper
} from "./style";
import { Swiper as ReactSwiper } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {getRandomInt} from "../../../helpers";
class Swiper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: this.props.events
        }
    }

    render() {
        const {events} = this.state;
        return (
            <Container>
                <Wrapper>
                    <ReactSwiper
                        modules={[Autoplay, Pagination, Scrollbar, A11y]}
                        spaceBetween={5}
                        slidesPerView={3}
                        speed={1000 * getRandomInt(5,8)}
                        loop={true}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: true
                        }}
                    >
                        {events.length > 0 && events.map((event, eventIndex) => {
                            return (
                                <SwiperSlide key={eventIndex}>
                                    <img src={event.url} alt={event.title}/>
                                </SwiperSlide>
                            );
                        })}
                    </ReactSwiper>
                </Wrapper>
            </Container>
        );
    }
}

export default Swiper;