import styled from "styled-components";
import {Col, Container} from "react-bootstrap";

export const Wrapper = styled(Container)`
  position: relative;
`;
export const SupportRowWrapper = styled.div`
  width: 100%;
  text-align: center;
  @media screen and (max-width: 700px) {
    margin-bottom: 1em;
  }
`
export const SupportRowFooterContainer = styled.div`
  margin-top: 5em;
  margin-bottom: 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 700px) {
    padding: 1em 0em;
    flex-wrap: wrap;
    width: 100%;
  }
`
export const SupportFooterContainer = styled.div`
  text-align: center;
  margin-bottom: 3em;
  ${(props) => props.margin && `margin: ${props.margin};`}
  @media screen and (max-width: 700px) {
    padding: 1em 0em;
    width: 100%;
  }
`

export const Title = styled.h1`
  font-size: 1em;
  text-align: center;
  margin-bottom: 1em;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  @media screen and (max-width: 700px) {
    font-size: .9em;
    text-align: center;
  }
`
export const Text = styled.p`
    margin: .1em 0;
  font-size: .9em;
  
  ${(props) => props.primary && `color: var(--color-primary);`}
  ${(props) => props.small && `font-size: .8em;`}
  ${(props) => props.gray && `color: var(--color-shadow-5);`}
  ${(props) => props.bold && `font-weight: 500;`}
  ${(props) => props.link && `
    color: var(--color-primary);
    cursor: pointer;
    transition: all ease-in-out .2s;
    &:hover{
      color: var(--color-shadow-8);
      transition: all ease-in-out .2s;
    }
  `}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  @media screen and (max-width: 700px) {
    text-align: center;
  }
`
export const FooterCopyrightContainer = styled.div`
  width: 100%;
  padding: 2em 2em 0.3em 2em;
  text-align: center;
  background-image: url('/assets/images/footer.png');
  background-size: cover;
  background-position: center;
  background-color: var(--color-shadow-4);
  position: relative;
  color: var(--color-secondary-on);
  
`
export const LogoWrapper = styled.div`
  min-height: 400px;
  position: relative;
`
export const Logo = styled.img`
  height: 80px;
  position: absolute;
  right: 2em;
`
export const CopyrightText = styled.p`
  font-size: 0.8em;
  line-height: 1.7em;
  direction: ltr;
  margin-bottom: .3em;
`
export const PaymentWrapper = styled.div`
  ${(props) => !props.line && `max-width: 220px;`}
  ${(props) => props.line && `
    display: flex;
    align-items: center;
    justify-content: space-between;
    & img{
      height: 40px !important;
    }
    & p{
      text-align: center;
      margin: 0 0.5em;
      width: 100%;
      white-space: nowrap;
    }
    @media screen and (max-width: 700px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  `}
  margin: 0 auto;
`
export const PaymentContainer = styled.div`
    display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .5em;
  & img{
    height: 50px;
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-1);
    padding: .3em;
    margin: .3em;
  }
  @media screen and (max-width: 700px) {
    justify-content: center;
    & img {
      width: 50px;
      height: 30px;
    }
  }
`
export const SocialContainer = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 .5em;
  & img{
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: var(--border-radius-1);
    padding: .3em;
    margin: 0 ;
  }
`
export const WhatsappContactContainer = styled.div`
  margin: 1em 0;
  ${(props) => props.row && `
    margin: .3em 0;
  `}
  @media screen and (max-width: 700px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const WhatsappContactLogo = styled.img`
  margin-right: .5em;
`
export const TicketSaleIcon = styled.img`
  @media screen and (max-width: 700px) {
    margin: 0 auto;
  }
`
export const TicketSaleCol = styled(Col)`
  @media screen and (max-width: 700px) {
    text-align: center;
  }
`