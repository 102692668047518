import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import SuccessfullMessageBox from "../../components/ui/SuccessfullMessageBox";

class EmailVerification extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <SuccessfullMessageBox
                title={applicationContext.translator("Check e-mail for reset link")}
                messages={[applicationContext.translator("An email has been sent to your inbox. check the inbox of the your email account, and click the reset link provided.")]}
            />
        );
    }
}
export default (withApplicationContext(EmailVerification));