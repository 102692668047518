import api from "./api";

const fetchCommonData = () => {
    return api.get("/common/all").then((response) => {
        return response.data;
    });
};
const doGet = (url, params = {}) => {
    return api.get(url, {
        params
    }).then((response) => {
        return response.data;
    });
};
const doGetBase = (url, config = {}) => {
    return api.get(url, config).then((response) => {
        return response.data;
    });
};

const doPost = (url, params = {}) => {
    return api.post(url, params).then((response) => {
        return response.data;
    });
};

const doDelete = (url, params = {}) => {
    return api.delete(url, {
        params
    }).then((response) => {
        return response.data;
    });
};

const CommonService = {
    fetchCommonData,
    doGet,
    doGetBase,
    doPost,
    doDelete
};

export default CommonService;