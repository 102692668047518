import React, {Component} from 'react';
import LoginForm from "../Forms/LoginForm";
import PropTypes from "prop-types";
import RegistrationOption from "../RegistrationOption";
import ForgetPasswordOption from "../ForgetPasswordOption";
import {withApplicationContext} from "../../../contexts/ApplicationContext";

class LoginPopup extends Component {
    render() {
        const {applicationContext, onCallback} = this.props;
        return (
            <React.Fragment>
                <LoginForm onCallback={onCallback}
                           popup={true}
                           title={applicationContext.translator("Please login to your account to continue")}/>
                <br/>
                <RegistrationOption />
                <ForgetPasswordOption />
            </React.Fragment>
        );
    }
}
LoginPopup.propTypes = {
    onCallback: PropTypes.func.isRequired
}
export default withApplicationContext(LoginPopup);