import React, {Component} from 'react';
import {
    Wrapper,
    FooterCopyrightContainer,
    CopyrightText, LogoWrapper, Logo
} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
class Footer extends Component {

    render() {
        const {applicationContext} = this.props;

        return (
            <React.Fragment>

                <FooterCopyrightContainer>
                    <Wrapper>
                        <LogoWrapper>
                            <Logo src={"/assets/images/logo.png"} />
                        </LogoWrapper>
                        <CopyrightText>
                            MUSIC NOVA LLC - The Meydan Hotel, Grandstand 6th floor Meydan Road, Nad Al Sheba, dubai, United arab Emirates. <span>Copyright © Music Nova 2024</span>
                        </CopyrightText>
                    </Wrapper>
                </FooterCopyrightContainer>

            </React.Fragment>
        );
    }
}
Footer.propTypes = {

}
export default withApplicationContext(Footer);