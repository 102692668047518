import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import AdminService from "../../../services/AdminService";
import {generateUrl} from "../../../helpers";
import DataList from "../../../components/ui/DataList/DataList";
import Search from "./Search";
import Button from "../../../components/ui/Button";
import {ROUTE_ADMIN_UPDATE_EVENT} from "../../../routes/routes";

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingData: true,
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
            dataStatus: props.status,
            description: null,
            submitting: false
        }

    }
    componentDidMount = async () => {
        await this.fetchData(1);

        this.setState({ loading: false })
    }

    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        const {service, applicationContext} = this.props;
        this.setState({ loadingData: true })
        if(!search)
            search = {
                status: this.state.dataStatus
            };

        let data = await AdminService.list(service, {
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search && search)
        });
        this.setState({
            loadingData: false,
            data: data,
            sort:{
                key: sort_field,
                dir: sort_dir
            },
            search
        }, callback)
    }
    renderSearch = () => {
        return <Search data={this.state.search} status={this.state.dataStatus} doFetchData={this.fetchData}/>;
    }
    onUpdate = (row) => {
        const {navigate} = this.props;
        navigate(generateUrl(ROUTE_ADMIN_UPDATE_EVENT, ['id'], [row.id]));
    }

    onDelete = async (row, callback) => {
        const {applicationContext} = this.props;
        let component = (
            <React.Fragment>
                {applicationContext.translator("Are you sure to delete this record?")}
            </React.Fragment>
        );
        let footer = (
            <React.Fragment>
                <Button color={"success"} submitting={this.state.submitting} onClick={(e) => this.handleDelete(e, row, callback)} margin={"0 .2em"}>{applicationContext.translator("Yes, I'm sure")}</Button>
                <Button color={"danger"} disabled={this.state.submitting} onClick={(e) => applicationContext.onCloseConfirmPopup(e, callback)} margin={"0 .2em"}>{applicationContext.translator("No")}</Button>
            </React.Fragment>

        );
        applicationContext.onOpenConfirmPopup("Delete", component, footer, callback, "sm")
    }
    handleDelete = async (e, row, callback) => {
        const {applicationContext, service, alert} = this.props;
        applicationContext.onUpdatePopupSubmitting(true);

        await AdminService.doDelete(service, row.id).then((data) => {
            applicationContext.onCloseConfirmPopup(e, () => {
                this.setState({
                    submitting: false
                }, () => {
                    callback({
                        id: row.id
                    }, "delete")
                })
            })
        }).catch((e) => {
            Object.keys(e.response.data.errors).map((field) => {
                alert.error(applicationContext.translator(e.response.data.errors[field][0]))
            })
        })
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                <DataList
                    columns={[
                        {key: "id", label: "#", format: "number"},
                        {key: "default_image", label: "Image", format: "image"},
                        {key: "title", label: "Title"},
                        {key: "date_of_event", label: "Date", format: "date"},
                        {key: "start_time_of_event", label: "Start"},
                        {key: "end_time_of_event", label: "End"},
                        {key: "created_at", label: "Created", format: "datetime"},
                        {key: "", label: "Actions", actions: [
                                {label: "Update", route: "#", onClick: this.onUpdate, color: "#4caf50"},
                                {label: "Delete", route: "#", onClick: this.onDelete, color: "#d50f0a"},
                        ]}
                    ]}
                    data={this.state.data}
                    action={this.fetchData}
                    search={this.renderSearch}
                    sort={this.state.sort}
                    loading={this.state.loadingData}
                />
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext((Events)))));