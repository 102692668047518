import styled from "styled-components";
import {Col, Row} from 'react-bootstrap';
export const Container = styled(Row)`
  padding: .5em 0;
`
export const EventTitle = styled.h1`
    font-size: 2.3em;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
`
export const EventTitleSmall = styled.h3`
    font-size: 1em;
    font-weight: 300;
    
`
export const EventDescription = styled.p`
    font-size: 1.6em;
    font-weight: 500;
    color: var(--color-primary);
    text-transform: uppercase;
    margin-bottom: 1.5em;
`
export const PrimaryText = styled.span`
    color: var(--color-primary);
    text-decoration: underline;
    
`
export const Title = styled.h3`
    font-size: 1.1em;
    font-weight: 500;
    margin-top: 1em;
`
export const Text = styled.p`
    font-size: .9em;
    font-weight: 400;
    & p{
      margin:0;
    }
    & a{
        text-decoration: underline;
    }
`
export const ArtistsContainer = styled.div`
    display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  
`
export const ArtistContainer = styled.div`
    display: flex;
  align-items: center;
  border: 1px solid var(--color-border);
  padding: 0.5em 1em;
  border-radius: var(--border-radius-2);
  margin-right: .5em;
  min-width: 25%;
`
export const ArtistImage = styled.div`
    background: url(${(props) => props.image});
  width: 68px;
  height: 68px;
  border-radius: var(--border-radius-1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
export const CoverImage = styled.img`
    width: 100%;
  border-radius: var(--border-radius-2);;
`
export const ArtistInfo = styled.div`
  width: fit-content;
  padding-left: .5em;
`

export const ArtistName = styled.div`
    font-size: 1em;
  font-weight: 500;
  margin-top: .5em;
`
export const ArtistType = styled.div`
    font-size: .8em;
  color: var(--color-shadow-5);
`
export const SelectTicketContainer = styled.div`
  margin: 1em 0;
  padding: 2em;
  @media screen and (max-width: 700px) {
    padding: .3em;
  }
`