import styled from "styled-components";
import {Form} from "react-bootstrap";

export const Container = styled.div`
  margin-bottom: 1em;
`;
export const InputContainer = styled.div`
  display: flex;
  & .is-invalid{
    padding-right: .3em !important;
    background-image: none;
  }
`;
export const InputError = styled(Form.Text)`
    color: #dc3545;
      margin: 0 1.2em;
      line-height: 3em;
    ${(props) => props.hidden && `
        display: none;
    `}

`