import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Main, StatusLabel, Title} from "./style";
import {withAlert} from "react-alert";
import PageNotFound from "../../../components/ui/PageNotFound";
import CommonService from "../../../services/CommonService";
import Search from "./Search";
import DataList from "../../../components/ui/DataList/DataList";
import ContentLoading from "../../../components/ui/ContentLoading/ContentLoading";
class Payments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            notFound: false,
            data: [],
            search: {

            },
            sort: {
                key: 'id',
                dir: 'desc'
            },
        }


    }
    componentDidMount = async () => {
        await this.fetchData(1);
    }
    fetchData = async (page, sort_field = 'id', sort_dir = 'desc', search = null, callback = null) => {
        this.setState({ loading: true })

        let params = {
            page,
            limit: 10,
            sort_field,
            sort_dir,
            ...(search && search)
        };
        let data = await CommonService.doGet("/profile/payments", params).catch((e) => {
            console.log(e.response.data.message);
            this.setState({ notFound: true });
        });
        if(data){
            this.setState({
                loading: false,
                data: data,
                sort:{
                    key: sort_field,
                    dir: sort_dir
                },
                search
            }, callback)
        }

    }
    renderDataLoading = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <ContentLoading height={"100vh"}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
                <rect x="0" y="61" rx="5" ry="5" width="20%" height="40" />
                <rect x="20.5%" y="0" rx="5" ry="5" width="20%" height="40" />
                <rect x="42%" y="0" rx="5" ry="5" width="20%" height="40" />
                <rect x="63%" y="0" rx="5" ry="5" width="40%" height="40" />
                <rect x="0" y="50" rx="5" ry="5" width="100%" height="80%" />
            </ContentLoading>

        )
    }
    renderViewComponent = () => {
        const {loading, notFound} = this.state;
        const {applicationContext} = this.props;
        if (loading) return null;
        if (notFound) return (<PageNotFound/>);
        return (
            <DataList
                columns={[
                    {key: "id", label: "#", format: "number"},
                    {key: "ref_id", label: "Reference id"},
                    {key: "order.id", label: "Order id", format: "uniqueId"},
                    {key: "order.basket_details.summary.checkout.total", label: "Total price"},
                    {key: "status", label: "Status", render: this.renderStatus},
                    {key: "callback_at", label: "Paid time", format: "datetime"}
                ]}
                data={this.state.data}
                action={this.fetchData}
                search={this.renderSearch}
                sort={this.state.sort}
                loading={this.state.loading}
            />
        )
    }

    renderStatus = (value, rowIndex, row) => {
        return <StatusLabel status={value}>{value}</StatusLabel>
    }
    renderSearch = () => {
        return <Search data={this.state.search}  status={this.state.dataStatus} doFetchData={this.fetchData}/>;
    }
    render() {
        const {applicationContext, accountContext} = this.props;
        return (
            <Container>
                <Title>Your payments</Title>
                <Main>
                    {this.renderDataLoading()}
                    {this.renderViewComponent()}
                </Main>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(Payments))));