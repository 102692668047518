import React, {Component} from 'react';
import {MainWrapper, Title} from "../style";
import {withCheckoutContext} from "./CheckoutContext"
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import EventCard from "./EventCard";
import AdditionalServices from "./AdditionalServices";
class OrderSummary extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <MainWrapper md={6}>
                <Title>{applicationContext.translator("Order summary")}</Title>
                <EventCard />
                {/*<AdditionalServices />*/}
            </MainWrapper>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withCheckoutContext(OrderSummary))));