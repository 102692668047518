import React, {Component} from 'react';
import {Container, Wrapper } from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";
import {StageMapProvider} from "./StageMapContext";
import Seats from "./Seats";

class StageMap extends Component {
    refContainer = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.eventItem.id,
            eventItem: null,
            seats: this.props.eventItem.place.seats,
            reservedSeats: this.props.reservedSeats,
            packages: this.props.packages,
            maxSeatsSelect: (this.props.maxSeatsSelect)??10,
            cellSize: 0,
            loading: true,
            cellHover: null,
            selectedSeats: props.selectedSeats,
            soldSeats: props.soldSeats,
            closeReservation: props.closeReservation
        }

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.selectedSeats !== this.state.selectedSeats){
            this.setState({
                selectedSeats: this.props.selectedSeats
            })
        }
        if(this.props.closeReservation !== this.state.closeReservation){
            this.setState({
                closeReservation: this.props.closeReservation
            })
        }
    }
    componentDidMount() {
        const {seats} = this.state;
        const {eventItem} = this.props;
        let containerWidth = this.refContainer.offsetWidth;
        let cellCountInRow = 0;
        seats['seats'].map((seatColumn) => {
            let counted = false;
            seatColumn.map((seatRow) => {
                if(!counted && seatRow.hasOwnProperty('seats') && seatRow.seats.length > 0){
                    cellCountInRow += seatRow.seats.length;
                    counted = true;
                }
            })
        });
        cellCountInRow += (seats['seats'].length + 1);
        this.setState({
            cellSize: Math.floor((containerWidth / cellCountInRow) - 2),
            eventItem,
            loading: false
        })
    }
    onUpdateState = (state) => {
        this.setState(state);
    }
    onClickSeat = (item) => {
        const {onSelectSeat} = this.props;
        const {selectedSeats} = this.state;
        if(!selectedSeats.includes(item.id)){
            selectedSeats.push(item.id);
            this.setState({
                selectedSeats
            }, () => {
                if(onSelectSeat)
                    onSelectSeat(item);
            })
        }
    }
    onClickRemoveSeat = (item) => {
        const {onSelectSeat} = this.props;
        const {selectedSeats} = this.state;
        let deleted = selectedSeats.indexOf(item.id);
        if(deleted > -1){
            selectedSeats.splice(deleted, 1);
            this.setState({
                selectedSeats
            }, () => {
                if(onSelectSeat)
                    onSelectSeat(item, 'remove');
            })
        }
    }

    render() {
        const {
            seats,
            loading,
            packages,
            cellSize,
            cellHover,
            selectedSeats,
            maxSeatsSelect,
            closeReservation,
            reservedSeats,
            soldSeats,
            id
        } = this.state;
        return (
            <StageMapProvider value={{
                id,
                seats,
                packages,
                cellHover,
                cellSize,
                loading,
                selectedSeats,
                closeReservation,
                soldSeats,
                reservedSeats,
                maxSeatsSelect,
                onUpdateState: this.onUpdateState,
                onClickSeat: this.onClickSeat,
                onClickRemoveSeat: this.onClickRemoveSeat
            }}>
                <Container ref={(ref) => this.refContainer = ref}>
                    <Wrapper>
                        {<Spinner size={"md"} show={loading} inline={true}/>}
                        <Seats />
                    </Wrapper>
                </Container>
            </StageMapProvider>
        );
    }
}
StageMap.propTypes = {
    eventItem: PropTypes.object.isRequired,
    packages: PropTypes.object.isRequired,
    onSelectSeat: PropTypes.func,
    size: PropTypes.string,
    show: PropTypes.bool,
    inline: PropTypes.bool,
    closeReservation: PropTypes.bool,
    selectedSeats: PropTypes.array,
    reservedSeats: PropTypes.array,
    soldSeats: PropTypes.array,
    maxSeatsSelect: PropTypes.number.isRequired
}
export default withApplicationContext(StageMap);