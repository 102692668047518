import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {WhatsappContactContainer, WhatsappContactLogo} from "./style";
import Button from "../Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage} from '@fortawesome/free-regular-svg-icons'
class WhatsappContact extends Component {
    render() {
        const {applicationContext, row} = this.props;
        return (
            <WhatsappContactContainer row={row}>
                <WhatsappContactLogo src={"/assets/images/whatsapp-modern-round.svg"} width={28}/>

                <Button color="outline-blue" size="sm">
                    <FontAwesomeIcon icon={faMessage} /> {applicationContext.translator("Chat Online")}
                </Button>
            </WhatsappContactContainer>
        );
    }
}

export default withApplicationContext(WhatsappContact);