import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const BlockText = styled(Form.Text)`
  font-size: 1em;
  color: var(--color-shadow-8);
  & a{
    display: inline;
    margin: 0 .3em;
    color: var(--color-primary);
    &:hover{
      color: var(--color-shadow-8);
    }
  }
`
export const FormTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  color: var(--color-shadow-7);
  text-align: center;
`
export const FormDescription = styled.div`
  margin: 0 0 01em 0px;
  font-size: .9em;
  font-weight: 400;
  color: var(--color-shadow-7);
  text-align: center;
`

export const SubmitError = styled.div`
    color: #dc3545;
    padding: 0 0.5em 1em .5em;
    text-align: center;
   
`
