import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
import { SwiperSlide as ReactSwiperSlide } from 'swiper/react';
export const Container = styled.div`
    
`
export const SwiperSlide = styled(ReactSwiperSlide)`
    width: fit-content !important;
    & img{
      height: 240px;
      border-radius: var(--border-radius-2);;
    }
`
export const SlideWrapper = styled(Col)`
  display: flex;
  height: calc(240px + 1em);
  overflow: hidden;
  flex-flow: row nowrap;
  position: relative;
  
  
  
`
export const Wrapper = styled.div`
    margin: .5em;
`
export const SwiperSlide1 = styled.img`
  margin: .5em;
  height: 140px;
  width: 100vw;
  border-radius: var(--border-radius-2);;
  &:nth-child(odd){
    animation: loop 10s -25s linear infinite;
  }
  &:nth-child(even){
    animation: loop2 10s linear infinite;
  }
`