import React, {Component} from 'react';
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import {ContentText} from "./style";

class AboutText extends Component {

    render() {
        return (
            <ContentText>
                <h1>About</h1>
                <p>Music Nova is a Dubai-based concert promoter and production company working across multiple entertainment platforms, from artist touring, concerts, music festivals, record labels, music publishing and music video production.</p>
                <p>With the technical expertise and logistical planning from a dedicated and talented team of individuals who bring ideas to life and leave audiences inspired.</p>
                <p>We aspire to become a major player in the live entertainment business backed by our local knowledge and global reach. Our intentions are to unite artists and audiences to create live experiences and capture long-lasting memories.</p>
                <p>At Music Nova, we are driven by a relentless pursuit of excellence. We constantly strive to exceed expectations, deliver the best possible experience, and build long-lasting relationships with our clients and partners.</p>
                <p>Follow us and stay tuned for upcoming events. <a href={"https://instagram.com/musicnova.live"} target={"_blank"}>(Instagram)</a> </p>
            </ContentText>

        );
    }
}

export default withAlert()((withRouter(AboutText)));