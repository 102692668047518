import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  bottom: 0;
  right: 0;
  background: var(--color-popup-overlay-background);
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.dir && `
    direction: ${props.dir};
  `};
`;
export const PopupContainer = styled.div`
  background: var(--color-popup-background);
  color: var(--color-popup-text);
  box-shadow: 0 5px 15px 0 var(--color-shadow-04);
  border: 1px solid var(--color-popup-border);
  border-radius: var(--border-radius-2);;
  width: 100%;
  max-width: 450px;
  ${(props) => props.size && props.size === "lg" && `
    max-width: 800px;
  `};
  ${(props) => props.size && props.size === "md" && `
    max-width: 650px;
  `};

  @media screen and (max-width: 700px) {
    width: 90%;
  }
  
  
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 1em;
  justify-content: space-between;
`
export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 1em;
  justify-content: center;
  position: relative;
  & button{
    width: 100%;
    padding: .7em 2em;
  }
  ${(props) => props.disabled && `
    & *{
      pointer-events: none; 
      opacity: .7;
    }
  `}
`
export const Title = styled.div`
  font-weight: 600;
`
export const Body = styled.div`
  padding: 1em;
`
export const Icon = styled.div`
  font-size: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover{
    color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
`
