import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Title} from "./style";
import SimpleCard from "../../../components/ui/SimpleCard";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Col, Row, Spinner} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import CountryDropDown from "../../../components/ui/CountryDropDown/CountryDropDown";
import Button from "../../../components/ui/Button";
import CommonService from "../../../services/CommonService";
import {withAlert} from "react-alert";
import {parseErrorMessage} from "../../../helpers";
import {ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL, ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE} from "../../../routes/routes";
class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            updateEmailSubmitting: false,
            updateMobileSubmitting: false,
            inputs: {
                first_name: "",
                last_name: "",
                mobile_country: "",
                mobile: "",
                email: "",
                address: "",
                national_id: "",
            }
        }


    }
    componentDidMount() {
        const {accountContext} = this.props;
        this.setState({
            inputs: {
                first_name: accountContext.state.userInfo['first_name'],
                last_name: accountContext.state.userInfo['last_name'],
                mobile_country: accountContext.state.userInfo['country']['mobile_prefix_clear'],
                mobile: accountContext.state.userInfo['mobile_no_nice'],
                email: accountContext.state.userInfo['email'],
                address: accountContext.state.userInfo['address'],
                national_id: accountContext.state.userInfo['national_id'],
            }
        })
    }
    onSubmit = async (values, actions) => {
        const {alert, accountContext} = this.props;
        this.setState({ submitting: true })
        let user = await CommonService.doPost(`/profile/update`, values).catch((e) => {
            let error = e.response.data;
            if(error.hasOwnProperty('input_errors')){
                Object.keys(error['input_errors']).map((name) => {
                    actions.setFieldError(name, error['input_errors'][name][0]);
                })
            }else{
                alert.error(error.message);
            }
        })
        if(user){
            accountContext.updateUserInfo(user, () => {
                alert.success("The profile information has been updated successfully");
            })
        }
        this.setState({ submitting: false })
    }
    onUpdateEmailAction = async (e) => {
        const {alert, navigate} =this.props;
        this.setState({
            updateEmailSubmitting: true
        })
        await CommonService.doPost(`/profile/email/request/update`, {}).catch((e) => {
            let error = e.response.data;
            alert.error(error.message);
        }).then((data) => {
            if(data)
                navigate(ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL);
        }).finally(() => {
            this.setState({
                updateEmailSubmitting: false
            })
        })

    }
    onUpdateMobileAction = async (e) => {
        const {alert, navigate, applicationContext} =this.props;
        this.setState({
            updateMobileSubmitting: true
        })
        await CommonService.doPost(`/profile/mobile/request/update`, {}).catch((e) => {
            let error = e.response.data;
            alert.error(error.message);
        }).then((data) => {
            if(data){
                applicationContext.doUpdateState({
                    holderData: data.endpoint
                });
                navigate(ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE);
            }

        }).finally(() => {
            this.setState({
                updateMobileSubmitting: false
            })
        })

    }
    render() {
        const {applicationContext, accountContext} = this.props;
        return (
            <Container>
               <SimpleCard>
                   <Formik
                       enableReinitialize={true}
                       innerRef={(ref) => this.refForm = ref}
                       initialValues={this.state.inputs}
                       validationSchema={Yup.object().shape({
                           first_name: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("first name")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                           last_name: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("last name")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                           address: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("address")).min(3, applicationContext.translator("${path} must be minimum 3 characters")),
                           national_id: Yup.string().required(applicationContext.translator("You must enter the ${path}")).label(applicationContext.translator("ID ContentLoading Number")).min(9, applicationContext.translator("${path} must be minimum 3 characters")),
                       })}
                       onSubmit={this.onSubmit}
                   >
                       {({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleSubmit,
                             setFieldValue
                         }) =>
                           (
                               <Form onSubmit={handleSubmit} style={{direction: applicationContext.translator("direction")}}>
                                   <Title>Your account profile</Title>
                                   <Row>
                                       <Col md={12}>
                                           <InputBox type="text"
                                                     name={"email"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("E-Mail Address")}
                                                     value={values.email}
                                                     disabled={true}
                                                     buttonAlign={"right"}
                                                     buttonSubmitted={this.state.updateEmailSubmitting}
                                                     button={this.onUpdateEmailAction}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                       <Col md={12}>
                                           <InputBox type="text"
                                                     onlyNumber={true}
                                                     name={"mobile"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("Mobile number")}
                                                     value={values.mobile}
                                                     disabled={true}
                                                     buttonAlign={"right"}
                                                     button={this.onUpdateMobileAction}
                                                     buttonSubmitted={this.state.updateMobileSubmitting}
                                                     maxLength={12}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                       <Col md={6}>
                                           <InputBox type={"text"}
                                                     name={"first_name"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("First name")}
                                                     value={values['first_name']}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                       <Col md={6}>
                                           <InputBox type={"text"}
                                                     name={"last_name"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("Last name")}
                                                     value={values['last_name']}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>

                                       <Col md={12}>
                                           <InputBox type="text"
                                                     name={"national_id"}
                                                     onlyNumber={true}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("ID Card Number")}
                                                     value={values['national_id']}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                       <Col md={12}>
                                           <InputBox type="text"
                                                     name={"address"}
                                                     onChange={handleChange}
                                                     label={applicationContext.translator("Address")}
                                                     value={values.address}
                                                     errors={errors}
                                                     touched={touched}
                                           />
                                       </Col>
                                        <Col md={12}>
                                            <Button disabled={this.state.submitting} color="primary" size="lg" margin={"1em 0"} type={"submit"} fullWidth={true}>
                                                {this.state.submitting?<Spinner animation="border" size={"sm"} />:applicationContext.translator("Update Profile")}
                                            </Button>
                                        </Col>
                                   </Row>
                               </Form>
                           )
                       }
                   </Formik>
               </SimpleCard>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(Profile))));