import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import withRouter from "../../contexts/withRouter";
import Button from "../../components/ui/Button";
import {generateUrl, slugify} from "../../helpers";
import {ROUTE_CART} from "../../routes/routes";
import PropTypes from "prop-types";

class SelectTicketButton extends Component {
    onClickSelectTicket = (e) => {
        const {navigate, eventItem} = this.props;
        navigate(generateUrl(ROUTE_CART, ["id", "slug"], [eventItem.id, slugify(eventItem.title)]));

    }
    render() {
        const {applicationContext, closeReservation, color} = this.props;
        return (
            <Button disabled={closeReservation} color={color?color:"primary"} size="lg" fullWidth={true} margin={"1em 0"} onClick={this.onClickSelectTicket}>
                {closeReservation?applicationContext.translator("Sold Out"):applicationContext.translator("Select Tickets")}
            </Button>
        );
    }
}
SelectTicketButton.propTypes = {
    closeReservation: PropTypes.bool.isRequired,
    eventItem: PropTypes.object.isRequired
}
export default withApplicationContext(withRouter(SelectTicketButton));