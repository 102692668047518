import styled from "styled-components";
import {Form} from 'react-bootstrap';
export const ContentText = styled.div`
    padding: 0;
    & p{
        font-size: 1em;
        font-weight: 400;
        text-align: justify;
    }
    & h1{
        font-size: 1.6em;
        font-weight: bold;
        margin-bottom: 1em;
        color: var(--color-primary);
    }
`