import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const BlockText = styled(Form.Text)`
  font-size: 1em;
  color: #333;
  & a{
    display: inline;
    color: var(--color-secondary);
    margin: 0 .3em;
  }
`
export const FormTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  color: #444;
  text-align: center;
`

export const ErrorBox = styled.div`
  max-width: 500px;
  margin: 2em auto;
  text-align: center;
`
export const Icon = styled.div`
  width: 100%;
  & svg{
    width: 80px;
    height: 80px;
    ${(props) => props.error && `color: #9d0715;`}
    ${(props) => props.success && `color: #198754;`}
  }
`
export const Text = styled.div`
  text-align: center;
  margin-top: 1em;
  
`
export const ListDetailsItems = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 400px;
  & li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.2em;
    margin: .2em 0;
    & b{
      color: var(--color-shadow-5);
    }
    border-radius: var(--border-radius-2);
    background: var(--color-shadow-03);
    &:nth-child(odd){
      background: var(--color-shadow-05);
    }
  }
`