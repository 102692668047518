import React, {Component} from 'react';
import {withCartContext} from "./CartContext";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {CartBarContainer, CartBarWrapper} from "./style";
import CartBarDetails from "./CartBarDetails";
import CartBarCheckout from "./CartBarCheckout";
import {Row} from "react-bootstrap";
import CartBarDetailsList from "./CartBarDetailsList";

class CartBar extends Component {
    render() {
        const {cartContext} = this.props;
        const {cart} = cartContext.state;
        return (
            (cart.count() > 0)?(
            <CartBarContainer>
                <CartBarWrapper>
                    <CartBarDetails />
                    <CartBarCheckout />
                    <CartBarDetailsList open={cartContext.state.cartBarOpen}/>
                </CartBarWrapper>
            </CartBarContainer>):(null)
        );
    }
}

export default withApplicationContext(withCartContext(CartBar));