import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import RowSupportFooter from "../../components/ui/Footer/RowSupportFooter";
import AboutText from "./AboutText";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

    }

    render() {
        return (
            <React.Fragment>
                <Container>
                    <AboutText />
                    <RowSupportFooter />
                </Container>
            </React.Fragment>


        );
    }
}

export default withAlert()((withRouter(About)));