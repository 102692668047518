import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Text} from "./style";

class ContactPhone extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Text>{applicationContext.translator("Customer service Telephone")}</Text>
                <Text link={true}><a href={"tel:+971502095458"}>+971 50 209 54 58</a></Text>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(ContactPhone);