import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from "prop-types";
import Select from "react-select";
import { Row} from "react-bootstrap";
import {InputGroup} from "./style";
import {InputError, Label} from "../InputBox/style";

class CountryDropDown extends Component {
    constructor(props) {
        super(props);
        const {applicationContext} = this.props;
        let countries = applicationContext.state.common.countries;
        if(props.onlyCountryCode){
            countries.map((r, index) => {
                countries[index].label = r.flag + " " + r.mobile_prefix;
            })
        }
        this.state = {
            countries: countries,
            selectedCountry: null
        }
    }
    componentDidMount() {
        this.handleSelectedCountry();
    }

    handleSelectedCountry = () => {
        const {applicationContext, onChange, name} = this.props;
        let selectedCountry = (this.props.value)?this.findIsoByCountryCode(this.props.value):applicationContext.state.common.default_country;
        if(onChange)
            onChange(name, selectedCountry.code);
        this.setState({
            selectedCountry
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value !== prevProps.value){
            this.handleSelectedCountry();
        }
    }

    findIsoByCountryCode = (cc) => {
        const {applicationContext} = this.props;
        if(!cc.startsWith("+"))
            cc = "+" + cc;
        let country = applicationContext.state.common.countries.filter(row => row.code === cc)
        if(country.length > 0) {
            return country[0];
        }

        return null;
    }
    render() {
        const {loading, selectedCountry, countries} = this.state;
        const {margin, floatLabel, name, touched, errors, onChange, disabled, label} = this.props;
        return (
            <InputGroup as={Row} margin={margin} floatLabel={floatLabel}>
                {label && <Label >{label}</Label>}
                <Select
                    name={name}
                    className={"react-country-dropdown"}
                    classNamePrefix={"nv"}
                    options={countries}
                    isDisabled={disabled}
                    value={selectedCountry}
                    onChange={(selectedOption) => {
                        this.setState({
                            selectedCountry: selectedOption
                        });
                        if(onChange)
                            onChange(name, selectedOption.code);
                    }}
                />
                {touched && touched[name] && <InputError hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
            </InputGroup>
        );
    }
}
CountryDropDown.propTypes = {
    margin: PropTypes.string,
    floatLabel: PropTypes.bool,
    label: PropTypes.string,
    onlyCountryCode: PropTypes.bool
}
export default withApplicationContext(CountryDropDown);