import adminApi from "./adminApi";
import api from "./api";
import {SERVICE_UPLOAD} from "./services";
import AdminTokenService from "./AdminTokenService";

const handler = () => {
    if(AdminTokenService.getLocalAccessToken())
        return adminApi;
    return api;
}
const doUpload = (file, file_type, onUploadProgress = null) => {
    let bodyFormData = new FormData();
    bodyFormData.append('file', file);
    bodyFormData.append('file_type', file_type);

    return handler().post(`/${SERVICE_UPLOAD}/create`, bodyFormData, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: onUploadProgress
    }).then((response) => {
        return response.data;
    });
};
const doDelete = (id) => {
    return handler().delete(`/${SERVICE_UPLOAD}/delete/${id}`)
        .then((response) => {
            return response.data;
        });
};

const UploadService = {
    doUpload,
    doDelete
};

export default UploadService;