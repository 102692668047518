import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAccountContext} from "../../contexts/AccountContext";
import SlideShow from "../../components/ui/SlideShow";
import {Row} from "react-bootstrap";
import {ColContainer, StageImage, StageImageTitle} from "./style";
import SupportFooter from "../../components/ui/Footer/SupportFooter";
import UpComingEvents from "./UpComingEvents";
import PastEvents from "./PastEvents";
import RowSupportFooter from "../../components/ui/Footer/RowSupportFooter";
import AboutText from "../About/AboutText";
import MusiciansText from "../Musicians/MusiciansText";
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pastEvents: {
                data: [],
                loading: true
            }
        }

    }
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SlideShow fetchUpComingEvents={true} isCountdown={true} />
                <UpComingEvents limit={3} />
                <PastEvents />
                <Row id={"about-section"} style={{ padding: "3em 1em 1em 1em"}}>
                    <ColContainer md={6}>
                        <AboutText />
                    </ColContainer>
                    <ColContainer md={6}>
                        <div>
                            <StageImageTitle>{applicationContext.translator("Book quickly and get your favorite seats.")}</StageImageTitle>
                            <StageImage src={"/assets/images/stage-updated.png"}/>
                        </div>
                    </ColContainer>
                </Row>
                <Row id={"musicians-section"} style={{ padding: "5em 1em 1em 1em"}}>
                    <ColContainer md={6}>
                        {this.renderMusiciansSlideshow()}
                    </ColContainer>
                    <ColContainer md={6}>
                        <MusiciansText />
                    </ColContainer>
                </Row>
                <Row id={"contact-section"}>
                    <ColContainer md={12}>
                        <RowSupportFooter />
                    </ColContainer>
                </Row>


            </React.Fragment>

        );
    }
    renderMusiciansSlideshow = () => {
        let images = [
            { slideshow: { url: "/assets/images/musicians-01.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-02.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-03.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-04.jpeg?v=1" } },
            { slideshow: { url: "/assets/images/musicians-05.jpg?v=1" } }
        ]
        return (
            <div>
                <SlideShow disabledFooter={true} disableArrows={true} fixedFooter={true} images={images} height={300}/>
            </div>
        )
    }
}

export default withApplicationContext(withAccountContext(Home));