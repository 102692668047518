import styled from "styled-components";
import {Button} from "react-bootstrap"
export const ButtonContainer = styled(Button)`
  text-wrap: nowrap;
  white-space: nowrap;
  ${(props) => props.color === "primary" && `
     background: var(--color-primary);
     border-color: var(--color-primary);
     color: #fff;
     
     &:hover{
        background: transparent;
       border-color: var(--color-primary);
       color: var(--color-primary);
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(178 13 93 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  var(--color-primary) !important;
        color: var(--color-primary) !important;;
        background: transparent !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "shadow" && `
     background: rgba(0,0,0,.3);
     border: none;
     color: #fff;
     &:hover{
        background: rgba(0,0,0,.4);
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(178 13 93 / 25%) !important;
     }
     &:active, &:focus{
        background: rgba(0,0,0,.4) !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "white" && `
     background: rgba(255,255,255,1);
     border: none;
     color: var(--color-primary);
     &:hover{
        background: rgba(255,255,255,.8);
        color: var(--color-primary);
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(178 13 93 / 25%) !important;
     }
     &:active, &:focus{
        background: rgba(0,0,0,.4) !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  & svg{
    width: 16px;
    height: 16px;
  }
  ${(props) => props.color === "outline-primary" && `
     background: transparent;
     border-color: var(--color-primary);
     color: var(--color-primary);
     &:hover{
        background: var(--color-primary);
       border-color: var(--color-primary);
       color: #fff;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(178 13 93 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  var(--color-primary) !important;
        color: #fff !important;;
        background: var(--color-primary) !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "outline-blue" && `
     background: transparent;
     border-color: #37a7f8;
     color: #37a7f8;
     &:hover{
        background: #1985d2;
       border-color: #1985d2;
       color: #fff;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(25 133 210 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #1985d2 !important;
        color: #fff !important;;
        background: #1985d2 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "black" && `
     background: #333;
     border-color: #333;
     color: #fff;
     &:hover{
        background: transparent;
       border-color: #333;
       color: #333;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #333 !important;
        color: #fff !important;;
        background: #333 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #333;
        border-color: #333;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "black-primary" && `
     background: #333;
     border-color: #333;
     color: #fff;
     &:hover{
        background: var(--color-secondary);
       border-color: var(--color-secondary);
       color: #333;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  var(--color-secondary) !important;
        color: #fff !important;;
        background: var(--color-secondary) !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #333;
        border-color: #333;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  
  ${(props) => props.color === "success" && `
      background: #4caf50;
      border-color: #4caf50;
      color: #fff;
      
     &:hover{
        background: var(--color-shadow-05);
       border-color: #4caf50;
       color: #4caf50;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(76 175 80 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #4caf50 !important;
        color: #fff !important;;
        background: #4caf50 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #4caf50;
        border-color: #4caf50;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.color === "danger" && `
      background: #d72424;
      border-color: #d72424;
      color: #fff;
     &:hover{
        background: #fff;
       border-color: #d72424;
       color: #d72424;
     }
     &:focus{
      box-shadow: 0 0 0 0.25rem rgb(215 36 36 / 25%) !important;
     }
     &:active, &:focus{
        border:1px solid  #d72424 !important;
        color: #fff !important;;
        background: #d72424 !important;;
     }
     &:disabled{
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: #d72424;
        border-color: #d72424;
        opacity: var(--bs-btn-disabled-opacity);
     }
  `}
  ${(props) => props.fullWidth && `width: 100%;`}
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.size && props.size === 2 && `
    padding: 0.7em 1em;
    font-size: 1.2em;
    font-weight: 600;
  `}
  ${(props) => props.size && props.size === 3 && `
    padding: 0.5em 1em;
    font-size: 1.2em;
  `}
  & span{
    font-size: .8em;
  }
  border-radius: var(--border-radius-2);
  
  
`;
