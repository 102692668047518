import React, {Component} from 'react';
import Popup from "./components/ui/Popup/Popup";
import {withApplicationContext} from "./contexts/ApplicationContext";

class Popups extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                {applicationContext.state.popup.show &&
                    <Popup size={applicationContext.state.popup.size}
                           title={applicationContext.state.popup.title}
                           onClose={applicationContext.onClosePopup}
                    >
                        {applicationContext.state.popup.component}
                    </Popup>
                }
                {applicationContext.state.confirmPopup.show &&
                    <Popup size={applicationContext.state.confirmPopup.size}
                           title={applicationContext.state.confirmPopup.title}
                           footer={applicationContext.state.confirmPopup.footer}
                           onClose={applicationContext.state.confirmPopup.onClose}
                           submitting={applicationContext.state.confirmPopup.submitting}
                    >
                        {applicationContext.state.confirmPopup.component}
                    </Popup>
                }
            </React.Fragment>
        );
    }
}

export default withApplicationContext(Popups);