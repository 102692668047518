import React, {Component} from 'react';
import {PaymentContainer} from "./style";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import withRouter from "../../contexts/withRouter";
import PageNotFound from "../../components/ui/PageNotFound";
import PaymentCancel from "./PaymentCancel";
import PaymentSuccessful from "./PaymentSuccessful";
import PaymentError from "./PaymentError";
import ContentLoading from "../../components/ui/ContentLoading/ContentLoading";

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    renderDataLoading = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <ContentLoading height={"300px"}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="300" />
            </ContentLoading>

        )
    }
    renderViewComponent = () => {
        const {params} = this.props;
        const {status} = params;
        const {loading, notFound} = this.state;
        if (loading) return null;
        if (notFound) return (<PageNotFound/>);
        if(status === "cancel")
            return (<PaymentCancel />);
        if(status === "successful")
            return (<PaymentSuccessful />);
        return <PaymentError />;
    }
    render() {
        return (
            <PaymentContainer>
                {this.renderDataLoading()}
                {this.renderViewComponent()}
            </PaymentContainer>
        );
    }
}

export default withRouter(withApplicationContext(Payment));