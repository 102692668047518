import axios from "axios";
import TokenService from "./TokenService";
import {ROUTE_LOGIN} from "../routes/routes";
import StorageService from "./StorageService";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_WEBSERVICE_API,
    headers: {
        "Content-Type": "application/json",
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        config.params = {
            ...config.params,
            currency: StorageService.get('currency'),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/login" && err.response) {
            console.log(originalConfig.url)
            // Access Token was expired
            if (err.response.status === 401) {
                TokenService.removeUser();
                StorageService.remove("cart");
                window.location.href = ROUTE_LOGIN;
            }else{
                return Promise.reject(err);
            }


        }
        if (originalConfig.url === "/auth/login" && err.response) {
            if (err.response.status === 401){
                TokenService.removeUser();;
            }
        }


        return Promise.reject(err);
    }
);

export default instance;