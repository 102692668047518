import React, {Component} from 'react';
import {TimerContainer, TimerNumber} from "./style";

class SpecialCountDown extends Component {
    
    render() {
        const {timer, size} = this.props;
        return (
            (timer)?(<TimerContainer>
                {timer.days > 0 &&
                    <React.Fragment>
                        <TimerNumber>
                            {timer.days.toString().padStart(2, "0")}
                            <div>Days</div>
                        </TimerNumber>
                        <TimerNumber padding={true}>-</TimerNumber>
                    </React.Fragment>
                }
                <React.Fragment>
                    <TimerNumber>
                        {timer.hours.toString().padStart(2, "0")}
                        <div>Hours</div>
                    </TimerNumber>
                    <TimerNumber padding={true}>:</TimerNumber>
                </React.Fragment>
                <React.Fragment>
                    <TimerNumber>
                        {timer.minutes.toString().padStart(2, "0")}
                        <div>Minutes</div>
                    </TimerNumber>
                    <TimerNumber padding={true}>:</TimerNumber>
                </React.Fragment>
                <TimerNumber>
                    {timer.seconds.toString().padStart(2, "0")}
                    <div>Seconds</div>
                </TimerNumber>
            </TimerContainer>):(null)
        );
    }
}

export default SpecialCountDown;