import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import withRouter from "../../../contexts/withRouter";
class Link extends Component {
    constructor(props) {
        super(props);
    }
    onClick = (e) =>{
        const {navigate, to, location} = this.props;
        e.preventDefault();
        if(location.pathname === to)
            window.location.reload()
        
        navigate(to);
    }
    render() {
        const {children, onClick} = this.props;
        return (
            <NavLink to={this.props.to} onClick={(e) => {
                if(onClick) return onClick(e);
                return this.onClick(e);
            }}>
                {children}
            </NavLink>
        );
    }
}
export default withRouter(Link);