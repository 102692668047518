import React, {Component} from 'react';
import {ContentText} from "./style";

class MusiciansText extends Component {
    render() {
        return (
            <ContentText>
                <h1>Musicians</h1>
                <p>If you are a highly skilled professional musician in your country, we invite you to send us your resume. Upon approval, you will have the opportunity to perform in our prestigious concerts in your country or travel with us for our European concert tour.</p>
            </ContentText>
        );
    }
}

export default MusiciansText;