import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {SocialContainer, Text, Title} from "./style";
import Link from "../Link";

class Social extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <SocialContainer>
                    <a href={"https://instagram.com/musicnova.live"} target={"_blank"}><img src={"/assets/images/instaram-logo.png"} /></a>
                    <a href={"https://tiktok.com/@musicnova.live"} target={"_blank"}><img src={"/assets/images/tiktok-logo.png"} /></a>
                    <a href={"https://youtube.com/@MusicNova"} target={"_blank"}><img src={"/assets/images/youtube-logo.png"} /></a>
                    <a href={"https://twitter.com/MusicNovaLive"} target={"_blank"}><img src={"/assets/images/twitter-logo.png"} /></a>

                </SocialContainer>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(Social);