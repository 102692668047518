import React, {Component} from 'react';
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import {withAccountContext} from "../../../contexts/AccountContext";
import {Card, PaymentContainer} from "../style";
import {
    PayPalScriptProvider,
    PayPalButtons,
} from "@paypal/react-paypal-js";
import {withCheckoutContext} from "./CheckoutContext";
import CommonService from "../../../services/CommonService";
import {parseErrorMessage} from "../../../helpers";
import ContentLoader from "react-content-loader";
import PageNotFound from "../../../components/ui/PageNotFound";
import {Spinner} from "react-bootstrap";
import ContentLoading from "../../../components/ui/ContentLoading";
import Button from "../../../components/ui/Button";
class PaymentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            callbackLoading: false,
            submitting: false,
            notFound: false,
            initialOptions: null
        }
    }
    componentDidMount = async () => {
        let config = await CommonService.doGet(`/checkout/payment/config`).catch((e) => {
            console.log(e)
            this.setState({
                notFound: true
            })
        });
        if(config){
            this.setState({
                initialOptions: config,
                loading: false
            })
        }
    }

    renderDataLoading = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <ContentLoading paddingTop={"2em"}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="300" />
            </ContentLoading>

        )
    }
    onClickTestPay = async (e) => {
        const {checkoutContext, alert, applicationContext} = this.props;
        this.setState({ submitting: true })
        let order = await CommonService.doPost(`/checkout/create-order/${checkoutContext.state.cart.id}`, checkoutContext.state.dataInput).catch((e) => {
            alert.error(applicationContext.translator(parseErrorMessage(e)));
        });
        if(order){
            window.location.href = process.env.REACT_APP_BACK_END_WEBSERVICE_API+"/payment/test/callback?token="+order['ref_id'];
        }
    }
    renderViewComponent = () => {
        const {checkoutContext, alert, applicationContext} = this.props;
        const {loading, notFound, initialOptions, callbackLoading} = this.state;
        if(notFound) return (<PageNotFound />);
        if(loading) return null;
        return (
            <Card>
                {callbackLoading?(
                    <Spinner animation="border" size={"sm"} />
                ):(
                    // <PayPalScriptProvider options={initialOptions}>
                    //     <PayPalButtons
                    //         style={{
                    //             shape: "rect",
                    //             layout: "vertical",
                    //             color: "gold",
                    //             label: "paypal",
                    //         }}
                    //         createOrder={async () => {
                    //             try {
                    //                 let order = await CommonService.doPost(`/checkout/create-order/${checkoutContext.state.cart.id}`, checkoutContext.state.dataInput).catch((e) => {
                    //                     alert.error(applicationContext.translator(parseErrorMessage(e)));
                    //                 });
                    //                 if(order){
                    //                     return order['ref_id'];
                    //                 }
                    //                 return null;
                    //
                    //             } catch (error) {
                    //                 console.log(error)
                    //                 alert.error(`Could not initiate PayPal Checkout...${error}`);
                    //             }
                    //         }}
                    //         onApprove={async (
                    //             data,
                    //             actions
                    //         ) => {
                    //             this.setState({
                    //                 callbackLoading: true
                    //             })
                    //             try {
                    //                 let callback = await CommonService.doGet(`/payment/paypal/callback`, {
                    //                     token: data.orderID,
                    //                     redirect: "no"
                    //                 }).catch((e) => {
                    //                     alert.error(applicationContext.translator(parseErrorMessage(e)));
                    //                 });
                    //                 if(callback && callback.url) {
                    //                     window.location.href = decodeURIComponent(callback.url);
                    //                 }else {
                    //                     this.setState({
                    //                         callbackLoading: false
                    //                     })
                    //                 }
                    //             } catch (error) {
                    //                 console.error(error);
                    //                 alert.error(
                    //                     `Sorry, your transaction could not be processed...${error}`
                    //                 );
                    //             }
                    //         }}
                    //     />
                    // </PayPalScriptProvider>
                    <Button onClick={this.onClickTestPay} disabled={this.state.submitting} color={"primary"} fullWidth={true} size={"lg"}>
                        {this.state.submitting?<Spinner animation="border" size={"sm"} />:applicationContext.translator("Pay test")}
                    </Button>
                )}
            </Card>

        )
    }
    render() {
        return (
            <PaymentContainer>
                {this.renderDataLoading()}
                {this.renderViewComponent()}

            </PaymentContainer>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(withCheckoutContext(PaymentPage)))));