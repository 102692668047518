import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../../contexts/withRouter";
import {withAdminContext} from "../../../contexts/AdminContext";
import LoggedIn from "../LoggedIn";
import AdminService from "../../../services/AdminService";
import PageNotFound from "../../../components/ui/PageNotFound";
import {ErrorBox, Icon, ListDetailsItems, Text} from "./style";
import {faTimesCircle, faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {accountId} from "../../../helpers";

class TicketValidity extends Component {
    constructor(props) {
        super(props);
        const {params} = props;
        this.state = {
            loading: true,
            notFound: false,
            data: null,
            id: params['id']
        }

    }
    componentDidMount = async () => {
        const {service, alert} = this.props;
        let data = await AdminService.get(service, this.state.id).catch((error) => {
            this.setState({
                loading: false,
                notFound: true
            })
        })
        if(data){
            this.setState({
                data,
                loading: false
            })
            console.log(data)
        }
    }
    renderViewComponent = () => {
        const {loading, notFound, data} = this.state;
        if (loading) return null;
        if (notFound) return (
            <React.Fragment>
                <ErrorBox>
                    <Icon error={true}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </Icon>
                    <Text>The submitted ticket is not valid.</Text>
                </ErrorBox>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <ErrorBox>
                    <Icon success={true}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </Icon>
                    <Text>
                        <ListDetailsItems>
                            <li>
                                <b>Ticket ID</b>
                                <div>{accountId(data.id)}</div>
                            </li>
                            <li>
                                <b>First name</b>
                                <div>{data.person.first_name}</div>
                            </li>
                            <li>
                                <b>Last name</b>
                                <div>{data.person.last_name}</div>
                            </li>
                            <li>
                                <b>ID Card number</b>
                                <div>{data.person.national_id}</div>
                            </li>
                            <li>
                                <b>Mobile number</b>
                                <div>{data.person.country.flag} ({data.person.country.mobile_prefix}) {data.person.mobile_trim}</div>
                            </li>
                            <li>
                                <b>Seat Count</b>
                                <div>{data.seats_sold.length}</div>
                            </li>
                            <li>
                                <b>Seats</b>
                                <div>{data.seats_sold.join(' / ')}</div>
                            </li>
                            <li>
                                <b>Total Paid</b>
                                <div>{data.payment.order.basket_details.summary.checkout.total}</div>
                            </li>
                        </ListDetailsItems>
                    </Text>
                </ErrorBox>
            </React.Fragment>
        )
    }
    render() {
        const {adminContext} = this.props;
        return (
            <LoggedIn loading={this.state.loading}>
                {this.renderViewComponent()}
            </LoggedIn>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAdminContext((TicketValidity)))));