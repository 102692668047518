import styled from "styled-components";
import {Col, Form} from "react-bootstrap"
import BootstrapSelect from 'react-bootstrap-select-dropdown';
export const Select = styled(BootstrapSelect)`
  
  &.show-search{
    display: block;
    width: 100% !important;
    margin: 0;
  }
  & .dropdown-toggle{
    width: 100%;
    padding: 0.8em 1.3em;
    border-radius: var(--border-radius-2);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--form-element-text-color-2) !important;
    background-color: var(--form-element-background);
    border: 1px solid var(--color-shadow-08);
    ${(props) => props.invalid && `
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(0.375em + 0.1875rem) center;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);   
  `}
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus{
      box-shadow: 0 0 0 0.25rem rgb(241 204 1 / 25%) !important;
      background: var(--form-element-background);
      border-color: var(--color-primary);
    }
    & .filter-option-inner-inner{
      ${(props) => props.dir === "rtl" && `
        direction: rtl;
        text-align: right;
    `}
      ${(props) => props.invalid && `
        color: #dc3545;
      `}
    }
    
  }
  & .dropdown-toggle::after{
    content: " ";
    border: 0;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 16px 12px;
    width: 16px;
    height: 12px;
    margin-left: -.3em;
  }
  & .dropdown-item{
    &:hover{
      background: var(--color-primary);
      color: var(--text-color-on-primary) !important;
    }
    &.active, &:active{
      background: var(--color-secondary);
      color: var(--text-color-on-primary) !important;
    }
  }
  & .inner{
    direction: ${(props) => props.dir??"rtl"};
    text-align: ${(props) => props.dir === "rtl"?`right`:`left`};
    max-height: ${(props) => `${props.menuSize * 38}px !important;`};
    &::-webkit-scrollbar {
      width: 6px;
      
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: var(--border-radius-1);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  & .dropdown-item{
    padding: 0.65em 1em;
    color: var(--color-text);
  }
  & .bs-searchbox .form-control{
    padding: 0.7em 1em;
    border-radius: var(--border-radius-2);
    font-size: .9em;
    background: var(--form-element-background);
    border-color: var(--color-shadow-08);
    &:focus{
      box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
    }
    &:active, &:focus{
      border:1px solid  var(--color-secondary) !important;
      color: var(--form-element-text-color-2) !important;;
      background: var(--form-element-background) !important;;
    }
    &:disabled{
      background: #fbfbfb !important;
      color: #a5a5a5 !important;
    }
  }
  & .dropdown-menu{
    padding-top: 0;
    background: var(--color-background);
    border:1px solid var(--color-border);
  }
  & .bs-searchbox{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: var(--color-background) !important;
    
  }
  & .border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--color-border) !important;
  }
  
`;