import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {Container} from "./style";
import SuccessfullMessageBox from "../../../components/ui/SuccessfullMessageBox";
import SimpleCard from "../../../components/ui/SimpleCard";

class CurrentEmailCheckInbox extends Component {
    render() {
        const {applicationContext, message} = this.props;
        return (
            <Container>
                <SimpleCard>
                    <SuccessfullMessageBox
                        messages={[
                            applicationContext.translator("You requested to update your e-mail address."),
                            applicationContext.translator("An email has been sent to your inbox.")
                        ]}
                    />
                </SimpleCard>
            </Container>

        );
    }
}
export default (withApplicationContext(CurrentEmailCheckInbox));