import React, {Component} from 'react';

import {
    Container, Toggle, ToggleValue
} from "./style";
import PropTypes from "prop-types";
import Card from "../Card";
class ToggleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggled: props.checked
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.checked !== this.state.isToggled)
            this.setState({
                isToggled: this.props.checked
            })
    }

    handleChange = (e) => {
        const {onChange, values} = this.props;
        let isToggled = !this.state.isToggled;
        this.setState({
            isToggled
        }, () => {
            if(onChange)
                onChange(isToggled?values[0]:values[1]);

        })
    };

    render() {
        const {isToggled} = this.state;
        const {values} = this.props;
        return (
            (values.length === 2)?(
            <Container>
                <Toggle onClick={this.handleChange}
                        className={`${isToggled ? 'on' : 'off'}`}
                        backgroundColor={(isToggled)?(values[0].backgroundColor):(values[1].backgroundColor)}
                        textColor={(isToggled)?(values[0].textColor):(values[1].textColor)}
                >
                    <ToggleValue>
                    {isToggled ? (
                        <React.Fragment>
                            {values[0].icon && <span className={"icon"}>{values[0].icon}</span>} {values[0].label}
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {values[1].icon && <span className={"icon"}>{values[1].icon}</span>} {values[1].label}
                        </React.Fragment>
                    )}</ToggleValue>
                </Toggle>
            </Container>):(null)
        );
    }
}
ToggleButton.propTypes = {
    value: PropTypes.string,
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func
}
export default ToggleButton;