import React, {Component} from 'react';
import {Col, Form, Row} from "react-bootstrap";
import {BlockText} from "./style";
import Link from "../../components/ui/Link/Link";
import {ROUTE_REGISTER} from "../../routes/routes";
import {withApplicationContext} from "../../contexts/ApplicationContext";

class RegistrationOption extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <Form.Group as={Row} className={"mb-3"}>
                <Col className={"text-center"}>
                    <BlockText>
                        {applicationContext.translator("Don't have an account yet?")} <Link to={ROUTE_REGISTER}>{applicationContext.translator("Click here to register")}</Link>
                    </BlockText>
                </Col>
            </Form.Group>
        );
    }
}

export default withApplicationContext(RegistrationOption);