import React, {Component} from 'react';
import {
    Container,
    Image,
    Wrapper,
    Date,
    Title,
    Description,
    ReadMoreIcon,
    InnerContent, LinkReadMore
} from "./style.jsx";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from 'jalali-moment'
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {generateUrl, slugify} from "../../../helpers";
import {ROUTE_EVENT_TICKETS} from "../../../routes/routes";
import Link from "../Link";
class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: generateUrl(ROUTE_EVENT_TICKETS, ["id", "slug"], [props.eventItem.id, slugify(props.eventItem.title)])
        }
    }
    render() {
        const {eventItem, applicationContext} = this.props;
        return (
            <Container md={4}>
                <Wrapper>
                    <InnerContent>
                        <Link to={this.state.url}>
                            <Image url={eventItem['default_image']['url']} />
                        </Link>
                        <Date>
                            <FontAwesomeIcon icon={"calendar"} />
                            {moment(eventItem['date_of_event'], 'YYYY-MM-DD').format('DD MMMM YYYY')}
                        </Date>
                        <Title>
                            <Link to={this.state.url}>{eventItem.title}</Link>
                        </Title>
                        <Description>{eventItem.description}</Description>
                        <LinkReadMore>
                            <Link to={this.state.url}>
                                <span>{applicationContext.translator("Read more")}</span>
                                <ReadMoreIcon className={"read-more"}>
                                    <FontAwesomeIcon icon={"arrow-right"} />
                                </ReadMoreIcon>
                            </Link>
                        </LinkReadMore>
                    </InnerContent>
                </Wrapper>
            </Container>
        );
    }
}
EventCard.propTypes = {
    eventItem: PropTypes.object
}
export default withApplicationContext(EventCard);