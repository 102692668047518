import React, {Component} from 'react';
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormDescription, FormTitle} from "../Login/style";
import Link from "../../components/ui/Link/Link";
import {ROUTE_RESET_PASSWORD_BY_MOBILE} from "../../routes/routes";
import * as Yup from "yup";
import InputBox from "../../components/ui/InputBox/InputBox";
import Button from "../../components/ui/Button";
import {Formik} from "formik";
import EmailCheckInbox from "./EmailCheckInbox";
import Service from "../../services/Service";
import {parseErrorMessage} from "../../helpers";
import FormErrorText from "../../components/ui/FormErrorText";

class ResetByEmailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                email: "",
            },
            error: null,
            submitting: false,
            successfully: false
        }
    }
    handleSubmit = async (values, actions) => {
        const {applicationContext} = this.props;
        this.setState({ submitting: true });
        try {
            await Service.doResetPasswordByEmail({
                email: values.email
            });
            this.setState({
                successfully: true,
                error: null
            })
        } catch (err) {
            let error = null;
            if(err.response.data.hasOwnProperty('input_errors')){
                actions.setErrors(err.response.data['input_errors'])
            }else{
                error = applicationContext.translator(parseErrorMessage(err));
            }
            this.setState({
                submitting: false,
                error
            });

        }
    }
    render() {
        const {applicationContext} = this.props;
        const {successfully, error} = this.state;
        return (
            <React.Fragment>
                {(successfully)?(
                    <EmailCheckInbox />
                ):(
                    <React.Fragment>
                        <Formik
                            innerRef={(ref) => this.refForm = ref}
                            initialValues={this.state.inputs}
                            validationSchema={Yup.object().shape({
                                email: Yup.string().required("You must enter your ${path}").email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
                            })}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) =>
                                (
                                    <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                                        <FormTitle>{applicationContext.translator("Reset Password")}</FormTitle>
                                        <FormDescription>{applicationContext.translator("Enter your e-mail address to reset your password")}</FormDescription>
                                        <InputBox type="text"
                                                  name={"email"}
                                                  onChange={handleChange}
                                                  placeholder={applicationContext.translator("E-Mail Address")}
                                                  value={values.email}
                                                  save={"off"}
                                                  focus={true}
                                                  errors={errors}
                                                  touched={touched}
                                        />
                                        {error && <FormErrorText>{error}</FormErrorText>}
                                        <Form.Group className={"text-center"}>
                                            <Button color={"primary"} fullWidth={true} size={3} submitting={this.state.submitting} radius={10} className={"btn btn-account"} type={"submit"}>{applicationContext.translator("Reset Password")}</Button>
                                        </Form.Group>
                                    </Form>
                                )}
                        </Formik>

                        <Form.Group as={Row} className={"mt-5"}>
                            <Col className={"text-center"}>
                                <BlockText>
                                    {applicationContext.translator("Do you want use your mobile number?")} <Link to={ROUTE_RESET_PASSWORD_BY_MOBILE}>{applicationContext.translator("Click here to reset")}</Link>
                                </BlockText>
                            </Col>
                        </Form.Group>
                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }
}

export default withAlert()(withRouter(withApplicationContext(ResetByEmailForm)));