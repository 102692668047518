import React, {Component} from 'react';
import 'react-slideshow-image/dist/styles.css'
import {Slide} from "react-slideshow-image";
import {
    SlideshowSubItem,
    SlideshowContainer,
    SlideshowItem,
    SlideshowWrapper,
    BigText,
    SmallText,
    Wrapper, SelectTicketButtonContainer, Volume, Container
} from "./style";
import {Col, Row} from "react-bootstrap";
import moment from "jalali-moment";
import PropTypes from "prop-types";
import CountDown from "../CountDown";
import SelectTicketButton from "../../../scenes/EventTickets/SelectTicketButton";
import CommonService from "../../../services/CommonService";
import {generateUrl, slugify} from "../../../helpers";
import {ROUTE_EVENT_TICKETS} from "../../../routes/routes";
import withRouter from "../../../contexts/withRouter";
import ContentLoading from "../ContentLoading";
import {faVolumeMute, faVolumeHigh} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SlideShow extends Component {
    voice = null;
    constructor(props) {
        super(props);
        this.state = {
            eventInfo: null,
            images: this.props.images,
            volume: .5,
            loading: true
        }
    }
    componentWillUnmount() {
        if(this.voice){
            this.voice.pause();
            this.voice = null;
        }
    }

    componentDidMount = async () => {
        const {fetchUpComingEvents, event, playMusic, images} = this.props;
        let seconds = new Date().getTime();
        let slideshow = [];
        if(images){
            this.setState({
                images: images,
                loading: false
            });
            return;
        }
        if(fetchUpComingEvents){
            slideshow = await CommonService.doGet("/public/slideshow");
            setTimeout(() => {
                this.setState({
                    eventInfo: event,
                    images: slideshow,
                    loading: false
                })
            }, 1000 - (new Date().getTime() - seconds))
        }else if(event){
            if(playMusic && event.music){
                try{
                    this.voice = new Audio(event.music.url);
                    this.voice.volume = this.state.volume;
                    this.voice.play();
                    this.voice.addEventListener('ended', function() {
                        this.currentTime = 0;
                        this.play();
                    }, false);
                }catch (e){
                    console.log(e)
                }
            }

            slideshow = event.slideshows;
            this.setState({
                eventInfo: event,
                images: slideshow,
                loading: false
            })
        }


    }
    onClickSelectTicket = (eventItem) => {
        const {navigate} = this.props;
        navigate(generateUrl(ROUTE_EVENT_TICKETS, ["id", "slug"], [eventItem.id, slugify(eventItem.title)]));

    }
    onChangeVolume = (e) => {
        this.setState({
            volume: (this.state.volume === 1)?0:1
        }, () => {
            this.voice.volume = this.state.volume;
        })
    }
    renderFooter = (eventItem) => {
        const {isCountdown, isSelectTicket, playMusic} = this.props;
        let cols = [2,5,2,3];
        if(isSelectTicket) cols = [2,5,2,3];
        return (
            <SlideshowSubItem>
                <Row>
                    <Col md={cols[0]}>
                        <Wrapper className={"flex-center"}>
                            <BigText>{moment(eventItem['date_of_event'], 'YYYY/MM/DD').format('DD MMMM YYYY')}</BigText>
                        </Wrapper>
                    </Col>
                    <Col md={cols[1]}>
                        <Wrapper>
                            <BigText uppercase={true}>{eventItem.place.title}</BigText>
                            <SmallText>{eventItem.place.address}</SmallText>
                        </Wrapper>
                    </Col>
                    <Col md={cols[2]}>
                        <Wrapper noBorder={true}>
                            <BigText>Total seats left</BigText>
                            <SmallText>{eventItem.place.capacity - eventItem.attending}</SmallText>
                        </Wrapper>
                    </Col>
                    {isCountdown && <Col md={cols[3]}>
                        <Wrapper noBorder={true}>
                            <CountDown timer={new Date(eventItem['close_reservation'])} size={1}/>
                        </Wrapper>
                    </Col>}
                    {isSelectTicket && <SelectTicketButtonContainer md={cols[3]}>
                        <SelectTicketButton eventItem={eventItem} color={"white"}/>
                        {playMusic && <Volume onClick={this.onChangeVolume}><FontAwesomeIcon icon={this.state.volume === 1?faVolumeHigh:faVolumeMute} /></Volume>}
                    </SelectTicketButtonContainer>}
                </Row>
                {eventItem.caption}
            </SlideshowSubItem>
        )
    }
    renderSlides = () => {
        const {images, eventInfo} = this.state;
        const {fixedFooter, disabledFooter, height, disableArrows} = this.props;
        let arrows = false;
        if(images && images.length > 1 && !disableArrows)
            arrows = true;

        return (
            <Slide autoplay={images && images.length > 1} arrows={arrows} duration={5000} transitionDuration={600}>
                {images && images.length > 0 && images.map((eventItem, index)=> {
                    let url = eventInfo?eventItem.url:eventItem.slideshow.url;
                    eventItem = eventInfo??eventItem;
                    return (
                        <React.Fragment>
                            <SlideshowWrapper key={index}>
                                <SlideshowItem image={url} height={height} onClick={(e) => this.onClickSelectTicket(eventItem)}/>
                                {!fixedFooter && !disabledFooter && this.renderFooter(eventItem)}
                            </SlideshowWrapper>
                        </React.Fragment>
                    )
                })}
            </Slide>
        );
    }
    renderDataLoading = () => {
        const {loading} = this.state;
        const {disabledFooter} = this.props;
        if(!loading) return null;
        return (
            <ContentLoading height={"550px"} smHeight={"180px"}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="80%" />
                {!disabledFooter && <rect x="0" y="81%" rx="5" ry="5" width="100%" height="20%" />}
            </ContentLoading>
        )
    }
    render() {
        const {loading} = this.state;
        const {fixedFooter, event, disabledFooter} = this.props;
        return (
            <Container>
                <SlideshowContainer className={"slide-container"} fixedFooter={fixedFooter}>
                    {this.renderDataLoading()}
                    {!loading && this.renderSlides()}
                </SlideshowContainer>
                {fixedFooter && !disabledFooter && event && this.renderFooter(event)}
            </Container>
        );
    }
}
SlideShow.propTypes = {
    disableTimer: PropTypes.bool,
    images: PropTypes.array,
    isCountdown: PropTypes.bool,
    isSelectTicket: PropTypes.bool,
    fetchUpComingEvents: PropTypes.bool,
    playMusic: PropTypes.bool,
    fixedFooter: PropTypes.bool,
    disabledFooter: PropTypes.bool,
    height: PropTypes.number,
    disableArrows: PropTypes.bool
}
export default withRouter(SlideShow);