import React, {Component} from 'react';
import {TimerContainer, TimerNumber} from "./style";
import PropTypes from "prop-types";
import SpecialCountDown from "./SpecialCountDown";
import NormalCountDown from "./NormalCountDown";

class CountDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer: null
        }
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount = ( ) => {
        this.timer = setInterval(this.countDown, 1000);
    }
    secondsToTime = (secs) => {
        let timer = {
            days: Math.floor(secs / (1000 * 60 * 60 * 24)),
            hours: Math.floor((secs / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((secs / 1000 / 60) % 60),
            seconds: Math.floor((secs / 1000) % 60)
        };
        return timer;
    }
    countDown = () => {
        const {timer, onUpdateSeconds, onUpdateTime, onCompletedTime} = this.props;
        let seconds = timer.getTime() - new Date().getTime();
        let t = this.secondsToTime(seconds);
        if(onUpdateTime)
            onUpdateTime(t);
        if(onUpdateSeconds)
            onUpdateSeconds(seconds)

        if((t.days <= 0 && t.hours <= 0 && t.minutes <= 0 && t.seconds <= 0)){
            t = null;
            clearInterval(this.timer);
            if(onCompletedTime)
                onCompletedTime();
        }

        this.setState({
            timer: t
        });
    }
    onDeleteCountDown = () => {

    }
    render() {
        const {timer} = this.state;
        const {theme, size} = this.props;
        return (
            <React.Fragment>
                {!theme && <SpecialCountDown timer={timer} size={size}/>}
                {theme && theme === 'normal' && <NormalCountDown timer={timer} />}
            </React.Fragment>
        );
    }
}
CountDown.propTypes = {
    timer: PropTypes.any,
    onUpdateTime: PropTypes.func,
    onUpdateSeconds: PropTypes.func,
    onCompletedTime: PropTypes.func,
    theme: PropTypes.string
}
export default CountDown;