import styled from "styled-components";
import {Form, Row} from 'react-bootstrap';

export const PaymentContainer = styled.div`
  width: 100%;
  margin: 5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Card = styled.div`
    width: 100%;
    max-width: 440px;
    padding: 1.5em;
    box-shadow: 0 2px 5px var(--color-shadow-04), 0 0 2px var(--color-shadow-02);
    border: 1px solid var(--color-shadow-1);
    border-radius: var(--border-radius-3);
    margin-bottom: 1em;
    text-align: center;
`
export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    & svg{
        width: 64px;
        height: 64px;
        color: ${(props) => props.success?`#23a26d`:`#df230f`};
    }
`
export const OrderID = styled.h3`
    font-size: .9em;
    font-weight: 400;
`
export const Title = styled.h1`
    font-size: 1.4em;
    text-align:center;
    
`
export const Amount = styled.h3`
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1em;
`
export const Message = styled.p`
    font-size: 1em;
    font-weight: 400;
    text-align:center;
`
export const Items = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`
export const Item = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em 0;
    ${(props) => props.borderTop && `
        border-top: 1px solid var(--color-shadow-1);
        margin-top: .5em;
        padding-top: 1em;
    `}
    & b{
        font-size: 1em;
        font-weight: 500;
    }
     
`