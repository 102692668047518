import React, {Component} from 'react';
import {Container} from 'react-bootstrap';
import {withAlert} from "react-alert";
import {LoginContainer} from "./style.jsx";
import {ROUTE_ACCOUNT, ROUTE_HOME} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";
import StorageService from "../../services/StorageService";
import LoginForm from "./Forms/LoginForm";
import RegistrationOption from "./RegistrationOption";
import ForgetPasswordOption from "./ForgetPasswordOption";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false
        }

    }


    onLoggedIn = () => {
        const {navigate} = this.props;
        let redirect = (StorageService.get('redirect'))??ROUTE_HOME;
        StorageService.remove('redirect');
        navigate(redirect)
    }


    render() {
        return (
            <React.Fragment>
                <Container>
                    <LoginContainer>
                        <LoginForm onCallback={this.onLoggedIn}/>
                        <br/>
                        <RegistrationOption />
                        <ForgetPasswordOption />
                    </LoginContainer>
                </Container>
            </React.Fragment>


        );
    }
}

export default withAlert()((withRouter(Login)));