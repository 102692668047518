import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Col} from "react-bootstrap";
export const HeaderBar = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1em;
`;
export const HeaderBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  
`;
export const HeaderLogo = styled.img`
  height: 100px;
  width: auto;
  margin-top: 30px;
  @media screen and (max-width: 700px) {
    height: 60px;
    margin-top: 10px;
  }
`;
export const HeaderMenuContainer = styled(Col)`
    & .hamburger-icon{
        display: none;
    }
   @media screen and (max-width: 700px) {
    display: flex;
      justify-content: flex-end;
      align-items: center;  
      & .hamburger-icon, .close-bar{
        display: block;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
     
      
  }
  
`
export const HeaderMenuWrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 1.5em;
  & .mobile-social{
    display:none;
  }
  @media screen and (max-width: 700px) {
    right: -100%;
    display: block;
    position: fixed;
    width: 100%;
    text-align: center;
    background: var(--color-background);
    height: 100vh;
    z-index: 999999;
    margin-top: 0;
    top: 0;
    padding: 3em 0;
    & .mobile-social{
     display:block;
    }
    & li{
        padding: 1em;
    }
    transition: all linear .3s;
    ${(props) => props.show && `
        right: 0;
        transition: all linear .3s;
        
        & .close-bar{
            position: absolute;
            right: 3em;
            top: 2em;
        }
    `}
  }
`;
export const HeaderMenu = styled.li`
    padding: 0 2em;
  & a{
    color: var(--color-text);
    text-transform: uppercase;
    font-weight: 500;
    &:hover{
      color: var(--color-primary);
    }
  }
`;
export const AccountAreaContainer = styled.div`
  padding: 2.5em 0px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  @media screen and (max-width: 700px) {
    padding: 0 1em 0 0;
    & .mobile-social{
        display:none;
      }
  }
`
export const AccountAreaButton = styled.div`
  cursor: pointer;
  position: relative;
  transition: all ease-in-out .3s;
  &:before{
    content: "";
    position: absolute;
    top: -.25em;
    left: -.25em;
    width: 3.5em;
    height: 3.5em;
    border-radius: 50%;
    background-color: var(--color-shadow-03);
    z-index: -1;
    transform: scale(.4);
    opacity: 0;
    transition: all .2s ease-out;
  }
  & svg{
    fill: var(--color-shadow-2);
    transition: all ease-in-out .3s;
    transform: scale(1);
  }
  &:hover{
    &:before{
      transform: scale(1);
      opacity: 1;
    }
    & svg{
      fill: var(--color-shadow-3);
      transform: scale(1.15);
    }
    transition: all ease-in-out .3s;
  }
`
export const AccountAreaDropDown = styled.div`
  @keyframes smooth-appear {
    to{
      top: 3.5em;
    }
  }
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  display: none;
  width: 300px;
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
  background: var(--color-background);
  height: fit-content;
  box-shadow: 0 2px 6px var(--color-shadow-08), 0 0 4px var(--color-shadow-06);
  border-radius: var(--border-radius-2);;
  padding: 2em;
  transition: all linear .3s;
  animation: smooth-appear .4s ease forwards;
  cursor: initial;
  ${(props) => props.open && `
        display: block;
  `}
  & hr{
    border-color: var(--color-shadow-4);
  }
  & a{
    display: block;
    color: var(--color-shadow-6);
    padding: 0.5em 0;
    &:hover{
      color: var(--color-primary);
    }
    &:first-child{
      padding-top: 0;
    }
    &:last-child{
      padding-bottom: 0;
    }
  }
  & .menu-text{
    font-size: .95em;
  }
`