
export const ROUTE_NOT_FOUND = `/404-not-found`;
export const ROUTE_HOME = `/`;
export const ROUTE_LOGIN = `/login`;
export const ROUTE_REGISTER = `/register`;
export const ROUTE_RESET_PASSWORD_BY_EMAIL = `/reset-password/email`;
export const ROUTE_RESET_PASSWORD_BY_MOBILE = `/reset-password/mobile`;
export const ROUTE_RESET_PASSWORD = `/reset-password/change/:url`;
export const ROUTE_MOBILE_VERIFICATION = `/register/mobile/:hash`;
export const ROUTE_EVENT_TICKETS = `/event-tickets/:id/:slug`;
export const ROUTE_CART = `/cart/:id/:slug`;
export const ROUTE_MUSICIANS = `/musicians`;
export const ROUTE_ABOUT = `/about`;
export const ROUTE_CHECKOUT = `/order/checkout/:basket_id`;
export const ROUTE_NOTFOUND = `/404`;
export const ROUTE_PAYMENT = `/payment/:status`;
export const ROUTE_ACCOUNT = `/account/profile`;
export const ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL = `/account/profile/update/email/inbox`;
export const ROUTE_ACCOUNT_UPDATE_EMAIL = `/account/profile/email/update/:hash`;
export const ROUTE_ACCOUNT_UPDATING_EMAIL = `/account/profile/email/updating/:hash`;
export const ROUTE_ACCOUNT_REQUEST_UPDATE_MOBILE = `/account/profile/update/mobile`;
export const ROUTE_ACCOUNT_ENTER_NEW_MOBILE = `/account/profile/update/mobile/enter`;
export const ROUTE_ACCOUNT_TICKETS = `/account/tickets`;
export const ROUTE_ACCOUNT_PAYMENTS = `/account/payments`;
export const ROUTE_ACCOUNT_CHANGE_PASSWORD = `/account/change/password`;


export const ROUTE_ADMIN_LOGIN = `/administrator/login`;
export const ROUTE_ADMIN_OVERVIEW = `/administrator/overview`;
export const ROUTE_ADMIN_ALL_MEMBERS = `/administrator/members`;
export const ROUTE_ADMIN_EVENT_PLACES = `/administrator/event-places`;
export const ROUTE_ADMIN_ADD_EVENT_PLACE = `/administrator/event-places/add`;
export const ROUTE_ADMIN_UPDATE_EVENT_PLACE = `/administrator/event-places/update/:id`;
export const ROUTE_ADMIN_ARTISTS = `/administrator/artists`;
export const ROUTE_ADMIN_ADD_ARTIST = `/administrator/artists/add`;
export const ROUTE_ADMIN_UPDATE_ARTIST = `/administrator/artists/update/:id`;
export const ROUTE_ADMIN_EVENTS = `/administrator/events`;
export const ROUTE_ADMIN_ADD_EVENT = `/administrator/events/add`;
export const ROUTE_ADMIN_UPDATE_EVENT = `/administrator/events/update/:id`;
export const ROUTE_ADMIN_TICKETS = `/administrator/tickets`;
export const ROUTE_ADMIN_TICKET_VALIDITY = `/administrator/ticket/validity/:id`;
export const ROUTE_ADMIN_PAYMENTS = `/administrator/payments`;
