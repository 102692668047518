import StorageService from "./StorageService";

const getLocalAccessToken = () => {
    let token = StorageService.get('admin-token', true);
    return token?.token
};

const updateLocalAccessToken = (token) => {
    StorageService.set('admin-token', token, true)
};

const getToken = () => {
    return StorageService.get('admin-token', true);
};

const getUser = () => {
    return StorageService.get('admin-info', true);
};

const setUser = (user) => {
    StorageService.set("admin-info", user, true);
};

const removeUser = () => {
    StorageService.remove(["admin-info", "admin-token"]);
};

const AdminTokenService = {
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
    getToken
};

export default AdminTokenService;