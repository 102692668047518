import React, {Component} from 'react';
import {
    CardContainer, SummaryContainer, SummaryRow, Title
} from "../style";
import {withCheckoutContext} from "./CheckoutContext"
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
class Summary extends Component {

    render() {
        const {checkoutContext, applicationContext} = this.props;
        const {cart} = checkoutContext.state;
        return (
            <React.Fragment>
                <Title>{applicationContext.translator("Summary")}</Title>
                <CardContainer>
                    <SummaryContainer>
                        <SummaryRow>
                            <b>Tickets</b>
                            <div>{cart.summary.total}</div>
                        </SummaryRow>
                        {cart.summary.checkout.additional.map((row, index) => (
                            <SummaryRow key={index}>
                                <b>{row.label}</b>
                                <div>{row.price}</div>
                            </SummaryRow>
                        ))}
                        <SummaryRow topLine={true}>
                            <b>{applicationContext.translator("Total incl. VAT")}</b>
                            <div>{cart.summary.checkout.total}</div>
                        </SummaryRow>



                    </SummaryContainer>
                </CardContainer>
            </React.Fragment>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withCheckoutContext(Summary))));