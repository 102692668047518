import React, {Component} from 'react';
import {Container, PopupContainer, Header, Title, Body, Icon, Footer} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SortIcon} from "../DataList/style";
class Popup extends Component {

    render() {
        const {applicationContext, title, children, footer, onClose, submitting, size} = this.props;

        return (
            <Container dir={applicationContext.translator("direction")}>
                <PopupContainer size={size??"md"}>
                    <Header>
                        {title && <Title>{applicationContext.translator(title)}</Title>}
                        <Icon onClick={(e) => {
                            applicationContext.onCloseConfirmPopup();
                            if(onClose) onClose();
                        }}><FontAwesomeIcon icon={"times"}/></Icon>
                    </Header>
                    <Body>
                        {children}
                    </Body>
                    {footer &&
                    <Footer disabled={submitting}>
                        {footer}
                    </Footer>
                    }
                </PopupContainer>
            </Container>
        );
    }
}
Popup.propTypes = {
    size: PropTypes.string.isRequired,
    title: PropTypes.string,
    footer: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
}
export default withApplicationContext(Popup);