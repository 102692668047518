import React, {Component} from 'react';
import {Container, Select} from "./style.jsx";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Row} from "react-bootstrap";
import {InputGroup, InputCol, Label, Wrapper, InputContainer, InputError} from "../InputBox/style";
class Dropdown extends Component {
    refLabel = React.createRef();
    render() {
        const {floatLabel, line, label, paddingAround, margin, padding, invalid, data, noInvalidIcon, children, touched, value, errors, name, onChange, applicationContext, className, boxClassName} = this.props;
        return (
            <React.Fragment>
                <InputGroup as={Row} margin={margin} padding={padding} floatLabel={floatLabel} className={boxClassName}>
                    <Wrapper line={line} padding={padding} paddingAround={paddingAround}>
                        {label && <Label floatLabel={floatLabel} ref={(ref) => this.refLabel = ref}>{label} {line?":":""}</Label>}
                        <Select
                            name={name}
                            onChange={onChange}
                            value={value}
                            dir={applicationContext.translator("direction")}
                            noInvalidIcon={noInvalidIcon}
                            isInvalid={invalid?invalid:(touched && touched[name] && errors[name])}
                            ref={(ref) => this.refInput = ref}
                            className={className}
                        >
                            {data?
                            <React.Fragment>
                                <option value={""}>{applicationContext.translator("Please choose...")}</option>
                                {data.map((item, index) => {
                                    return <option value={item.key}>{applicationContext.translator(item.label)}</option>
                                })}
                            </React.Fragment>
                            :children}
                        </Select>
                    </Wrapper>
                    {touched && touched[name] && <InputError paddingLeft={line && this.refLabel.offsetWidth+"px"} hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
                </InputGroup>
            </React.Fragment>
        );
    }
}
Dropdown.propTypes = {
    margin: PropTypes.string,
    invalid: PropTypes.bool,
    padding: PropTypes.string,
    fullWidth: PropTypes.bool,
    size: PropTypes.number,
    fontSize: PropTypes.string,
    line: PropTypes.bool,
    floatLabel: PropTypes.bool,
    label: PropTypes.string,
    data: PropTypes.array,
    className: PropTypes.string,
    boxClassName: PropTypes.string,
    paddingAround: PropTypes.string,
}
export default withApplicationContext(Dropdown);