import React, {Component} from 'react';
import {convertToDate, formatBytes} from "../../../helpers";
import {InputError} from "../InputBox/style";
import {FileInfoContainer, Hint} from "./style";
import {withUploaderContext} from "./UploaderContext";
import PropTypes from "prop-types";

class FileInfo extends Component {
    render() {
        const {fileInfo, error, context, placeholder} = this.props;
        const {fileTypes, accept, maxSize} = context;
        return (
            <FileInfoContainer>
                {fileInfo &&
                    <React.Fragment>
                        <h1><b>FileName:</b> {fileInfo['file_name']}</h1>
                        <p><b>Size:</b> {formatBytes(fileInfo['file_size'])}</p>
                        <p><b>Mime:</b> {fileInfo['mime_type']}</p>
                        <p><b>Created:</b> {convertToDate(fileInfo['created_at'], 'en')}</p>
                    </React.Fragment>}
                {error && <InputError>{error}</InputError>}
                {!fileInfo &&
                    <Hint error={error}>
                        {placeholder && <p>{placeholder}</p>}
                        {fileTypes && <p><b>Allow files:</b> {fileTypes.join(', ')}</p>}
                        {accept && <p><b>Allow extensions:</b> {accept}</p>}
                        {maxSize && <p><b>Maximum Size:</b> {formatBytes(maxSize)}</p>}
                    </Hint>
                }
            </FileInfoContainer>
        );
    }
}
FileInfo.propTypes = {
    fileInfo: PropTypes.object,
    error: PropTypes.any,
}
export default withUploaderContext(FileInfo);