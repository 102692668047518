import React, {Component} from 'react';
import {Amount, Card, Icon, Item, Items, Message, OrderID, Title} from "./style";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";
import Button from "../../components/ui/Button";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {ROUTE_ACCOUNT, ROUTE_HOME} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";
import moment from "jalali-moment";
import Spinner from "../../components/ui/Spinner/Spinner";
import {Loading} from "../../components/ui/InputTags/style";
import PageNotFound from "../../components/ui/PageNotFound";
import CommonService from "../../services/CommonService";

class PaymentCancel extends Component {
    constructor(props) {
        super(props);
        const urlSearchParams = new URLSearchParams(props.location.search);
        this.state = {
            ticketId: urlSearchParams.get('ticket_id'),
            driver: urlSearchParams.get('driver'),
            payment: null,
            notFound: false,
            loading: true
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // get-payment-by-ticket-id
    }
    routeToNotFound = () => {
        this.setState({
            notFound : true,
            loading: false
        })
    }
    componentDidMount = async () => {
        const {ticketId} = this.state;
        const {params, applicationContext, accountContext} = this.props;
        let payment = await CommonService.doGet(`/payment/get_payment_by_ticket_id`, {
            ticket_id: ticketId
        }).then((data) => data.data).catch((e) => {
            this.routeToNotFound();
        });
        if(payment){
            this.setState({
                loading: false,
                payment
            })
        }else{
            this.routeToNotFound();
        }
    }

    onClickToHome = (e) => {
        const {navigate} = this.props;

        navigate(ROUTE_ACCOUNT);
    }
    renderDataLoading = () => {
        const {loading} = this.state;
        if (!loading) return null;
        return (
            <Card>
                <Loading><Spinner size={"sm"} show={loading} inline={true}/></Loading>
            </Card>
        );
    }
    renderViewComponent = () => {
        const {loading, notFound, payment} = this.state;
        const {applicationContext} = this.props;
        if (loading) return null;
        if (notFound) return (<PageNotFound/>);
        return (
            <Card>
                <Icon success={true}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                </Icon>
                <Title>{applicationContext.translator("Payment Success!")}</Title>
                <Amount>{payment.order['basket_details']['summary']['checkout']['total']}</Amount>
                <Items>
                    <Item borderTop={true}>
                        <b>{applicationContext.translator("Ref Number")}</b>
                        <div>#{payment.order.id}</div>
                    </Item>
                    <Item>
                        <b>{applicationContext.translator("Payment time")}</b>
                        <div>{moment(new Date(payment['callback_at']), 'YYYY-MM-DD').format('DD MMMM YYYY HH:mm:ss')}</div>
                    </Item>
                    <Item>
                        <b>{applicationContext.translator("Payment method")}</b>
                        <div>{this.state.driver}</div>
                    </Item>
                    <Item>
                        <b>{applicationContext.translator("Payer")}</b>
                        <div>{payment.order.person['full_name']}</div>
                    </Item>
                    <Item borderTop={true}>
                        <b>{applicationContext.translator("Total excl. VAT")}</b>
                        <div>{payment.order['basket_details']['summary']['checkout']['excl_vat']}</div>
                    </Item>
                    <Item>
                        <b>{applicationContext.translator("VAT")}</b>
                        <div>{payment.order['basket_details']['summary']['checkout']['vat']}</div>
                    </Item>
                    <Item>
                        <b>{applicationContext.translator("Total")}</b>
                        <div>{payment.order['basket_details']['summary']['checkout']['total']}</div>
                    </Item>
                </Items>


                <Button color="primary" size="md" margin={"1em"} onClick={this.onClickToHome}>
                    {applicationContext.translator("Go to your account")}
                </Button>
            </Card>
        );
    }
    render() {
        const {loading} = this.state;
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                {this.renderDataLoading()}
                {this.renderViewComponent()}
            </React.Fragment>
        );
    }
}

export default withRouter(withApplicationContext(PaymentCancel));