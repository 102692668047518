import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Text} from "./style";

class WorkDays extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Text small={true} primary={true}>{applicationContext.translator("All weekdays, 10 AM to 10 PM")}</Text>
            </React.Fragment>
        );
    }
}

export default withApplicationContext(WorkDays);