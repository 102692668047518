import styled from "styled-components";
import {Col, Form} from "react-bootstrap";
export const InputSearch = styled.div`
  width: 80px;
  height: 100%;
  padding: 0.6em 0.8em;
  &:active, &:focus{
    outline: 0px solid transparent;
  }
`
export const InputContainer = styled.div`
    border-radius: var(--border-radius-2);
    background: var(--form-element-background);
    color: var(--form-element-text-color);
    border:1px solid var(--color-shadow-08);
    ${(props) => props.isInvalid && `
      border-color: var(--bs-form-invalid-border-color);
    `}
    font-family: inherit;
    width: 100%;
    cursor: text;
    padding: .4em;
    transition: all ease-in-out .2s;
    position: relative;
    &.is-focused{
        transition: all ease-in-out .2s;
        outline: 0px solid transparent;
        box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
        border:1px solid  var(--color-shadow-2) !important;
        color: var(--form-element-text-color) !important;
        background: var(--form-element-background) !important;
    }
    display: flex;
    align-items: center;
  
`
export const Container = styled.div`
    position: relative;
`;
export const Label = styled(Form.Label)`
  font-weight: 500;
  color: var(--form-element-text-color);
  ${(props) => props.floatLabel && `
          position: absolute;
          top: -0.8em;
          right: 1em;
          background: var(--form-element-background);
          padding: 0.2em 0.5em;
          text-align: right;
          border-radius: var(--border-radius-1);
          margin-bottom: 0;
          color: var(--form-element-text-color-2);
  `}

`;
export const InputCol = styled(Col)`
  position: relative;
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`

export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  ${(props) => props.padding && `
        padding: ${props.padding} !important;
  `}
`
export const SuggestionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const SuggestionListItem = styled.li`
  padding: .6em 1em;
  cursor: pointer;
  transition: all ease-in-out .2s;
  font-size: .9em;
  &:hover{
    background: var(--color-shadow-09);
    color: var(--color-shadow-8);
    transition: all ease-in-out .2s;
  }
`
export const SuggestionContainer = styled.div`
    width: 100%;
    max-height:250px;
    border-radius: var(--border-radius-2);
    background: var(--form-element-background);
    color: var(--form-element-text-color);
    border:1px solid var(--color-shadow-1);
    position: absolute;
    left: 0;
    right: 0;
    ${(props) => props.top && `top: ${props.top + 1}px;`} 
    z-index: 99999;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 7px;
      border-radius: var(--border-radius-1);
    }
    
    ::-webkit-scrollbar-track {
      background: var(--form-element-background);
      border-radius: var(--border-radius-1);
    }
    
    ::-webkit-scrollbar-thumb {
      background: var(--color-shadow-2);
      border-radius: var(--border-radius-1);
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: var(--color-shadow-3);
    }
    @keyframes smooth-appear-list {
      from{
        opacity:0.8;
      }
      to{
        opacity:1;
      }
    }
    animation: smooth-appear-list .2s linear forwards;
`
export const Wrapper = styled.div`
  position: relative;
  ${(props) => props.line && `
        display: flex;
        justify-content: space-between;
        align-items: center;      
        & .form-label{
            min-width: 100px;
            margin-bottom: 0;
        }
  `}
  ${(props) => props.padding && ` padding:${props.padding} !important; `}
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`
export const Selected = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`
export const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-primary);
  color: var(--color-text);
    margin: 0.2em;
    border-radius: var(--border-radius-1);
    padding: 0.4em 0.8em;
    & svg{
      cursor: pointer !important;
      margin-left:0.5em;
      &:hover{
        color: var(--color-shadow-7);
      }
    }
`
export const Loading = styled.div`
  padding: .8em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`