import React from 'react';
import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme.css';
import './assets/css/App.module.css';
import './assets/css/main.css';
import "./functions";
import 'react-calendar/dist/Calendar.css';
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import {Component, Suspense} from "react";
import {Translation} from 'react-i18next'
import Loading from "./components/ui/Loading";
import {ApplicationProvider} from "./contexts/ApplicationContext";
import {positions, Provider} from "react-alert";
import CustomAlertTemplate from "./components/ui/Alert";
import Routers from "./routes/Routers";
import StorageService from "./services/StorageService";
import CommonService from "./services/CommonService";
import {AppContainer} from "./components/ui/AppStyle";
const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

library.add(...iconList);



class App extends Component {
    voice = null;
    constructor(props) {
        super(props);
        this.state = {
            locale: process.env.REACT_APP_DEFAULT_LOCALE,
            ticketPrice: 1,
            config: {},
            states: [],
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            lastLottery: null,
            firstLottery: null,
            wallet: "",
            popup: {
                show: false,
                title: "",
                component: null,
                size: "md"
            },
            confirmPopup: {
                show: false,
                title: "",
                component: null,
                footer: null,
                size: "md",
                onClose: null,
                submitting: false
            },
            theme: null,
            common: null,
            holderData: null,
            loading: true
        };
    }


    componentDidMount = async () => {
        this.onChangeTheme();
        let common = await CommonService.fetchCommonData();
        this.setState({
            common,
            loading: false
        });
    }

    onChangeTheme = (theme = null) => {
        if(theme === null){
            if(StorageService.get("selected-theme")){
                theme = StorageService.get("selected-theme");
            }else{
                theme = "light-theme";
            }
        }
        if(theme === "auto"){
            if(StorageService.get("selected-theme") === "light-theme")
                theme = "dark-theme"
            else
                theme = "light-theme"
        }
        document.body.classList.remove("light-theme");
        document.body.classList.remove("dark-theme");
        document.body.classList.add(theme);
        StorageService.set("selected-theme", theme);
        this.setState({
            theme
        })
    }

    onOpenPopup = (title, component, size = "md", show = true) => {
        let popup = {
            show,
            title,
            component,
            size
        }
        this.setState({
            popup
        })
    }
    onClosePopup = () => {
        this.onOpenPopup("", null, "md",false)
    }
    doUpdateLocale = (locale, callback) => {
        this.setState({
            locale
        }, async () => {
            await i18n.changeLanguage(locale);
            return callback();
        })
    }

    onOpenConfirmPopup = (title,
                          component,
                          footer,
                          onClose = null,
                          size = "md",
                          show = true,
                          callback = () => {}
    ) => {
        let popup = {
            show,
            title,
            component,
            footer,
            size,
            onClose,
            submitting: false
        }
        this.setState({
            confirmPopup: popup
        }, callback)
    }
    onCloseConfirmPopup = (e, callback = () => {}) => {
        this.onOpenConfirmPopup("", null,null, null, "md",false, callback)
    }
    onUpdatePopupSubmitting = (submitting) => {
        this.setState({
            confirmPopup: {
                ...this.state.confirmPopup,
                submitting
            }
        })
    }
    updateLastLottery = (data, callback) => {
        this.setState({
            lastLottery: data
        }, callback)
    }

    playSound = (name) => {
        if(this.voice && !this.voice.paused) return false;
        this.voice = new Audio(`/assets/voices/${this.state.locale}/${name}.mp3`);
        this.voice.play();
    }

    doChangePageTitle = (title = null) => {
        if(title !== null)
            document.title = title;
        else
            document.title = process.env.REACT_APP_TITLE;
    }
    doUpdateState = (state) => {
        this.setState(state)
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading?(
                    <Loading animation={true}/>
                ):(
                    <Suspense fallback={<Loading bg={"transparent"}/>}>
                        <Translation>
                            {(t, {i18n}) => (
                                <ApplicationProvider value={{
                                    state: this.state,
                                    onOpenPopup: this.onOpenPopup,
                                    onClosePopup: this.onClosePopup,
                                    onOpenConfirmPopup: this.onOpenConfirmPopup,
                                    onCloseConfirmPopup: this.onCloseConfirmPopup,
                                    onUpdatePopupSubmitting: this.onUpdatePopupSubmitting,
                                    doUpdateLocale: this.doUpdateLocale,
                                    playSound: this.playSound,
                                    doChangePageTitle: this.doChangePageTitle,
                                    onChangeTheme: this.onChangeTheme,
                                    doUpdateState:this.doUpdateState,
                                    translator: t,
                                    i18n: i18n
                                }}>
                                    <Provider template={CustomAlertTemplate} {...{
                                        timeout: 6000,
                                        position: positions.TOP_CENTER
                                    }}>
                                        <AppContainer direction={t("direction")}>
                                            <Routers/>
                                        </AppContainer>
                                    </Provider>
                                </ApplicationProvider>
                            )}
                        </Translation>

                    </Suspense>
                )}

            </React.Fragment>


        );
    }
}

export default App;
