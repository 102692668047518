import styled from "styled-components";
import {Form} from 'react-bootstrap';

export const LoginContainer = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 5em auto;
`
export const BlockText = styled(Form.Text)`
  font-size: 1em;
  color: #333;
  & a{
    display: inline;
    color: var(--color-secondary);
    margin: 0 .3em;
  }
`
export const FormTitle = styled.h5`
  margin-bottom: 1.5em;
  font-size: 1.2em;
  font-weight: 500;
  color: #444;
  text-align: center;
`

export const StatusLabel = styled.div`
    ${(props) => props.status === "PENDING" && `color: #dda600;`}
    ${(props) => props.status === "SUCCESSFULLY" && `color: #198754;`}
    ${(props) => props.status === "CANCEL" && `color: #dc3545;`}
    ${(props) => props.status === "ERROR" && `color: #dc3545;`}
`