import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {Col, Row} from "react-bootstrap";
import {SupportFooterContainer, Text, TicketSaleCol, TicketSaleIcon, Title} from "./style";

class TicketSaleFooter extends Component {
    render() {
        const {applicationContext} = this.props;
        return (
            <SupportFooterContainer margin={"2em 0"}>
                <Row>
                    <TicketSaleCol md={3}>
                        <TicketSaleIcon src={"/assets/images/secure-checkout.svg"} />
                        <Text bold={true} marginTop={".5em"}>Secure checkout</Text>
                        <Text>Fast & secured payment</Text>
                    </TicketSaleCol>
                    <TicketSaleCol md={3}>
                        <TicketSaleIcon src={"/assets/images/instant-confirmation.svg"} />
                        <Text bold={true} marginTop={".5em"}>Instant confirmation</Text>
                        <Text>Refund guarantee options</Text>
                    </TicketSaleCol>
                    <TicketSaleCol md={3}>
                        <TicketSaleIcon src={"/assets/images/official-ticket-seller.svg"} />
                        <Text bold={true} marginTop={".5em"}>Official ticket seller</Text>
                        <Text>Used by 3m+ people</Text>
                    </TicketSaleCol>
                    <TicketSaleCol md={3}>
                        <TicketSaleIcon src={"/assets/images/customer-service.svg"} />
                        <Text bold={true} marginTop={".5em"}>24/7 Customer service</Text>
                        <Text>Reliable after sales support</Text>
                    </TicketSaleCol>
                </Row>
            </SupportFooterContainer>
        );
    }
}

export default withApplicationContext(TicketSaleFooter);