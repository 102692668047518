import React, {Component} from 'react';
import {withCartContext} from "./CartContext"
import withRouter from "../../contexts/withRouter";
import {BackButtonContainer} from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import {generateUrl, slugify} from "../../helpers";
import {ROUTE_EVENT_TICKETS} from "../../routes/routes";
import {Row,Col} from "react-bootstrap";
import Link from "../../components/ui/Link";
class BackToEventTicket extends Component {
    render() {
        const {cartContext} = this.props;
        const {eventItem} = cartContext.state;
        return (
            <Row>
                <BackButtonContainer md={12}>
                    <Link to={generateUrl(ROUTE_EVENT_TICKETS, ["id", "slug"], [eventItem.id, slugify(eventItem.title)])}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                    <h1>{eventItem.title}</h1>
                </BackButtonContainer>
            </Row>

        );
    }
}

export default withCartContext(withRouter(BackToEventTicket));