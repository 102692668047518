import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {AdminProvider} from "../../contexts/AdminContext";
import {Container} from "./style";
import Loading from "../../components/ui/Loading";
import AdminTokenService from "../../services/AdminTokenService";
import AdminService from "../../services/AdminService";
import {Outlet} from "react-router-dom";
import {ROUTE_ADMIN_LOGIN} from "../../routes/routes";
import withRouter from "../../contexts/withRouter";
import Popups from "../../Popups";


class BaseMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            userInfo: {},
            loading: true
        }
    }
    handleRedirectionToLogin = async ()  => {
        const {applicationContext, navigate} = this.props;
        let userInfo = await this.fetchUserInfo();
        if(!userInfo){
            this.setState({ loading: false })
            navigate(ROUTE_ADMIN_LOGIN);
            return;
        }
        applicationContext.doUpdateLocale('en', () => {
            this.setState({
                userInfo,
                loggedIn: (userInfo)?true:false,
                loading: false
            })
        })
        return userInfo;
    }
    componentDidMount = async () => {
        document.body.classList.remove("light-theme")
        document.body.classList.remove("dark-theme")
        document.body.classList.add('admin-theme');
        await this.handleRedirectionToLogin();
    }
    fetchUserInfo = async () => {
        let userInfo = null;
        if(AdminTokenService.getLocalAccessToken()){
            try{
                userInfo = await AdminService.getUserBoard();
            }catch (e){
                console.log(e)
            }
        }
        return userInfo;
    }
    updateUserInfo = (data, callback, loggedIn = true) => {
        this.setState({
            loggedIn: loggedIn,
            userInfo: data
        }, callback)
    }

    handleLogout = async (callback) => {
        AdminTokenService.removeUser();
        this.setState({
            loggedIn: false,
            userInfo: {},
        }, callback)
    }

    render() {
        const {applicationContext, adminContext, children} = this.props;
        return (
            <AdminProvider value={{
                state: this.state,
                updateUserInfo: this.updateUserInfo,
                handleLogout: this.handleLogout
            }}>
                {this.state.loading?(
                    <Loading animation={true} />
                ):(
                    <Container>
                        <Outlet />
                        <Popups />
                    </Container>
                )}
            </AdminProvider>
        );
    }
}

export default withApplicationContext(withRouter(BaseMaster));