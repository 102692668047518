import React, {Component} from 'react';
import {withEventTicketsContext} from "./EventTicketsContext";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {CoverImage} from "./style";

class Cover extends Component {
    render() {
        const {context} = this.props;
        const {eventItem} = context.state;
        if(!eventItem.banner) return null;
        return (
            <React.Fragment>
                <CoverImage src={eventItem.banner.url} />
            </React.Fragment>
        );
    }
}

export default withApplicationContext(withEventTicketsContext(Cover));