import React, {Component} from 'react';
import {SeatContainer, StageLabel} from "./style";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withStageMapContext} from "./StageMapContext";
import PropTypes from "prop-types";

class StageLocation extends Component {
    render() {
        const {context, align, applicationContext} = this.props;
        const {seats} = context;
        return (
            seats.hasOwnProperty('stage_data') && seats['stage_data'].hasOwnProperty('align') && seats['stage_data']['align'] === align?(
                <SeatContainer>
                    <StageLabel>
                        <div>{applicationContext.translator("Stage")}</div>
                    </StageLabel>
                </SeatContainer>
            ):(null)
        )
    }
}
StageLocation.propTypes = {
    align: PropTypes.string
}
export default withApplicationContext(withStageMapContext(StageLocation));