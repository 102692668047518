import React from "react";
const StageMapContext = React.createContext({});
const StageMapProvider = StageMapContext.Provider;
const StageMapConsumer = StageMapContext.Consumer;
const withStageMapContext = Component => React.forwardRef((props, ref) => (
    <StageMapConsumer>
        {(response) => {
            return <Component {...props} context={response} ref={ref}/>;
        }}
    </StageMapConsumer>
));
export { StageMapProvider, StageMapConsumer, withStageMapContext };
