import React, {Component} from 'react';
import * as Yup from "yup";
import {Col, Form, Row} from "react-bootstrap";
import {BlockText, FormTitle} from "../style";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button/Button";
import {ROUTE_LOGIN} from "../../../routes/routes";
import {Formik} from "formik";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import PropTypes from "prop-types";
import Link from "../../../components/ui/Link";
import CountryDropDown from "../../../components/ui/CountryDropDown";
import {passwordCheck} from "../../../helpers";

class EnterInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {
                email: "",
                first_name: "",
                last_name: "",
                mobile: "",
                mobile_country: props.applicationContext.state.common['default_country']['mobile_prefix_clear'],
                password: "",
                password_confirmation: "",
            },
            showPassword: false,
            showRepeatPassword: false,
        }
    }

    validate = () => {
        const {applicationContext} = this.props;
        return Yup.object().shape({
            email: Yup.string().required("You must enter your ${path}").email(applicationContext.translator("E-Mail Address format is invalid.")).label(applicationContext.translator("E-Mail Address")),
            first_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).min(3, applicationContext.translator("You must enter minimum 3 characters")).label(applicationContext.translator("First name")),
            last_name: Yup.string().required(applicationContext.translator("You must enter your ${path}")).min(3, applicationContext.translator("You must enter minimum 3 characters")).label(applicationContext.translator("Last name")),
            mobile: Yup.string().required("You must enter your ${path}").min(9, applicationContext.translator("You must enter minimum 9 digits")).label(applicationContext.translator("Mobile number")),
            password: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                .test('password', applicationContext.translator("The password must contain at least 8 english characters and include upper and lower case letters, numbers and a symbol"), (value) => {
                    let check = passwordCheck(value);
                    return (check.message === "Strong")
                })
                .label(applicationContext.translator("Password")),
            password_confirmation: Yup.string().required(applicationContext.translator("You must enter your ${path}"))
                .oneOf([Yup.ref('password'), null], applicationContext.translator('Passwords must match'))
                .label(applicationContext.translator("Password Confirmation"))
        })

    }
    render() {
        const {applicationContext, onSubmit, loginHint, submitting, submitLabel} = this.props;
        return (
            <Formik
                innerRef={(ref) => this.refForm = ref}
                initialValues={this.state.inputs}
                validationSchema={this.validate()}
                onSubmit={onSubmit}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) =>
                    (
                        <Form onSubmit={handleSubmit} style={{ direction: applicationContext.translator("direction") }}>
                            <FormTitle>{applicationContext.translator("Enter your information and follow the instructions sent to you by email.")}</FormTitle>
                            <Row>
                                <Col md={12}>
                                    <InputBox type="text"
                                              name={"email"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("E-Mail Address")}
                                              value={values.email}
                                              save={"off"}
                                              focus={true}
                                              errors={errors}
                                              touched={touched}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type="text"
                                              name={"first_name"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("First name")}
                                              value={values.first_name}
                                              save={"off"}
                                              focus={true}
                                              errors={errors}
                                              touched={touched}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type="text"
                                              name={"last_name"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Last name")}
                                              value={values.last_name}
                                              save={"off"}
                                              focus={true}
                                              errors={errors}
                                              touched={touched}
                                    />
                                </Col>
                                <Col md={6}>
                                    <CountryDropDown
                                        name={"mobile_country"}
                                        onChange={setFieldValue}
                                        value={values.mobile_country}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type="text"
                                              onlyNumber={true}
                                              name={"mobile"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Mobile number")}
                                              value={values.mobile}
                                              maxLength={12}
                                              errors={errors}
                                              touched={touched}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type={"password"}
                                              name={"password"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Password")}
                                              value={values.password}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                              strength={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <InputBox type={"password"}
                                              name={"password_confirmation"}
                                              onChange={handleChange}
                                              placeholder={applicationContext.translator("Password Confirmation")}
                                              value={values.password_confirmation}
                                              save={"off"}
                                              errors={errors}
                                              touched={touched}
                                              strength={true}
                                    />
                                </Col>

                            </Row>
                            <Form.Group className={"text-center"}>
                                <Button color={"primary"} fullWidth={true} size={3} submitting={submitting} radius={10} className={"btn btn-account"} type={"submit"}>{submitLabel??applicationContext.translator("Register")}</Button>
                            </Form.Group>

                            <br/>
                            {loginHint && <Form.Group as={Row} className={"mb-3"}>
                                <Col className={"text-center"}>
                                    <BlockText>
                                        {applicationContext.translator("Do you have an account?")} <Link to={ROUTE_LOGIN}>{applicationContext.translator("Click here to login")}</Link>
                                    </BlockText>
                                </Col>
                            </Form.Group>}

                        </Form>
                    )}
            </Formik>
        );
    }
}
EnterInformation.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    loginHint: PropTypes.bool,
    submitLabel: PropTypes.string,
    inputErrors: PropTypes.object
}
export default withApplicationContext(EnterInformation);