import React, {Component} from 'react';
import { Container } from "./style.jsx";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class SuccessfullMessageBox extends Component {
    render() {
        const {title,messages,icon} = this.props;
        return (
            <Container>
                {icon??<FontAwesomeIcon icon={"check-circle"} />}<br/>
                {title?<h1>{title}</h1>:null}
                {messages.map((message, index) => <p key={index}>{message}</p>)}
            </Container>
        );
    }
}
SuccessfullMessageBox.propTypes = {
    title: PropTypes.string,
    messages: PropTypes.array,
    icon: PropTypes.object
}
export default SuccessfullMessageBox;