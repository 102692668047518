import styled from "styled-components";
import {Col, Form, Row} from "react-bootstrap";

export const Container = styled.div`
    position: relative;
`;
export const Label = styled(Form.Label)`
  font-weight: 500;
  color: var(--form-element-text-color);
  ${(props) => props.floatLabel && `
          position: absolute;
          top: -0.8em;
          right: 1em;
          background: var(--form-element-background);
          padding: 0.2em 0.5em;
          text-align: right;
          border-radius: var(--border-radius-1);
          margin-bottom: 0;
          color: var(--form-element-text-color-2);
  `}

`;
export const InputCol = styled(Col)`
  position: relative;
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`

export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  ${(props) => props.padding && `
        padding: ${props.padding} !important;
  `}
`

export const Wrapper = styled.div`
  position: relative;
  ${(props) => props.line && `
        display: flex;
        justify-content: space-between;
        align-items: center;      
        & .form-label{
            min-width: 100px;
            margin-bottom: 0;
        }
  `}
  ${(props) => props.padding && ` padding:${props.padding} !important; `}
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`

export const RowForm = styled(Row)`
  
`
export const ColForm = styled(Col)`
  padding: 0;
  &:last-child{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
  }
`
export const ToolsItem = styled.div`
    border-radius: 100%;
    min-width: 2em;
    width: 2em;
    min-height: 2em;  
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .2em;
    ${(props) => !props.disabled?`
        cursor: pointer;
        ${props.add?`background: #7cb342;`:props.delete?`background: #d50f0a;`:`background: var(--color-shadow-3); `}   
        &:hover{
          color: var(--color-shadow-5);
          transition: all ease-in-out .3s;
        }
        
    `:`
        background: var(--color-shadow-1);
        color: var(--color-shadow-3);
    `}
`