import React, {Component} from 'react';
import {withEventTicketsContext} from "./EventTicketsContext";
import {EventDescription, EventTitle, EventTitleSmall, PrimaryText, Text, Title} from "./style";
import {Col} from "react-bootstrap";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import moment from "jalali-moment";
import Artists from "./Artists";
import Link from "../../components/ui/Link";

class EventLeftSide extends Component {
    render() {
        const {context, applicationContext} = this.props;
        const {eventItem} = context.state;
        return (
            <Col md={8}>
                <EventTitleSmall small={true}>Music Nova Proudly Presents:</EventTitleSmall>
                <EventTitle>{eventItem['artists'][0].name}</EventTitle>
                <EventDescription>Live in concert on {moment(eventItem['date_of_event'], 'YYYY/MM/DD').format('DD MMMM YYYY')}</EventDescription>
                {/*{eventItem.content && <Text dangerouslySetInnerHTML={{ __html: eventItem.content }} />}*/}

                <Title>{applicationContext.translator("Artists")}</Title>
                <Artists />

                <Title>{applicationContext.translator("Date of Event")}</Title>
                <Text>{moment(eventItem['date_of_event'], 'YYYY/MM/DD').format('dddd DD MMMM YYYY')}</Text>

                <Title>{applicationContext.translator("Schedule")}</Title>
                <Text>
                    The show starts at {moment(new Date(eventItem['start_time_of_event']), 'YYYY/MM/DD').format('HH:mm')}<br/>
                    Doors open at {moment(new Date(eventItem['doors_open']), 'YYYY/MM/DD').format('HH:mm')}, ensuring you have ample time to settle in.<br/>
                    We offer fast, efficient, secure ticket scanning services to ensure all attendees have seamless and hassle-free entry.
                </Text>


                <Title>{applicationContext.translator("Duration")}</Title>
                <Text>{eventItem.duration}</Text>

                <Title>{applicationContext.translator("Variety of ticket sales")}</Title>
                <Text>{(eventItem.varieties.map(row => row.name)).join(", ")}</Text>

                {eventItem.policies &&
                    <React.Fragment>
                        <Title>{applicationContext.translator("Event Policies")}</Title>
                        <Text dangerouslySetInnerHTML={{ __html: eventItem.policies }} />
                    </React.Fragment>
                }


                <Title>{applicationContext.translator("Location")}</Title>
                <Text>
                    {eventItem.place.title} - {eventItem.place.address}<br/>
                    {eventItem.place.location &&
                        <React.Fragment>
                            <a href={eventItem.place.location.googleMapUrl} target={"_blank"}>(Google Map)</a>
                        </React.Fragment>
                    }
                </Text>


                <Title>{applicationContext.translator("Tickets")}</Title>
                <Text>{eventItem.owner_message}</Text>

                <Title>The concert is organised by <PrimaryText>MUSIC NOVA</PrimaryText></Title>
                {/**/}
            </Col>
        );
    }
    renderVariety = () => {

    }
}

export default withApplicationContext(withEventTicketsContext(EventLeftSide));