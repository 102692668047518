import styled from "styled-components";

export const LoadingBox = styled.div`
  width: 100%;
  ${(props) => props.height?`height: ${props.height};`:``}
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`}
  margin-bottom: 1em;
  background: url("#gtufpq8-animated-diff");
  @media screen and (max-width: 700px) {
    ${(props) => props.smHeight && `height: ${props.smHeight};`}
  }
`
