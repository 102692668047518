import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
export const Container = styled.div`
    margin: 0 .5em
`
export const Toggle = styled.button`
  padding: .5em;
  border-radius: var(--border-radius-3);
  min-width: 100px;
  background-color: var(--color-shadow-03);
  color: var(--color-text);
  border: none;
  cursor: pointer;
  font-size: .9em;
  transition: all 0.3s ease;
  position: relative;
  box-shadow: 0 2px 4px var(--color-shadow-04), 0 0 4px var(--color-shadow-06);
  display: flex;
  &:before{
    content: "";
    position: absolute;
    top: calc(50% - 1em);
    width: 2em;
    height: 2em;
    background: var(--color-background);
    border-radius: 100%;
    transition: all linear .2s;
    box-shadow: 0 2px 4px var(--color-shadow-04), 0 0 4px var(--color-shadow-06);
  }
  &.on{
    background-color: ${(props) => props.backgroundColor??`var(--color-shadow-1)`};
    color: ${(props) => props.textColor??`var(--color-text)`};
    padding-right: 2.4em;
    &:before {
      left: calc(100% - 2.2em);
    }
  }
  &.off{
    background-color: ${(props) => props.backgroundColor??`var(--color-shadow-1)`};
    color: ${(props) => props.textColor??`var(--color-text)`};
    padding-left: 2.4em;
    &:before {
      left: 0.2em;
    }
  }
`
export const ToggleValue = styled.div`
  min-width: 100%;
  display: flex;
    align-items: center;
  & .icon{
    margin: 0 .2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`