import React, {Component} from 'react';
import PageNotFound from "../../components/ui/PageNotFound";

class Error404Page extends Component {
    render() {
        return (
            <PageNotFound />
        );
    }
}

export default Error404Page;