import React, {Component} from 'react';
import {
    InputGroup,
    InputCol,
    Wrapper,
    InputContainer,
    Label,
    SuggestionContainer,
    Container,
    SuggestionList, SuggestionListItem,
    Selected,
    SelectedItem, InputSearch,
    Loading
} from "./style.jsx";
import PropTypes from "prop-types";
import {Row} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Spinner/Spinner";
import {InputError} from "../InputBox/style";
class InputTags extends Component {
    refInput = React.createRef();
    refContainer = React.createRef();
    refSelected = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            suggestions: [],
            found: [],
            selected: [],
            loading: true
        }
    }
    componentDidMount = () => {
        let {suggestions, selected} = this.state;
        suggestions = this.props.suggestions;
        const {value} = this.props;
        value.map((r) => {
            let items = suggestions.filter((suggest) => suggest.key.toString() === r.toString());
            if(items.length > 0)
                selected.push(items[0]);
        })
        this.setState({
            selected,
            suggestions,
            loading: false
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value.length !== prevProps.value.length && this.props.value.length === 0){
            console.log("Reset data", this.props.value)
            this.setState({
                selected: []
            })
        }
    }

    onKeyUpHandler = (e) => {
        let content = e.target.textContent;
        const {suggestions, selected} = this.state;
        let found = [];
        let ids = selected.map(({ key }) => key);
        if(content !== "")
            found = suggestions.filter((row) => row.value.toLowerCase().includes(content.toLowerCase()) && !ids.includes(row.key))

        this.setState({
            open: found.length > 0,
            found
        })
    }
    onAddToSelected = (item) => {
        const {form, name} = this.props;
        let {selected} = this.state;
        selected.push(item);
        this.refInput.textContent = "";
        this.setState({
            selected,
            open: false
        }, () => {
            if(form)
                form.setFieldValue(name, selected.map(({ key }) => key));
        })
    }
    onDeleteItem = (index) => {
        const {form, name} = this.props;
        let {selected} = this.state;
        selected.splice(index, 1);
        this.setState({
            selected
        }, () => {
            if(form)
                form.setFieldValue(name, selected.map(({ key }) => key));
        })
    }
    onFocusHandler = (e) => {
        this.refContainer.classList.add("is-focused")

    }
    onBlurHandler = (e) => {
        this.refContainer.classList.remove("is-focused")
    }
    render() {
        const {label, margin, floatLabel, paddingAround, line, touched, errors, name} = this.props;
        const {open, found, selected, loading} = this.state;
        return (
            <InputGroup as={Row} margin={margin} floatLabel={floatLabel}>
                <InputCol paddingAround={paddingAround}>
                    <Wrapper line={line}>
                        {label && <Label floatLabel={floatLabel}>{label} {line?":":""}</Label>}
                        <Container>
                            <InputContainer ref={(ref) => this.refContainer = ref} onClick={(e) => this.refInput.focus()} isInvalid={(touched && touched[name] && errors[name])}>
                                {loading && <Loading><Spinner size={"sm"} show={loading} inline={true}/></Loading>}
                                {!loading &&
                                    <Selected ref={(ref) => this.refSelected = ref}>
                                        {selected.map((item, index) => {
                                            return (<SelectedItem>{item.value} <FontAwesomeIcon icon={faTimes} onClick={(e) => this.onDeleteItem(index)}/></SelectedItem>)
                                        })}
                                        <InputSearch ref={(ref) => this.refInput = ref} contentEditable="true"
                                                     onKeyUp={this.onKeyUpHandler}
                                                     onFocus={this.onFocusHandler}
                                                     onBlur={this.onBlurHandler}/>
                                    </Selected>
                                }
                            </InputContainer>
                            {open && <SuggestionContainer top={this.refContainer.offsetHeight}>
                                <SuggestionList>
                                    {found.map((item, index) => {
                                        return (<SuggestionListItem key={index} onClick={(e) => this.onAddToSelected(item)}>{item.value}</SuggestionListItem>)
                                    })}
                                </SuggestionList>
                            </SuggestionContainer>}
                        </Container>


                    </Wrapper>
                    {touched && touched[name] && <InputError line={line} paddingLeft={line && this.refLabel.offsetWidth+"px"} hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
                </InputCol>
            </InputGroup>
        );
    }
}
InputTags.propTypes = {
    name: PropTypes.string,
    form: PropTypes.object,
    value: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.string,
    floatLabel: PropTypes.bool,
    paddingAround: PropTypes.string,
    suggestions: PropTypes.array,
    line: PropTypes.bool,
}
export default InputTags;