import React, {Component} from 'react';
import {Container, Wrapper, InnerContent} from "./style";
import ContentLoading from "../ContentLoading";

class EventContentLoader extends Component {
    render() {
        return (
            <Container>
                <Wrapper>
                    <InnerContent>
                        <ContentLoading height={"450px"}>
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="230" />
                            <rect x="0" y="240" rx="5" ry="5" width="40%" height="20" />
                            <rect x="0" y="270" rx="5" ry="5" width="100%" height="20" />
                            <rect x="0" y="330" rx="5" ry="5" width="90%" height="20" />
                            <rect x="0" y="360" rx="5" ry="5" width="70%" height="20" />
                            <rect x="0" y="400" rx="5" ry="5" width="50%" height="50" />
                        </ContentLoading>

                    </InnerContent>
                </Wrapper>
            </Container>

        );
    }
}

export default EventContentLoader;