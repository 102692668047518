import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 3em 0;
  text-align: center;
  
`;
export const Code = styled.h1`
    font-size: 5em;
    font-weight: 600;
    margin-bottom: 0.4em;
`
export const Wrapper = styled.div`
    font-size: 1em;
`