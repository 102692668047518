import React, {Component} from 'react';
import {withEventTicketsContext} from "./EventTicketsContext";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import {ArtistContainer, ArtistImage, ArtistInfo, ArtistName, ArtistsContainer, ArtistType} from "./style";

class Artists extends Component {
    render() {
        const {context, applicationContext} = this.props;
        const {eventItem} = context.state;

        return (
            <ArtistsContainer>
                {eventItem['artists'].map((artist, index) => {
                    return (
                        <ArtistContainer key={index}>
                            <ArtistImage image={artist.avatar.url} />
                            <ArtistInfo>
                                <ArtistName>{artist.name}</ArtistName>
                                <ArtistType>{artist.type}</ArtistType>
                            </ArtistInfo>
                        </ArtistContainer>
                    )
                })}
            </ArtistsContainer>
        );
    }
}

export default withApplicationContext(withEventTicketsContext(Artists));