import styled from "styled-components";
import {Button} from "react-bootstrap";
export const Container = styled.div`
  position: relative;
  ${(props) => props.margin && `
    margin: 1em 0;
  `};
  @media screen and (max-width: 700px) {
    display: block;
    text-align: center;
  }
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`;
export const PickerButton = styled(Button)`
  width: 100%;
  padding: ${(props) => !props.padding?`0.8em 1.3em;`:`${props.padding};`}
  border-radius: var(--border-radius-2);
    background: var(--form-element-background);
    color: var(--form-element-text-color);
    border-color: var(--color-shadow-08);
  &:hover{
    background: var(--color-primary);
    color: var(--color-text);
    border-color: var(--color-primary);
  }
  &:active, &:focus{
    box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
    border-color: var(--color-shadow-2);
    color: var(--color-text) !important;
    background: var(--form-element-background) !important;

  }
  &:disabled{
    background: var(--form-element-background-disabled) !important;
    color: var(--form-element-text-color-disabled) !important;
  }
`
export const CalendarContainer = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  z-index: 999;
  & .react-calendar__navigation{
    background: var(--color-popup-background);
    color: var(--color-popup-text);
  }
  & abbr[title]{
    text-decoration: none !important;
  }
  & abbr[data-bs-original-title]{
    text-decoration: none !important;
  }
  & .react-calendar__tile{
    border: 1px solid var(--color-shadow-08) !important;
  }
  & .react-calendar{
    background: var(--color-popup-background);
    border: 1px solid var(--color-shadow-08);
    font-size: 14px;
  }
  & .react-calendar button{
    color: var(--color-popup-text);
  }
  & .react-calendar button:hover{
    background-color: var(--color-primary);
    color: var(--color-text);
  }
  & .react-calendar__month-view__days__day--weekend{
    color: var(--color-primary) !important;
  }
  & .react-calendar__month-view__days__day--weekend:hover{
    color: var(--color-text) !important;
  }
  & .react-calendar__tile:enabled:hover, 
  & .react-calendar__tile:enabled:focus{
    background-color: var(--color-primary);
    color: var(--color-secondary-on2);
  }
  & .react-calendar__tile--now{
    color: var(--color-secondary-on2) !important;
    background-color: #8bc34a !important;
  }
`

