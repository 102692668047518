import styled from "styled-components";
import {Col, Form} from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker';
export const InputContainer = styled(DateTimePicker)`
  & .react-datetime-picker__wrapper{
    padding: ${(props) => !props.padding?`0.8em 1.3em;`:`${props.padding};`}
    ${(props) => props.floatLabel && `
        padding: 1.2em 1.3em .9em 1.3em;
  `}
    border-radius: var(--border-radius-2);
    background: var(--form-element-background);
    color: var(--form-element-text-color);
    border-color: var(--color-shadow-08);
    font-family: inherit;
    ${(props) => props.isInvalid && `
      border-color: var(--bs-form-invalid-border-color);
    `}
  }
  width: 100%;
  & .react-datetime-picker__clock {
    background: var(--form-element-background);
    color: var(--form-element-text-color);
    border-color: var(--color-shadow-08);
    border-radius: var(--border-radius-2);
    box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
    z-index: 99999;
    & .react-clock__mark__body, .react-clock__hand__body{
      background-color: var(--color-shadow-8);
    }
  }
  & .react-datetime-picker__button {
    border: 0px;
    background: var(--color-shadow-1);
    padding: .3em;
    border-radius: var(--border-radius-1);
    margin: .1em;
    & svg{
      stroke: var(--color-shadow-8);
      width: 15px;
      height: 15px;
      transition: all ease-in-out .3s;
    }
  }
  & .react-datetime-picker__button:enabled:hover 
  .react-datetime-picker__button__icon, 
  .react-datetime-picker__button:enabled:focus 
  .react-datetime-picker__button__icon{
    stroke: var(--color-primary);
    transition: all ease-in-out .3s;
  }
  & .react-datetime-picker__inputGroup__input option{
    background: var(--form-element-background);
    color: var(--form-element-text-color);
  }
  & .react-datetime-picker__inputGroup__input{
    color: var(--form-element-text-color);
  }
  
  & .react-calendar__navigation button {
    color: var(--color-shadow-8);
    transition: all ease-in-out .3s;
  }
  & .react-calendar__navigation button:enabled:hover, 
  .react-calendar__navigation button:enabled:focus {
    background-color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
  & abbr[title] {
    text-decoration: none;
    cursor: help;
    color: var(--color-shadow-8);
  }
  & .react-calendar__tile:enabled:hover, 
  .react-calendar__tile:enabled:focus {
    background-color: var(--color-primary);
    transition: all ease-in-out .3s;
  }
  & .react-calendar__month-view__days__day--neighboringMonth, 
  .react-calendar__decade-view__years__year--neighboringDecade, 
  .react-calendar__century-view__decades__decade--neighboringCentury {
    color: var(--color-shadow-4) !important;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #d10000 !important;
  }
  & .react-calendar__tile{
    color: var(--color-shadow-8);
  }
  .react-calendar__tile--now {
    background: var(--color-primary);
  }
  & .react-datetime-picker__inputGroup__input {
    text-align: center;
    background: transparent;
    border-color: var(--color-shadow-2);
  }
  & .react-datetime-picker__calendar {
    z-index: 99999;
    & .react-calendar {
      background: var(--form-element-background);
      color: var(--form-element-text-color);
      border-color: var(--color-shadow-08);
      border-radius: var(--border-radius-2);
      box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
    }
  }
  
`
export const Container = styled.div`
  background: var(--color-background);
  color: var(--color-text);
  @media screen and (max-width: 700px) {
    padding: .5em 0em;
  }
`;
export const Label = styled(Form.Label)`
  font-weight: 500;
  color: var(--form-element-text-color);
  ${(props) => props.floatLabel && `
          position: absolute;
          top: -0.8em;
          right: 1em;
          background: var(--form-element-background);
          padding: 0.2em 0.5em;
          text-align: right;
          border-radius: var(--border-radius-1);
          margin-bottom: 0;
          color: var(--form-element-text-color-2);
  `}

`;
export const InputCol = styled(Col)`
  position: relative;
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`

export const InputGroup = styled(Form.Group)`
  margin-bottom: 1em;
  ${(props) => props.margin && `
        margin: ${props.margin} !important;
  `}
  ${(props) => props.floatLabel && `
        margin-bottom: 1.5em !important;
  `}
  ${(props) => props.padding && `
        padding: ${props.padding} !important;
  `}
`
export const InputWrapper = styled.div`
    width: 100%;
`
export const Wrapper = styled.div`
  position: relative;
  ${(props) => props.line && `
        display: flex;
        justify-content: space-between;
        align-items: center;      
        & .form-label{
            min-width: 100px;
            margin-bottom: 0;
        }
  `}
  ${(props) => props.padding && ` padding:${props.padding} !important; `}
  ${(props) => props.paddingAround && `
        padding-left: ${props.paddingAround} !important;
        padding-right: ${props.paddingAround} !important;
  `}
`