import React, {Component} from 'react';
import {
    AccountAreaContainer,
    HeaderBar,
    HeaderBarWrapper, HeaderLogo, HeaderMenu, HeaderMenuContainer, HeaderMenuWrapper
} from "./style.jsx";
import {Container, Row, Col, Dropdown} from 'react-bootstrap';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import withRouter from "../../../contexts/withRouter";
import {withAccountContext} from "../../../contexts/AccountContext";
import AccountArea from "./AccountArea";
import ToggleButton from "../ToggleButton";
import Link from "../Link";
import {ROUTE_ABOUT, ROUTE_MUSICIANS} from "../../../routes/routes";
import Social from "../Footer/Social";
class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menu: false
        }
    }
    onClickMenuIcon = (e) => {
        this.setState({
            menu: !this.state.menu
        })
    }
    scrollTo = (e, id) => {
        e.preventDefault();
        document.getElementById(id).scrollIntoView({behavior: 'smooth'});
    }
    render() {
        const {applicationContext} = this.props;
        return (
            <HeaderBar>
                <Container style={{ height: "100%" }}>
                    <HeaderBarWrapper>
                        <Row>
                            <Col md={5} xs={8}>
                                <Link to={"/"}><HeaderLogo src={"/assets/images/logo.png"} /></Link>
                            </Col>
                            <HeaderMenuContainer md={7} xs={4} show={this.state.menu}>
                                <AccountAreaContainer>
                                    <div className={"mobile-social"}><Social /></div>
                                    <ToggleButton
                                        checked={applicationContext.state.theme !== "light-theme"}
                                        onChange={(item) => {
                                            applicationContext.onChangeTheme(item.id);
                                        }}
                                        values={[{
                                            id: "dark-theme",
                                            label: "Dark",
                                            icon: "🌙",
                                            backgroundColor: "#333",
                                            textColor: "#fff"
                                        },{
                                            id: "light-theme",
                                            label: "Light",
                                            icon: "🔆",
                                            backgroundColor: "#f1f1f1",
                                            textColor: "#333"
                                        }]}
                                    />
                                    <AccountArea />
                                </AccountAreaContainer>
                                <FontAwesomeIcon icon={faBars} className={"hamburger-icon"} onClick={this.onClickMenuIcon} />
                                <HeaderMenuWrapper show={this.state.menu}>
                                    {this.state.menu && <FontAwesomeIcon className={"close-bar"} icon={faClose} onClick={this.onClickMenuIcon} />}
                                   <HeaderMenu><Link to={"#"} onClick={(e) => this.scrollTo(e, "about-section")}>About</Link></HeaderMenu>
                                   {/*<HeaderMenu><Link to={"/"}>Pictures</Link></HeaderMenu>*/}
                                   {/*<HeaderMenu><Link to={"/"}>Previous Events</Link></HeaderMenu>*/}
                                   <HeaderMenu><Link to={"#"} onClick={(e) => this.scrollTo(e, "musicians-section")}>Musicians</Link></HeaderMenu>
                                   <HeaderMenu><Link to={"#"} onClick={(e) => this.scrollTo(e, "contact-section")}>Contact</Link></HeaderMenu>
                                    <div className={"mobile-social"}><Social /></div>
                                </HeaderMenuWrapper>
                            </HeaderMenuContainer>
                        </Row>
                    </HeaderBarWrapper>
                </Container>
            </HeaderBar>
        );
    }
}

export default withApplicationContext(withRouter(withAccountContext(AppHeader)));