import React, {Component} from 'react';
import {InputGroup, InputCol, Wrapper, InputContainer, Label, InputWrapper} from "./style.jsx";
import PropTypes from "prop-types";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import {Row} from "react-bootstrap";
import {InputError} from "../InputBox/style";
class DateTimePicker extends Component {

    render() {
        const {onChange, label, value, margin, floatLabel, paddingAround, line, touched, errors, name} = this.props;
        return (
            <InputGroup as={Row} margin={margin} floatLabel={floatLabel}>
                <InputCol paddingAround={paddingAround}>
                    <Wrapper line={line}>
                        {label && <Label ref={(ref) => this.refLabel = ref} floatLabel={floatLabel}>{label} {line?":":""}</Label>}
                        <InputWrapper>
                            <InputContainer
                                {...this.props}
                                isInvalid={(touched && touched[name] && errors[name])}
                                format={"y-MM-dd HH:mm"}
                                onChange={onChange}
                                value={value}
                            />
                            {touched && touched[name] && <InputError line={line} paddingLeft={line && this.refLabel.offsetWidth+"px"} hidden={errors[name] === undefined && true}>{errors[name]}</InputError>}
                        </InputWrapper>

                    </Wrapper>
                </InputCol>
            </InputGroup>
        );
    }
}
DateTimePicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.string,
    floatLabel: PropTypes.bool,
    paddingAround: PropTypes.string,
    line: PropTypes.bool,
}
export default DateTimePicker;