import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import EventCard from "../../components/ui/EventCard/EventCard";
import {LoadMore} from "./style";
import Card from "../../components/ui/Card";
import {withApplicationContext} from "../../contexts/ApplicationContext";
import CommonService from "../../services/CommonService";
import PropTypes from "prop-types";
import EventContentLoader from "../../components/ui/EventCard/EventContentLoader";
import Link from "../../components/ui/Link";

class UpComingEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            loading: true
        }
    }
    componentDidMount = async () => {
        await this.fetchData(1);
    }
    fetchData = async (page) => {
        try{
            this.setState({ loading: true });
            let seconds = new Date().getTime();
            let result = await CommonService.doGet("/public/events", {
                page,
                limit: this.props.limit??3,
                upcoming: 1
            });
            setTimeout(() => {
                this.setState({
                    data: this.state.data.concat(result),
                    page: (this.state.data.length >= 3)?(page + 1):page,
                    loading: false
                })
            }, 1000 - (new Date().getTime() - seconds))
        }catch (e){
            console.log(e)
        }
    }
    renderLoadingData = () => {
        const {loading} = this.state;
        if(!loading) return null;
        return (
            <Col md={12}>
                <Row>
                    <EventContentLoader />
                    <EventContentLoader />
                    <EventContentLoader />
                </Row>
            </Col>

        )
    }
    renderData = () => {
        const {data, loading} = this.state;
        if(!data) return null;
        return data.map((eventItem, index) => <EventCard key={index} eventItem={eventItem} />)
    }
    render() {
        const {applicationContext} = this.props;
        const {page, loading} = this.state;
        return (
            <Card title={applicationContext.translator("Upcoming Events")}>
                <Row>
                    {this.renderData()}
                    {this.renderLoadingData()}
                    {!loading && page === 2 && <Col md={12}>
                        <LoadMore>
                            <Link to={"#"} onClick={(e) => this.fetchData(page)}>{applicationContext.translator("Load More")}</Link>
                        </LoadMore>
                    </Col>}

                </Row>
            </Card>
        );
    }
}
UpComingEvents.propTypes = {
    limit: PropTypes.number
}
export default withApplicationContext(UpComingEvents);