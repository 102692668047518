import moment from "jalali-moment";
import * as momentTimezone from "moment-timezone";
export const convertStringToArray = (string) => {
    let array = [];
    for (let i = 0; i < string.length; i++) {
        array.push(string.charAt(i));
    }
    return array;
}
export const arrayRange = (start, stop, step = 1) => {
    return Array.from(
        {length: (stop - start) / step + 1},
        (value, index) => start + index * step
    );
}
export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export const shuffle = (start = 1, numbers = []) => {
    for(let i = start; i <= 6 ; i++){
        let number = getRandomInt(1, 46);
        if(!numbers.includes(number)){
            numbers.push(number);
        }else{
            return shuffle(i, numbers);
        }
    }
    return sortNumbers(numbers);
}

export const getNextFriday = (drawCount, fd = null) => {
    let dayOfWeek = 5;
    let fromDate = fd??new Date();

    fromDate.setDate(fromDate.getDate() + (7 * (drawCount - 1)))
    fromDate.setDate(fromDate.getDate() + (7 + dayOfWeek - fromDate.getDay()) % 7);
    return fromDate;
}

export const convertToEnglishNumbers = (str) => {
    let
    persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
    arabicNumbers  = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];

    if(typeof str === 'string'){
        for(let i=0; i<10; i++)
        {
            str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
        }
    }
    return str;

}
export const isNumber = (evt) => {
    evt = (evt) ? evt : window.event;
    let charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode > 1785 || charCode < 1776)) {
        return false;
    }
    return true;
}

export const msisdn = (string) => {
    string = string.replaceAll(" ", "");
    if(string.length === 10){
        let left = string.substr(0, 3);
        let left2 = string.substr(3, 3);
        let mid = string.substr(6, 2);
        let right = string.substr(8, 2);
        return `+98 ${left} ${left2} ${mid}${right}`;
    }
    return null;
}

export const ticketId = (string) => {
    string = string.toString().replaceAll(" ", "");
    if(string.length === 10){
        let left = string.substr(0, 4);
        let mid = string.substr(4, 3);
        let right = string.substr(7, 3);
        return `${left} ${mid} ${right}`;
    }
    return null;
}

export const accountId = (string, index) => {
    string = string.toString().replaceAll(" ", "");
    if(string.length === 9){
        let left = string.substr(0, 3);
        let mid = string.substr(3, 3);
        let right = string.substr(6, 3);
        return `${left} ${mid} ${right}`;
    }
    return null;
}

export const isValidNationalCode = (nationalCode) => {
    if (String(nationalCode).toLowerCase().match(/^[0-9]{10}$/)) {
        let nationalCodeString = nationalCode.toString();
        let nationalCodeArray = nationalCodeString.split('');
        switch (nationalCodeString) {
            case "0000000000":
            case "1111111111":
            case "22222222222":
            case "33333333333":
            case "4444444444":
            case "5555555555":
            case "6666666666":
            case "7777777777":
            case "8888888888":
            case "9999999999":
                return false
        }
        let num9 = nationalCodeArray[9];
        let sumNationalCode = (nationalCodeArray[0] * 10) + (nationalCodeArray[1] * 9) + (nationalCodeArray[2] * 8) + (nationalCodeArray[3] * 7) + (nationalCodeArray[4] * 6) + (nationalCodeArray[5] * 5) + (nationalCodeArray[6] * 4) + (nationalCodeArray[7] * 3) + (nationalCodeArray[8] * 2);
        let divideNationalCode = Math.floor(sumNationalCode % 11);
        if (divideNationalCode < 2 && num9 == divideNationalCode || divideNationalCode >= 2 && num9 == Math.abs(11 - divideNationalCode)) {
            return true
        }
        else {
            return false
        }

    }
    else {
        return false
    }
};
export const queryStringSerialize = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const toHashMap = (array, key) => {
    let _hashMap = {};
    array.forEach(function (obj){
        _hashMap[obj[key]] = obj;
    });
    return _hashMap;
}

export const toDataList = (array, labelKey = [], key = 'id', labelSeparator = '') => {
    let _list = [];
    array.forEach(function (obj){
        let label = [];
        labelKey.forEach((l) => obj.hasOwnProperty(l) && label.push(obj[l]));
        if(obj.hasOwnProperty(key))
            _list.push({
                key: obj[key],
                label: label.join(labelSeparator)
            })
    });
    return _list;
}

export const toSearchDataList = (translator, array, labelKey = [], selectedKey = null, key = 'id', labelSeparator = '') => {
    let _list = [];
    array.forEach(function (obj){
        let label = [];
        labelKey.forEach((l) => obj.hasOwnProperty(l) && label.push(obj[l]));
        if(obj.hasOwnProperty(key))
            _list.push({
                labelKey: obj[key],
                value: translator(label.join(labelSeparator)),
                ...(selectedKey && obj[key] === selectedKey && {
                    isSelected: true
                })
            })
    });
    return _list;
}
export const isRouteActive = (route) => {
    return window.location.pathname.startsWith(route);
}

export const toDate = (date, locale, time = false, full = true) => {
    let format = 'YYYY/MM/DD';
    if(time)
        format = 'YYYY/MM/DD HH:mm:ss';
    let toFormat = 'dddd, D MMMM YYYY';
    let mid = (locale === "fa")?"ساعت ":"";
    if(time && full)
        toFormat = 'dddd, D MMMM YYYY '+mid+'HH:mm:ss';
    if(time && !full)
        toFormat = 'HH:mm:ss YYYY/MM/DD';

    date = utcTime(date).format('YYYY/MM/DD HH:mm:ss');

    return moment(date, format).locale(locale).format(toFormat);
}
export const convertToDate = (date, locale = "fa", sourceFormat = 'YYYY/MM/DD HH:mm:ss', destFormat = 'dddd, D MMMM YYYY') => {
    date = utcTime(date).format(sourceFormat);
    return moment(date, sourceFormat).locale(locale).format(destFormat)
}
export const convertToTime = (date, locale = "fa") => {
    date = utcTime(date).format('YYYY/MM/DD HH:mm:ss');
    return moment(date, 'YYYY/MM/DD HH:mm:ss').locale(locale).format('HH:mm:ss')
}
export const  numberWithCommas = (x, symbol = false, point = ",") => {
    x = parseFloat(x).toFixed(0);
    let m =  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, point);
    if(symbol){
        m = m + ",-"
        m = "$ " +m ;
    }

    return m;
}
export const parsePropertyByDotValue = (row, key) => {
    let value = null;
    if(!row.hasOwnProperty(key)) {
        if(key.search(/[.]/) > -1){
            let indexes = key.split(".");
            indexes.forEach((index) => {
                if(value && value.hasOwnProperty(index)){
                    value = value[index];
                }else{
                    if(row.hasOwnProperty(index))
                        value = row[index];
                }
            })
        }
    }else{
        value = row[key]
    }

    return value;
}



export const parseErrorMessage = (error) => {
    if(error.response && error.response.status !== 200 && error.response.data.hasOwnProperty('message')){
        return error.response.data.message;
    }
    return error.response.statusText;
}
export const handleValidationErrors = (e, alert = null) => {
    let errors = [];
    if(e.response.data.hasOwnProperty('input_errors')){
        Object.keys(e.response.data['input_errors']).map((error) => {
            e.response.data['input_errors'][error].map((errorMessage) => errors.push(errorMessage))
        })
    }else{
        errors.push(parseErrorMessage(e))
        if(alert) alert.error(parseErrorMessage(e));
    }
    return errors;
}
export const addNumberToArray = (array, number) => {
    let set = false;

    array.map((n, index) => {
        if(n === null && !set) {
            array[index] = number;
            set = true;
        }
    });

    return sortNumbers(array);
}

export const sortNumbers = (numbers) => {
    let output = [];
    numbers.map((n) => {
        if(n) output.push(n);
    });
    output.sort(function(a, b) {
        return a - b;
    });
    for (let i = output.length; i < 6 ; i++){
        output.push(null);
    }
    return output;
}

export const deleteNullItemInArray = (array) => {
    let output = [];
    array.map((item, index) => {
        if(item)
            output.push(item);
    })
    return output;
}

export const timezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
export const utcTime = (date) => {
    return momentTimezone.utc(date, "YYYY-MM-DD HH:mm:ss").tz(timezone());
}



export const passwordCheck = (passwordValue) => {
    const strengthChecks = {
        length: 0,
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? true : false;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[!$#%@]+/.test(passwordValue);

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
        verifiedList.length == 5
            ? "Strong"
            : verifiedList.length >= 2
            ? "Medium"
            : "Weak";
    let color = "#FF0054";
    if (strength === "Strong") color = "#8BC926";
    if (strength === "Medium") color = "#FEBD01";
    return {
        value: passwordValue,
        progress: `${(verifiedList.length / 5) * 100}%`,
        message: strength,
        color: color,
        strengthChecks
    };
}

export const yearRange = (start, end) => {

    return Array.from({ length: end - start + 1 }, (_, i) => {
        let y = (new Date().getFullYear() - 90) + i;
        let date = y+"-03-01 00:00:00";
        return moment(utcTime(date).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').locale("fa").format("YYYY");
    })
}

export const yearToNowRange = (start, locale) => {
    let end = new Date().getFullYear();
    start = new Date(start).getFullYear();
    if(locale === "fa") {
        end = moment(utcTime(new Date()).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').locale(locale).format("YYYY");
        start = moment(utcTime(start).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').locale(locale).format("YYYY");
    }
    start = parseInt(start);
    end = parseInt(start);
    return Array.from({ length: end - start + 1 }, (_, i) => {
        return i + end;
    })
}

export const dayRange = (month, locale) => {
    let days = {
        1: locale === "fa"?31:31,
        2: locale === "fa"?31:29,
        3: locale === "fa"?31:31,
        4: locale === "fa"?31:30,
        5: locale === "fa"?31:31,
        6: locale === "fa"?31:30,
        7: locale === "fa"?30:31,
        8: locale === "fa"?30:31,
        9: locale === "fa"?30:30,
        10: locale === "fa"?30:31,
        11: locale === "fa"?30:30,
        12: locale === "fa"?30:31
    }
    let dayCount = days[(month?month:1)];

    return Array.from({ length: dayCount - 0  }, (_, i) => 1 + i)
}

export const zeroPad = (value, length) => {
    length = length || 2; // defaults to 2 if no parameter is passed
    return (new Array(length).join('0')+value).slice(length*-1);
};



export const condition = (row, c) => {
    if(c.length !== 3) return false;
    let key = c[0];
    let op = c[1];
    let value = c[2];
    let rowValue = parsePropertyByDotValue(row, key);
    if(op === "IN" && typeof value !== "object"){
        console.log('ERROR')
    }
    switch (op){
        case "=":
            if(rowValue === value) return true;
        break;
        case "<>":
            if(rowValue !== value) return true;
            break;
        case "<":
            if(parseFloat(rowValue) < parseFloat(value)) return true;
            break;
        case "<=":
            if(parseFloat(rowValue) <= parseFloat(value)) return true;
            break;
        case ">":
            if(parseFloat(rowValue) > parseFloat(value)) return true;
            break;
        case ">=":
            if(parseFloat(rowValue) >= parseFloat(value)) return true;
            break;
        case "IN":
            if(value.includes(rowValue)) return true;
            break;
    }

    return false;
}

export const currentMonth = (locale) => {
    if(locale === "fa")
        return moment(utcTime(new Date()).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').locale(locale).format("MM");
    return new Date().getMonth() + 1;
}

export const currentYear = (locale) => {
    if(locale === "fa")
        return moment(utcTime(new Date()).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').locale(locale).format("YYYY");
    return new Date().getFullYear();
}

export const slugify = (str) => {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');
}

export const generateUrl = (str, parameters = [], values = []) => {
    if(parameters.length === values.length){
        parameters.map((param, index) => {
            str = str.replaceAll(":" + param, values[index])
        })
    }

    return str;
}

export const id = (digit) => {
    return Math.floor((Math.random() * Math.pow(10, (digit + 1))));
}

export const addSecondsToDate = (date, seconds) => {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
}
export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
export const file = (upload) => {
    let file = new File([""], "");
    file.uploaded = upload;
    return file;
}

export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};
export const acceptFileTypes = () => {
    return {
        image: ["jpg","jpeg","png","bmp","tiff","webp"],
        video: ["mp4"],
        audio: ["mp3"],
    }
}
export const acceptFormat = (fileTypes, isString = true) => {
    let accepted = acceptFileTypes();
    let accept = [];
    fileTypes.map((key) => {
        accept = accept.concat(accepted[key])
    })
    if(!isString) return accept;
    let string = "";
    accept.map((r) => string += ("."+r+","));
    return string.substring(0, string.length - 1);
}
export const getFileTypeByExtension = (extension) => {
    let accepted = acceptFileTypes();
    let type = null;
    Object.keys(accepted).map((key) => {
        if(accepted[key].includes(extension)) type = key;
    })
    return type;
}
export const prepareParametersData = (values) => {
    let params = {...values};
    Object.keys(params).map((key) => {
        if(params[key] && typeof params[key] === "object" && params[key].length > 0){
            let uploadedIds = [];
            params[key].map((item, index) => {
                if(typeof item === "object" && item.hasOwnProperty('uploaded') && item.uploaded.id)
                    uploadedIds.push(item.uploaded.id)
            })
            if(uploadedIds.length > 0)
                params[key] = uploadedIds;
        }else if(params[key] && params[key] instanceof Date){
            params[key] = convertToDate(params[key], "en", 'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
        }
    })
    return params;
}
export const convertArrayToFiles = (data) => {
    if(!data) return [];
    if(data && typeof data === "object" && data.hasOwnProperty('real_file_name')){
        let files = [];
        files.push(file(data))
        data = files;
    }else if(data && typeof data === "object" && data.length > 0 && data[0].hasOwnProperty('real_file_name')){
        let files = [];
        data.map((f) => files.push(file(f)))
        data = files;
    }
    return data;
}
export const moveArrayIndex = (array, source, dest) => {
    let x = source;
    let pos = dest;
    let temp = array[x];
    let i;
    for (i = x; i >= pos; i--) {
        array[i] = array[i - 1];
    }
    array[pos] = temp;
    return array;

}
export const convertListToKeyValue = (data, key, value, indexKey = 'key', indexValue = 'value') => {
    let array = [];
    data.map((row) => {
        array.push({
            [indexKey]: row[key],
            [indexValue]: row[value]
        })
    })
    return array;
}

export const convertListToListOfKeys = (data, key) => {
    return data.map((row) => row[key]);
}
export const isDate = (date) => {
    try{
        return new Date(date);
    }catch (e){
        console.log(e)
    }
    return false;
}