import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAlert} from "react-alert";
import withRouter from "../../contexts/withRouter";
import {Container} from "./style";
import ResetByMobileForm from "./ResetByMobileForm";
import ResetByEmailForm from "./ResetByEmailForm";
import ResetPasswordForm from "./ResetPasswordForm";

class ResetPassword extends Component {
    render() {
        const {method} = this.props;
        return (
            <React.Fragment>
                <Container>
                    {this.renderComponent(method)}
                </Container>
            </React.Fragment>
        );

    }
    renderComponent(method) {
        switch(method) {
            case 'mobile':
                return <ResetByMobileForm {...this.props}/>;
            case 'email':
                return <ResetByEmailForm {...this.props} />;
            default:
                return <ResetPasswordForm {...this.props} />;
        }
    }
}

export default withAlert()(withRouter(withApplicationContext(ResetPassword)));