import React, {Component} from 'react';
import {MainWrapper} from "../style";
import {withCheckoutContext} from "./CheckoutContext"
import {withAlert} from "react-alert";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import withRouter from "../../../contexts/withRouter";
import CustomerInformation from "./CustomerInformation";
import Summary from "./Summary";
class PaymentMethod extends Component {
    render() {
        return (
            <MainWrapper md={6}>
                <CustomerInformation />
                <Summary />
            </MainWrapper>
        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withCheckoutContext(PaymentMethod))));