import React, {Component} from 'react';
import {Container, Title} from "./style.jsx";
import PropTypes from "prop-types";

class Card extends Component {

    render() {
        const {children, loading, title} = this.props;
        return (
            <Container {...this.props} className={"card"}>
                {title && <Title>{title}</Title>}
                <div>
                    {loading?"":children}
                </div>

            </Container>
        );
    }
}
Card.propTypes = {
    maxWidth: PropTypes.number,
    center: PropTypes.bool,
    top: PropTypes.bool,
    bottom: PropTypes.bool,
    padding: PropTypes.string,
    margin: PropTypes.string,
    loading: PropTypes.bool
}
export default Card;