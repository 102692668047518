import React, {Component} from 'react';
import {withEventTicketsContext} from "./EventTicketsContext";
import {Col} from "react-bootstrap";
import Cover from "./Cover";
import SelectTicket from "./SelectTicket";

class EvenRightSide extends Component {
    render() {
        const {context} = this.props;
        const {eventItem} = context.state;
        return (
            <Col md={4}>
                <Cover />
                <SelectTicket />
            </Col>
        );
    }
}

export default withEventTicketsContext(EvenRightSide);