import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withCheckoutContext} from "./CheckoutContext";
import {withAccountContext} from "../../../contexts/AccountContext";
import {withFormContext} from "../../../contexts/FormContext";
import {CardContainer, RadioOption, RadioOptionContainer, RadioOptionLabel, Title} from "../style";
import CommonService from "../../../services/CommonService";

class AdditionalServices extends Component {
    onChangeService = async (e) => {
        const {formContext, checkoutContext} = this.props;
        const {handleChange, values} = formContext;
        handleChange(e);
        let params = {
            send_via_sms: values.send_via_sms,
            deliver_to_address: values.deliver_to_address
        };
        params[e.target.value] = (e.target.checked)?"1":null;
        let cart = await CommonService.doGet(`/basket/basket-checkout/${checkoutContext.state.cart.id}`, params).catch((e) => {
            this.routeToNotFound();
        });
        if(cart){
            checkoutContext.doUpdateState({
                cart
            })
        }

    }
    render() {
        const {applicationContext} = this.props;
        return (
            <React.Fragment>
                <Title>{applicationContext.translator("Additional services")}</Title>
                <CardContainer>
                    <RadioOptionContainer>
                        <RadioOption type="checkbox" name="send_via_sms" value="send_via_sms" onChange={this.onChangeService}/>
                        <RadioOptionLabel>{applicationContext.translator("Send me tickets via sms")}</RadioOptionLabel>
                    </RadioOptionContainer>
                </CardContainer>
                <CardContainer>
                    <RadioOptionContainer>
                        <RadioOption type="checkbox" name="deliver_to_address" value="deliver_to_address" onChange={this.onChangeService}/>
                        <RadioOptionLabel>{applicationContext.translator("Deliver tickets to entered address")}</RadioOptionLabel>
                    </RadioOptionContainer>
                </CardContainer>
            </React.Fragment>
        );
    }
}

export default withApplicationContext((withCheckoutContext(withAccountContext(withFormContext(AdditionalServices)))));