import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext"
import {withAccountContext} from "../../../contexts/AccountContext";
import withRouter from "../../../contexts/withRouter";
import {Container, Loader, Title} from "./style";
import SimpleCard from "../../../components/ui/SimpleCard";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {Col, Row, Spinner} from "react-bootstrap";
import InputBox from "../../../components/ui/InputBox/InputBox";
import Button from "../../../components/ui/Button";
import CommonService from "../../../services/CommonService";
import {withAlert} from "react-alert";
import PageNotFound from "../../../components/ui/PageNotFound";
import {ROUTE_ACCOUNT_REQUEST_UPDATE_EMAIL} from "../../../routes/routes";
import SuccessfullMessageBox from "../../../components/ui/SuccessfullMessageBox";
class UpdatingEmailProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notFound: false,
            submitting: true,
            endpoint: null,
            inputs: {
                current_email: "",
                email: ""
            }
        }


    }
    componentDidMount = async () => {
        const {alert, params, accountContext} = this.props;
        try{
            let hash = JSON.parse(decodeURIComponent(atob(params.hash)));
            if(hash.hasOwnProperty('u')){
                let user = await CommonService.doPost(hash.u, {}).catch((e) => {
                    let error = e.response.data;
                    alert.error(error.message);
                });
                if(user){
                    accountContext.updateUserInfo(user, () => {
                        this.setState({
                            submitting: false
                        })
                    })
                }
            }else{
                this.setState({
                    notFound: true,
                })
            }

        }catch (e){
            this.setState({
                notFound: true,
            })
        }


    }
    render() {
        const {applicationContext, accountContext} = this.props;
        if(this.state.notFound)
            return <PageNotFound/>;
        return (
            <Container>
               <SimpleCard>
                   {this.state.submitting?<Loader><Spinner animation="border" size={"sm"} /></Loader>:<SuccessfullMessageBox
                       messages={[
                           applicationContext.translator("You e-mail address has been updated successfully.")
                       ]}
                   />}
               </SimpleCard>
            </Container>

        );
    }
}

export default withAlert()(withApplicationContext(withRouter(withAccountContext(UpdatingEmailProfile))));