import styled from "styled-components";
import {Col, Form} from "react-bootstrap";

export const Container = styled.div`
  width: 100%;
`;


export const Select = styled(Form.Select)`
  padding: ${(props) => !props.padding?`0.8em 1.3em;`:`${props.padding};`}
  ${(props) => props.floatLabel && `
        padding: 1.2em 1.3em .9em 1.3em;
  `}
  ${(props) => props.dir === "rtl" && `
        background-position: left .75rem center;
  `}
  border-radius: var(--border-radius-2);
  background-color: var(--form-element-background);
  color: var(--form-element-text-color);
  border-color: var(--color-shadow-08);
  background-image: var(--form-element-dropdown-icon);
  &:focus{
    box-shadow: 0 0 0 0.25rem var(--form-element-focus-shadow) !important;
  }
  
  &:active, &:focus{
    border:1px solid  var(--color-shadow-2) !important;
    color: var(--form-element-text-color) !important;;
    background-color: var(--form-element-background) !important;;
  }
  &:disabled{
    background-color: var(--form-element-background-disabled) !important;
    color: var(--form-element-text-color-disabled) !important;
  }
  ${(props) => props.fontSize && `
        font-size: ${props.fontSize} !important;
  `}
  ${(props) => props.fontWeight && `
        font-weight: ${props.fontWeight} !important;
  `}
  ${(props) => props.noInvalidIcon && `
      background-position: left 0.75rem center !important;
      padding-right: 1.3em !important;
      background-image: var(--form-element-dropdown-icon) !important;
  `}

`;