import React, {Component} from 'react';
import {withApplicationContext} from "../../contexts/ApplicationContext"
import {withAccountContext} from "../../contexts/AccountContext";
import withRouter from "../../contexts/withRouter";
import {EventTicketsProvider} from "./EventTicketsContext";
import Event from "./Event";
import CommonService from "../../services/CommonService";
import PageNotFound from "../../components/ui/PageNotFound";

class EventTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            eventItem: null,
            closeReservation: false,
            slideshow: [],
            notFound: false
        }
    }

    routeToNotFound = () => {
        this.setState({
            notFound : true,
            loading: false
        })
    }
    componentDidMount = async () => {
        await this.getEventById();
    }
    componentWillUnmount() {
        const {applicationContext} = this.props;
        applicationContext.doChangePageTitle();
    }

    getEventById = async () => {
        const {params, applicationContext} = this.props;
        let seconds = new Date().getTime();
        let item = await CommonService.doGet(`/public/get_event_by_id/${params['id']}`).catch((e) => {
            this.routeToNotFound();
        });
        if(!item){
            this.routeToNotFound();
            return;
        }
        applicationContext.doChangePageTitle(item.title);
        setTimeout(() => {
            this.setState({
                eventItem: item,
                loading: false
            })
        }, 1000 - (new Date().getTime() - seconds))
    }
    doUpdateState = (state) => {
        this.setState(state);
    }

    renderViewComponent = () => {
        const {notFound} = this.state;
        if(notFound) return (<PageNotFound />);
        return <Event />;
    }
    render() {
        return (
            <EventTicketsProvider value={{
                state: this.state,
                doUpdateState: this.doUpdateState
            }}>
                {this.renderViewComponent()}
            </EventTicketsProvider>

        );
    }

}

export default withApplicationContext(withRouter(withAccountContext(EventTickets)));