import React from "react";
const UploaderContext = React.createContext({});
const UploaderProvider = UploaderContext.Provider;
const UploaderConsumer = UploaderContext.Consumer;
const withUploaderContext = Component => React.forwardRef((props, ref) => (
    <UploaderConsumer>
        {(response) => {
            return <Component {...props} context={response} ref={ref}/>;
        }}
    </UploaderConsumer>
));
export { UploaderProvider, UploaderConsumer, withUploaderContext };
