import React, {Component} from 'react';
import {withEventTicketsContext} from "./EventTicketsContext";
import SlideShow from "../../components/ui/SlideShow";
import {Container} from "./style";
import EventLeftSide from "./EventLeftSide";
import EvenRightSide from "./EventRightSide";
import {Col} from "react-bootstrap";
import RowSupportFooter from "../../components/ui/Footer/RowSupportFooter";
import TicketSaleFooter from "../../components/ui/Footer/TicketSaleFooter";
import ContentLoading from "../../components/ui/ContentLoading";

class Event extends Component {
    renderDataLoading = () => {
        const {context} = this.props;
        const {loading} = context.state;
        if(!loading) return null;
        return (
            <React.Fragment>
                <ContentLoading height={"480px"} smHeight={"180px"}>
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="80%" />
                    <rect x="0" y="81%" rx="5" ry="5" width="100%" height="19%" />
                </ContentLoading>
                <ContentLoading height={"100vh"}>
                    <rect x="0" y=".5%" rx="5" ry="5" width="70.5%" height="100%" />
                    <rect x="71%" y=".5%" rx="5" ry="5" width="29%" height="100%" />
                </ContentLoading>
            </React.Fragment>

        )
    }
    renderViewComponent = () => {
        const {context} = this.props;
        const {loading, eventItem} = context.state;
        if(loading) return null;
        return (
            <React.Fragment>
                <SlideShow event={eventItem} isSelectTicket={!context.state.closeReservation} fixedFooter={true} playMusic={true}/>
                <Container>
                    <EventLeftSide />
                    <EvenRightSide />
                </Container>
                <Col md={12}>
                    <RowSupportFooter />
                </Col>
            </React.Fragment>
        );
    }
    render() {
        const {context} = this.props;
        return (
            <React.Fragment>
                {this.renderDataLoading()}
                {this.renderViewComponent()}
            </React.Fragment>
        );
    }
}

export default withEventTicketsContext(Event);