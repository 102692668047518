import styled from "styled-components";

export const TimerContainer = styled.div`
  padding: .5em 1em;
  background: #fff;
  border-radius: var(--border-radius-2);;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px -4px, rgba(0, 0, 0, 0.12) 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const TimerNumber = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin: 0 .2em;
  ${(props) => props.padding && `padding-bottom: .6em;`}
  & div{
    font-size: .6em;
    font-weight: 300;
  }
`

export const NormalTimerContainer = styled.div`
  padding: .5em 1em;
  background: var(--color-shadow-1);
  border-radius: var(--border-radius-2);;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const NormalTimerNumber = styled.div`
  font-size: 1em;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin: 0 .1em;
  ${(props) => props.padding && `padding-bottom: .6em;`}
  & div{
    font-size: .5em;
    font-weight: 300;
  }
`