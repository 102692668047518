import React, {Component} from 'react';
import {PackageColor, SeatInfo, SeatPopup, SeatPopupSeatInfoWrapper, SeatPopupWrapper} from "./style";
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withStageMapContext} from "./StageMapContext";
import PropTypes from "prop-types";
import {numberWithCommas} from "../../../helpers";

class StageMapPopup extends Component {
    render() {
        const {applicationContext, context, seatRow, name} = this.props;
        const {cellHover, packages, cellSize} = context;
        return (
            (cellHover === name)?(
                <SeatPopup show={cellHover} size={cellSize}>
                    <PackageColor className={"package-color"} bg={packages[seatRow.package].color}>{packages[seatRow.package].label}</PackageColor>
                    <SeatPopupWrapper>
                        <SeatPopupSeatInfoWrapper>
                            <SeatInfo><div>{applicationContext.translator("Seat")}:</div> {name}</SeatInfo>
                            <SeatInfo><div>{applicationContext.translator("Price")}:</div> {numberWithCommas(packages[seatRow.package].price)} {packages[seatRow.package].currency}</SeatInfo>
                        </SeatPopupSeatInfoWrapper>
                    </SeatPopupWrapper>


                </SeatPopup>
            ):null
        );
    }
}
StageMapPopup.propTypes = {
    seatRow: PropTypes.object,
    name: PropTypes.string,
}
export default withApplicationContext(withStageMapContext(StageMapPopup));