import axios from "axios";
import AdminTokenService from "./AdminTokenService";
import {ROUTE_ADMIN_LOGIN} from "../routes/routes";
import TokenService from "./TokenService";
import StorageService from "./StorageService";

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACK_END_WEBSERVICE_API,
    headers: {
        "Content-Type": "application/json",
    },
});
instance.interceptors.request.use(
    (config) => {
        const token = AdminTokenService.getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        config.params = {
            ...config.params,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                AdminTokenService.removeUser();
                window.location.href = ROUTE_ADMIN_LOGIN;
            }else{
                return Promise.reject(err);
            }
        }
        if (originalConfig.url === "/auth/login" && err.response) {
            if (err.response.status === 401){
                AdminTokenService.removeUser();;
            }
        }

        return Promise.reject(err);
    }
);

export default instance;