import React, {Component} from 'react';
import {withApplicationContext} from "../../../contexts/ApplicationContext";
import {withStageMapContext} from "./StageMapContext";
import PropTypes from "prop-types";
import {Package, PackageColor, PackagePrice, PackageWrapper, SeatContainer} from "./style";
import {numberWithCommas} from "../../../helpers";

class StageHelp extends Component {
    render() {
        const {applicationContext, context} = this.props;
        const {packages} = context;

        return (
            <SeatContainer>
                <PackageWrapper>
                    {Object.keys(packages).map((packageRow, packageRowIndex) => {
                        return (
                            <Package key={packageRowIndex}>
                                <PackageColor bg={packages[packageRow].color}>{packages[packageRow].label}</PackageColor>
                                <PackagePrice>{numberWithCommas(packages[packageRow].price)} {packages[packageRow].currency}</PackagePrice>
                            </Package>
                        )
                    })}
                    <Package>
                        <PackageColor bg={"#44536a"} left={"3em"}>{applicationContext.translator("SOLD")}</PackageColor>
                    </Package>
                </PackageWrapper>

            </SeatContainer>
        )
    }
}
StageHelp.propTypes = {

}
export default withApplicationContext(withStageMapContext(StageHelp));